import { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Divider } from 'primereact/divider';
import { useDispatch, useSelector } from 'react-redux';
import { setSettingsModal, settingsModalState } from '../../store/commonSlice';
import * as Yup from 'yup';
import InputField from '../../components/input-field/input-field';
import { formatDateDDMMYYYYhhmm } from '../../utils/utility-function';
import { InputTextarea } from 'primereact/inputtextarea';
import { useUpdateMappedServiceMutation } from '../../store/servicesApi';
import { IMappingData } from '.';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useGetClusterKeywordsQuery } from '../../store/apiSlice';
import DisplayIcon from '../../components/icon';
import {
	useAddClusteringKeywordMutation,
	useDeleteClusteringKeywordMutation,
} from '../../store/commonApi';

const EditClusteringKeywords = () => {
	const dispatch = useDispatch();
	const openModal = useSelector(settingsModalState);

	const [keyword, setKeyword] = useState('');

	const [deleteClusteringKeyword, { isLoading: isClusteringDeleting }] =
		useDeleteClusteringKeywordMutation();
	const resetData = () => {
		setKeyword('');
	};
	const { data: clusteringKeywords = [] } = useGetClusterKeywordsQuery();
	const [addClusteringKeyword, { isLoading: isClusteringUpdating }] =
		useAddClusteringKeywordMutation();

	const clickAddClusteringKeyword = () => {
		addClusteringKeyword(keyword);
		setKeyword('');
	};

	const deleteKeyword = (rowData: any) => {
		return (
			<DisplayIcon
				icon="tablerTrash"
				className="table-action"
				onClick={() => {
					deleteClusteringKeyword(rowData.id);
				}}
			/>
		);
	};

	return (
		<Dialog
			header="Clustering Keywords"
			visible={openModal.clusteringKeywords}
			style={{ width: '50vw' }}
			onHide={() => {
				resetData();
				dispatch(setSettingsModal({ clusteringKeywords: false }));
			}}
		>
			<h5 className="section-title">Add Keyword</h5>
			<div className="p-fluid grid formgrid mt-3">
				<div className="grid col-12 align-items-center">
					<div className="field col-6 mt-3">
						<span className="p-float-label">
							<InputText
								id="costCenter"
								value={keyword}
								onChange={(e) => setKeyword(e.target.value)}
							/>
							<label htmlFor="costCenter">Keyword</label>
						</span>
					</div>
					<div className="field col-3 mt-3">
						<Button
							label="Add Keyword"
							icon="pi pi-check"
							className="p-button-sm"
							loading={isClusteringUpdating}
							disabled={keyword.length === 0}
							onClick={() => {
								console.log('Save Modal');
								clickAddClusteringKeyword();
							}}
						/>
					</div>
				</div>
			</div>

			<DataTable value={clusteringKeywords} responsiveLayout="scroll">
				<Column field="id" header="Id"></Column>
				<Column field="sAs" header="Keyword" sortable></Column>

				<Column header="Delete" body={deleteKeyword}></Column>
			</DataTable>
		</Dialog>
	);
};

export default EditClusteringKeywords;

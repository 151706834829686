import { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import {
    getServiceOptions,
    setServiceOptions,
} from '../../store/commonSlice';
import { Card } from 'primereact/card';
import notes from './notes.json';

const ReleaseDialog = () => {

    const dispatch = useDispatch();
    const openModal = useSelector(getServiceOptions);

    return (
        <Dialog
            header="Latest Updates"
            visible={openModal.releaseNotes}
            style={{ width: '75vw' }}
            onHide={() => {
                setTimeout(() => {
                    dispatch(setServiceOptions({ releaseNotes: false }));
                }, 50)
            }}
        >
            <Card className='mb-6'>
                {/* <h1 className='mb-2' style={{ fontSize: '2em', fontWeight: 'bold' }}>Latest Updates</h1> */}
                <p className="ml-4 mb-4" style={{ fontSize: '1.1em' }}>
                    <strong style={{ fontSize: '1.2em', fontWeight: '500', lineHeight: '2' }}>Dear XCharge users,</strong> <br />We have some exciting news to share with you!  <br />In the past few months, we have been working on developing new features for the XCharge application, and we are pleased to announce their release. Below is a brief overview of the major new functionalities.
                </p>
                {
                    notes?.map((note, index) => {
                        return (
                            <div key={index}>
                                <h1 className='mb-2' style={{ fontSize: '1.3em', fontWeight: 'bold' }}>{note.title}</h1>
                                {/* <p className="ml-4 mb-4" style={{ fontSize: '1.1em' }}>
                                    {note.description}
                                </p> */}
                                <ul className="ml-4 mb-4">
                                    {
                                        note.points.map((list, index) => {
                                            return (
                                                <li className='ml-4' style={{ fontSize: '1.1em' }} key={index}>{list}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        )
                    })
                }
                {/* <h1 className='mb-4' style={{ fontSize: '1.4em', fontWeight: 'bold' }}>Deputy Function:</h1> */}
                <hr className='mb-4' />
                <p className="ml-4 mb-4" style={{ fontSize: '1.1em' }}>
                    Thank you for your attention, and we hope you enjoy using these new functionalities.
                </p>
                <p className="ml-4" style={{ fontSize: '1.1em' }}>
                    Best regards, <br /> The XCharge Team
                </p>
            </Card>

        </Dialog>
    )
}

export default ReleaseDialog;
import { useEffect, useMemo, useState } from 'react';
import CostCenterCard from '../../components/cost-center-card';
import ExtraActions from '../../components/extra-actions';
import { DataTable } from 'primereact/datatable';
import { Divider } from 'primereact/divider';
import { Column } from 'primereact/column';
import '../../views/actuals/styles.scss';
import { useGetAssetListUploadQuery, useGetAssetNewListUploadQuery, useLazyGetAssetListUploadQuery, useLazyGetAssetNewListUploadQuery } from '../../store/apiSlice';
import DisplayIcon from '../../components/icon';
import { useDispatch, useSelector } from 'react-redux';
import {
	getIsAdmin,
	getIsCostController,
	getSearchTerm,
	getToken,
	setAssetListModal,
	setAssetSummaryFilters,
	setDataProcessingModal,
} from '../../store/commonSlice';
import SetAssetPrice from './set-asset-price';
import { useDeletePicasReportMutation, useDeleteNewPicasReportMutation } from '../../store/picasApi';
import UploadAssetList from './upload-asset-list-report';
import AutoCompleteCostCenterName from '../../components/autocomplete-costcenter-name';
import CustomAutoComplete from '../../components/autocomplete/autocomplete';
import { Months } from '../../utils/json/months';
import { formatDateDDMMYYYY } from '../../utils/utility-function';
import PAGES from '../../utils/pages.enum';
import useFindCurrentLocation from '../../hooks/useFindCurrentLocation';
import { toast } from 'react-toastify';
import useDownloadFile from '../../hooks/useDownloadFile';
import ReactTooltip from 'react-tooltip';
import { Button } from 'primereact/button';
import { IChargingReport } from '../charging-report-upload';
import { Dialog } from 'primereact/dialog';

const AssetListUpload: React.FC = function () {

	const currentPage = useFindCurrentLocation();
	const { downloadFile } = useDownloadFile();
	const searchTerm = useSelector(getSearchTerm);
	const [searchKeyword, setSearchKeyword] = useState('');
	const [filter, setFilter] = useState('');
	useEffect(() => {
		const apiCallTimeoutId = setTimeout(() => {
			if (searchTerm !== '') {
				setSearchKeyword(searchTerm);
			} else {
				setSearchKeyword('');
			}
		}, 1000);

		return () => clearTimeout(apiCallTimeoutId);
	}, [searchTerm]);

	const {data, isLoading, isSuccess, isFetching, isError, error } =
		useGetAssetListUploadQuery();
	const {data: newData, isFetching: isNewFetching } =
		useGetAssetNewListUploadQuery();

	const [deletePicasReport, { isLoading: isDeleting }] =
		useDeletePicasReportMutation();
	const [deleteNewPicasReport, { isLoading: isNewDeleting }] =
		useDeleteNewPicasReportMutation();

	function onRowclick(data: any) {
		console.log(data);
	}

	const [deleteDialog, setDeleteDialog] = useState(false);
	const [deleteId, setDeleteId] = useState(undefined);

	const [assetData, setAssetData] = useState<IChargingReport[]>([]);

	// const selectedData = useMemo(() => {
	// 	return data;
	// }, [data]);

	const dispatch = useDispatch();
	const token = useSelector(getToken);

	useEffect(() => {
		if (currentPage === PAGES.PICAS_LIST) {
			setAssetData(data || []);
		} else {
			setAssetData(newData || []);
		}
	}, [currentPage, data, newData]);

	const computedData = useMemo(() => {
		let result = assetData;

		result =
			searchKeyword !== '' && assetData
				? assetData.filter((items) => {
					console.log(Object.values(items));
					return (
						Object.values(items).findIndex((chunk) =>
							chunk
								.toString()
								.toLowerCase()
								.includes(searchKeyword.toLowerCase())
						) !== -1
					);
				})
				: assetData;

		if (filter !== '') {
			console.log('Checking for filter ::', filter);
			result = result?.filter((_result) =>
				_result.uploadedMonthYear.includes(filter)
			);
		}

		return result;
	}, [assetData, searchKeyword, filter]);

	useEffect(() => {
		document.title = 'Workplace List Upload | XCharge';
	}, []);

	const isCostController = useSelector(getIsCostController);

		
	const DeleteFooter = () => (
		<div>
			<Button
				label="Cancel"
				icon="pi pi-times"
				onClick={() => {
					setDeleteDialog(false);
					setDeleteId(undefined);
				}}
			/>
			<Button
				label="Confirm"
				icon="pi pi-times"
				loading={isDeleting}
				onClick={async () => {
					deleteId &&
					
					await deleteNewPicasReport(deleteId)
					.unwrap()
					.then((payoad: any) => {
						toast.success('Deleted Workplace Report Successfully');
						setDeleteDialog(false);
						setDeleteId(undefined);
					})
					.catch((e: any) => {
						toast.error(e.data.message);
						setDeleteDialog(false);
						setDeleteId(undefined);
					});
				}}
			/>
		</div>
	)

	const actions = (rowData: any) => {
		return (
			<div className="table-menu">
				<Button
					className="p-button-rounded p-button-text p-button-icon-only"
					tooltipOptions={{ position: 'right' }}
					onClick={async () => {
						if(currentPage === PAGES.PICAS_LIST) {
							await downloadFile(
								`picasReport/export?Id=${rowData.id}`,
								'Picas-Report_' + new Date().toISOString() + '.xlsx'
							);
						} else {
							await downloadFile(
								`picasReport/exportnew?Id=${rowData.id}`,
								'Workplace-Report_' + new Date().toISOString() + '.xlsx'
							);
						}
					}}
					tooltip={currentPage === PAGES.PICAS_LIST ? "Export Picas List" : "Export Workplace List"}
				>
					<DisplayIcon icon="tablerFileExport" className="table-action" />
				</Button>
				{!isCostController && <Button
					className="p-button-rounded p-button-text p-button-icon-only"
					tooltipOptions={{ position: 'right' }}
					onClick={async () => {
						if(currentPage === PAGES.PICAS_LIST) {
							await deletePicasReport(rowData.id)
								.unwrap()
								.then((payoad: any) =>
									toast.success('Deleted Picas Report Successfully')
								)
								.catch((e: any) => {
									toast.error(e.data.message);
								});
						} else {
							setDeleteId(rowData.id);
							setDeleteDialog(true);
						}
					}}
					tooltip={currentPage === PAGES.PICAS_LIST ? "Delete Picas List" : "Delete Workplace List"}
				>
					<DisplayIcon icon="tablerTrash" className="table-action" />
				</Button>}
			</div>
		);
	};
	return (
		<>
			<div className={`grid`}>
				<div className="cards">
					<DataTable
						tableClassName='asset-list-upload'
						loading={currentPage === PAGES.PICAS_LIST ? isFetching : isNewFetching}
						header={
							<AssetListHeader
								title={currentPage === PAGES.PICAS_LIST ? "Picas List Upload" : "Workplace List Upload"}
								currentPage={currentPage}
								yearCallback={(filter: any) => {
									filter ? setFilter(filter.value) : setFilter('');
								}}
							/>
						}
						value={computedData}
						responsiveLayout="scroll"
						onRowClick={(event) => onRowclick(event)}
					>
						<Column
							header="#"
							alignHeader="left"
							body={(data, props) => props.rowIndex + 1}
						></Column>
						<Column
							field="uploadedMonthYear"
							header="Month Of Upload"
							sortable
						></Column>
						<Column
							field="uploadedOn"
							header="Upload Date"
							body={(data) => formatDateDDMMYYYY(data.uploadedOn)}
							sortable
						></Column>
						<Column
							field="uploadedBy.firstName"
							header="Uploader"
							body={(data) =>
								`${data.uploadedBy.firstName} ${data.uploadedBy.lastName}`
							}
							sortable
						></Column>
						<Column field="status" header="Status" sortable></Column>
						<Column body={actions} align="center"></Column>
					</DataTable>
				</div>
			</div>
			<SetAssetPrice />
			
			<Dialog
				header="Delete Workplace Report"
				visible={deleteDialog}
				footer={DeleteFooter}
				onHide={() => {
					setDeleteDialog(false);
					setDeleteId(undefined);
				}}
			>
				<div className='flex flex-wrap justify-content-center'>
					<DisplayIcon className='flex justify-items-center warning-icon' icon="warning" />
				</div>
				<p className="mt-2 px-5 bold-text">
					Do you want to delete this Workplace Report?
				</p>
			</Dialog>
		</>
	);
};

const AssetListHeader = ({
	title,
	currentPage,
	yearCallback,
}: {
	title: string;
	currentPage: PAGES;
	yearCallback?: (e: any) => any;
}) => {
	const dispatch = useDispatch();
	const isAdmin = useSelector(getIsAdmin);
	const isCostController = useSelector(getIsCostController);
	return (
		<>
			<div className="flex flex-column justify-content-between align-items-center">
				<div className="flex justify-content-between w-full align-items-center">
					<div className="flex align-items-baseline w-6">
						<h5>{title}</h5>
					</div>
					<div className="actions flex align-items-center justify-content-between mx-4">
						<div className="app-icon mr-2">
							<DisplayIcon icon="filter" />
						</div>

						{(currentPage === PAGES.PICAS_LIST || currentPage === PAGES.WORKPLACE_LIST) && (
							<CustomAutoComplete
								customId="select-year"
								placeholder="Select Year"
								inputList={[
									{ value: '2023', key: '2023' },
									{ value: '2022', key: '2022' },
									{ value: '2021', key: '2021' },
									{ value: '2020', key: '2020' },
								]}
								field="key"
								target="value"
								callback={yearCallback}
							/>
						)}
						{currentPage === PAGES.ASSET_SUMMARY && (
							<>
								<CustomAutoComplete
									customId="select-year"
									placeholder="Select Year"
									inputList={[
										{ value: '2023', key: '2023' },
										{ value: '2022', key: '2022' },
										{ value: '2021', key: '2021' },
										{ value: '2020', key: '2020' },
									]}
									field="key"
									target="value"
									defaultValue={{
										value: '2023',
										key: '2023',
									}}
									callback={(e) => {
										if (e) {
											dispatch(setAssetSummaryFilters({ year: e.key }));
										} else {
											dispatch(setAssetSummaryFilters({ year: 9999 }));
										}
									}}
								/>
								<CustomAutoComplete
									shellClass="mx-4"
									customId="select-active"
									placeholder="Select Active"
									inputList={[
										{ value: 'Active', key: 'active' },
										{ value: 'In-Active', key: 'inactive' },
									]}
									field="key"
									target="value"
									callback={(e) => {
										if (e) {
											dispatch(setAssetSummaryFilters({ active: e.key }));
										} else {
											dispatch(setAssetSummaryFilters({ active: '' }));
										}
									}}
								/>
							</>
						)}
					</div>

					<div className="flex align-items-center justify-content-end icon-section">
						<div className="flex flex-columns">
							<div className="flex">
								<div className="actions flex ml-4">
									{!isCostController && <Button
										icon="pi pi-filter"
										className="p-button-rounded p-button-text p-button-plain p-button-icon-only"
										tooltip={currentPage === PAGES.PICAS_LIST ? "Upload Picas List" : "Upload Workplace List"}
										onClick={() =>
											dispatch(
												setDataProcessingModal({ uploadChargingReport: true })
											)
										}
									>
										<DisplayIcon icon="upload" className="action-icon" />
									</Button>}
									<Button
										icon="pi pi-filter"
										className="p-button-rounded p-button-text p-button-plain p-button-icon-only"
										tooltip="Show Asset Summary"
										onClick={() => {
											console.log('PROCESS :: ', process.env);
											window.open(
												`${process.env.REACT_APP_ENV === 'staging'
													? '/web/XCharge'
													: '#'
												}/dataProcessing/assetListUpload/assetSummary`,
												'_blank'
											);
										}}
									>
										<DisplayIcon icon="tablerTable" className="action-icon" />
									</Button>
									<Button
										icon="pi pi-filter"
										className="p-button-rounded p-button-text p-button-plain p-button-icon-only"
										tooltip="Add/Edit Asset Price"
										onClick={() =>
											dispatch(
												setAssetListModal({ assetPriceConfiguration: true, showInvalidAsset: false })
											)
										}
									>
										<DisplayIcon icon="settings" className="action-icon" />
									</Button>
									<Button
										icon="pi pi-filter"
										className="p-button-rounded p-button-text p-button-plain p-button-icon-only"
										tooltip="View/Edit Invalid Asset Price"
										tooltipOptions={{ position: 'left' }}
										onClick={() =>
											dispatch(
												setAssetListModal({ assetPriceConfiguration: true, showInvalidAsset: true })
											)
										}
									>
										<DisplayIcon icon="invalidAsset" className="action-icon" />
									</Button>
									{isAdmin && (
										<Button
											icon="pi pi-filter"
											className="p-button-rounded p-button-text p-button-plain p-button-icon-only"
											tooltip="Show New Asset"
											onClick={() => {
												console.log('PROCESS :: ', process.env);
												window.open(
													`${
														process.env.REACT_APP_ENV === 'staging'
															? '/web/XCharge'
															: '#'
													}/dataProcessing/assetListUpload/newAssets`,
													'_blank'
												);
											}}
										>
											<DisplayIcon icon="add" className="action-icon" />
										</Button>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<UploadAssetList />
		</>
	);
};
export default AssetListUpload;

export { AssetListHeader };

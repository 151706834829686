import { useEffect, useMemo, useState } from 'react';
import CostCenterCard from '../../components/cost-center-card';
import ExtraActions from '../../components/extra-actions';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import '../../views/actuals/styles.scss';
import { useGetAllChargingReportQuery } from '../../store/apiSlice';
import { Icon } from '@iconify/react';
import DisplayIcon from '../../components/icon';
import FileUploader from '../../components/file-uploader';
import {
	commonState,
	getIsAdmin,
	getIsCostController,
	getIsServiceOwner,
	getSearchTerm,
	setDataProcessingModal,
} from '../../store/commonSlice';
import { useDispatch, useSelector } from 'react-redux';

import UploadChargingReport from './upload-charging-report';
import Emailer from '../../components/email/mailer';
import { useDeleteChargingReportMutation, useSendCRUploadNotificationMutation } from '../../store/chargingReportApi';
import { Outlet, useNavigate } from 'react-router-dom';
import CustomAutoComplete from '../../components/autocomplete/autocomplete';
import useDownloadFile from '../../hooks/useDownloadFile';
import PAGES from '../../utils/pages.enum';
import useFindCurrentLocation from '../../hooks/useFindCurrentLocation';
import { formatDateDDMMYYYY } from '../../utils/utility-function';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import UploadUserList from './upload-user-list';
import { Dialog } from 'primereact/dialog';

export interface IChargingReport {
	id: number;
	name: string;
	status: string;
	uploadedBy: {
		email: string;
		firstName: string;
		id: number;
		identifer: number;
		lastName: string;
		isApproval: boolean;
	};
	uploadedMonthYear: string;
	uploadedOn: string;
	isMailed: boolean;
	isDuplicate: boolean;
}

const ChargingReportUpload: React.FC = function () {
	const { downloadFile } = useDownloadFile();
	const searchTerm = useSelector(getSearchTerm);
	const [searchKeyword, setSearchKeyword] = useState('');
	const [filter, setFilter] = useState('');
	const isAdmin = useSelector(getIsAdmin);
	const isServiceOwner = useSelector(getIsServiceOwner);
	const [showNotificationDialog, setShowNotificationDialog] = useState(false);
	useEffect(() => {
		const apiCallTimeoutId = setTimeout(() => {
			if (searchTerm !== '') {
				setSearchKeyword(searchTerm);
			} else {
				setSearchKeyword('');
			}
		}, 1000);

		return () => clearTimeout(apiCallTimeoutId);
	}, [searchTerm]);

	const { data, isLoading, isSuccess, isError, error, isFetching } =
		useGetAllChargingReportQuery();

	useEffect(() => {
		document.title = 'Charging Report Upload | XCharge';
	}, []);
	const computedData = useMemo(() => {
		let result = data;

		result =
			searchKeyword !== '' && data
				? data.filter((items) => {
						console.log(Object.values(items));
						return (
							Object.values(items).findIndex((chunk) =>
								chunk
									.toString()
									.toLowerCase()
									.includes(searchKeyword.toLowerCase())
							) !== -1
						);
				  })
				: data;

		if (filter !== '') {
			console.log('Checking for filter ::', filter);
			result = result?.filter((_result) =>
				_result.uploadedMonthYear.includes(filter)
			);
		}

		return result;
	}, [data, searchKeyword, filter]);

	const [deleteChargingReport, { isLoading: isDeleting }] =
		useDeleteChargingReportMutation();
		
	const [sendCRUploadNotification, { isLoading: isSending }] = useSendCRUploadNotificationMutation();

	const dispatch = useDispatch();

	function onRowclick(data: any) {
		console.log(data);
	}
	
	const [deleteDialog, setDeleteDialog] = useState(false);
	const [deleteId, setDeleteId] = useState(undefined);

	const currentPage = useFindCurrentLocation();
	const navigate = useNavigate();
	const isCostController = useSelector(getIsCostController);

	const actions = (rowData: any, props: any) => {
		return (
			<div className="table-menu">
				<Button
					className="p-button-rounded p-button-text p-button-icon-only"
					tooltipOptions={{ position: 'right' }}
					tooltip="Export Charging Report"
					onClick={async () => {
						downloadFile(
							`chargingReport/exportchargingreport?Id=${rowData.id}`,
							`Charging-Report_${rowData.uploadedMonthYear}.xlsx`
						);
					}}
				>
					<DisplayIcon icon="tablerFileExport" className="table-action" />
				</Button>
				{
					(isAdmin && !isCostController || isServiceOwner) &&
					<Button
						className="p-button-rounded p-button-text p-button-icon-only"
						tooltipOptions={{ position: 'right' }}
						onClick={async () => {
							setDeleteId(rowData.id);
							setDeleteDialog(true);
						}}
						tooltip="Delete Charging Report"
					>
						<DisplayIcon icon="tablerTrash" className="table-action" />
					</Button>
				}
				{ ((isAdmin && !isCostController || isServiceOwner) && props.rowIndex === 0) && 
				<Button
					className="p-button-rounded p-button-text p-button-icon-only"
					tooltipOptions={{ position: 'right' }}
					onClick={async () => {
						setShowNotificationDialog(true);
						console.log(rowData);
						
					}}
					tooltip="Send Notification Mail"
				>
					<DisplayIcon icon="tablerMail" className="table-action" />
				</Button>
				}
			</div>
		);
	};

	
	const DeleteFooter = () => (
		<div>
			<Button
				label="Cancel"
				icon="pi pi-times"
				onClick={() => {
					setDeleteDialog(false);
					setDeleteId(undefined);
				}}
			/>
			<Button
				label="Confirm"
				icon="pi pi-times"
				loading={isDeleting}
				onClick={async () => {
					deleteId &&
					
					await deleteChargingReport(deleteId)
					.unwrap()
					.then((payoad: any) => {
						toast.success('Deleted Charging Report Successfully');
						setDeleteDialog(false);
						setDeleteId(undefined);
					})
					.catch((e: any) => {
						toast.error(e.data.message);
						setDeleteDialog(false);
						setDeleteId(undefined);
					});
				}}
			/>
		</div>
	)
	
	const NotificationFooter = () => (
		<div>
			<Button
				label="Cancel"
				icon="pi pi-times"
				onClick={() => setShowNotificationDialog(false)}
			/>
			<Button
				label="Send"
				icon="pi pi-check"
				loading={isSending}
				onClick={async () => {
					// process.env.REACT_APP_ENV === 'production' && 
					sendCRUploadNotification({ uploadType: 'Itservice' }).unwrap()
						.then((payoad: any) => {
							toast.success('Notification sent Successfully')
							setShowNotificationDialog(false);
						}
						).catch((e: any) => {
							toast.error(e.data.message);
						});
				}}
			/>
		</div>
	);

	return (
		<>
			<div className={`grid`}>
				<div className="cards">
					<DataTable
						tableClassName="charging-report-upload"
						value={computedData}
						responsiveLayout="scroll"
						onRowClick={(event) => onRowclick(event)}
						loading={isFetching}
						header={
							<ChargingReportHeader
								title="Charging Report Upload"
								currentPage={currentPage}
								callback={(filter: any) => {
									filter ? setFilter(filter.value) : setFilter('');
								}}
							/>
						}
					>
						<Column
							header="#"
							alignHeader="left"
							body={(data, props) => props.rowIndex + 1}
						></Column>
						<Column
							field="uploadedMonthYear"
							header="Month Of Upload"
							sortable
						></Column>
						<Column
							field="uploadedOn"
							header="Upload Date"
							body={(data) => formatDateDDMMYYYY(data.uploadedOn)}
							sortable
						></Column>
						<Column
							field="uploadedBy.firstName"
							header="Uploader"
							body={(data) =>
								`${data.uploadedBy.firstName} ${data.uploadedBy.lastName}`
							}
							sortable
						></Column>
						<Column field="status" header="Status" sortable></Column>
						<Column field="ou" header="OU Type" sortable></Column>
						<Column body={actions} align="center"></Column>
					</DataTable>
					<Dialog
						header="Send Notification Maill"
						visible={showNotificationDialog}
						footer={NotificationFooter}
						onHide={() => setShowNotificationDialog(false)}
					>
						<p className="m-2 px-5 bold-text">
							Do you want to send notification mail for the latest Charging report upload?
						</p>
					</Dialog>
				</div>
			</div>
						
			<Dialog
				header="Delete Charging Report"
				visible={deleteDialog}
				footer={DeleteFooter}
				onHide={() => {
					setDeleteDialog(false);
					setDeleteId(undefined);
				}}
			>
				<div className='flex flex-wrap justify-content-center'>
					<DisplayIcon className='flex justify-items-center warning-icon' icon="warning" />
				</div>
				<p className="mt-2 px-5 bold-text">
					Do you want to delete this Charging Report?
				</p>
			</Dialog>
		</>
	);
};

const ChargingReportHeader = ({
	title,
	currentPage,
	options,
	callback,
	mapCallback,
	unmapCallback,
}: {
	title: string;
	currentPage: PAGES;
	options?: any;
	callback?: (e: any) => any;
	mapCallback?: (e: any) => any;
	unmapCallback?: (e: any) => any;
}) => {
	const dispatch = useDispatch();
	const { downloadFile } = useDownloadFile();
	const isAdmin = useSelector(getIsAdmin);
	const isServiceOwner = useSelector(getIsServiceOwner);
	const isCostController = useSelector(getIsCostController);

	return (
		<>
			<div className="flex flex-column justify-content-between align-items-center">
				<div className="flex justify-content-between w-full align-items-center">
					<div className="flex align-items-baseline w-8">
						<h5>{title}</h5>
					</div>

					{currentPage === PAGES.CHARGING_REPORT ? (
						<div className="actions flex align-items-center justify-content-between mr-4">
							<div className="app-icon mr-2">
								<DisplayIcon icon="filter" />
							</div>
							<CustomAutoComplete
								customId="filter-active"
								placeholder="Filter By Year"
								inputList={[
									{ value: '2023', key: '2023' },
									{ value: '2022', key: '2022' },
									{ value: '2021', key: '2021' },
									{ value: '2020', key: '2020' },
								]}
								field="key"
								target="value"
								callback={callback}
							/>
						</div>
					) : null}
					{currentPage === PAGES.NEW_SERVICES ? (
						<Button
							label="Map Services"
							icon="pi pi-plus"
							className="p-button-sm w-10rem mr-3"
							onClick={callback}
							disabled={options.disableButton}
						/>
					) : null}
					{currentPage === PAGES.WILDCARD_MAPPING ? (
						<div className="actions flex align-items-center justify-content-between mr-4">
							<Button
								label="Unmap"
								icon="pi pi-ban"
								className="p-button-sm"
								onClick={unmapCallback}
								disabled={options.disableButton}
							></Button>
							<Button
								label="Confirm"
								className="p-button-sm ml-3"
								icon="pi pi-check-square"
								onClick={mapCallback}
								disabled={options.disableButton}
							></Button>
						</div>
					) : null}

					<div className="flex align-items-center justify-content-end icon-section">
						<div className="flex flex-columns">
							<div className="flex">
								<div className="actions flex ml-4">
									{isAdmin && !isCostController && (
										<Button
											icon="pi pi-filter"
											className="p-button-rounded p-button-text p-button-plain p-button-icon-only"
											tooltip="Upload Current User List"
											onClick={() =>
												dispatch(
													setDataProcessingModal({
														uploadGemsList: true,
													})
												)
											}
										>
											<DisplayIcon icon="user" className="action-icon" />
										</Button>
									)}
									{(isAdmin && !isCostController || isServiceOwner) && (
										<Button
											icon="pi pi-filter"
											className="p-button-rounded p-button-text p-button-plain p-button-icon-only"
											tooltip="Upload Charging Report"
											onClick={() =>
												dispatch(
													setDataProcessingModal({
														uploadChargingReport: true,
													})
												)
											}
										>
											<DisplayIcon icon="upload" className="action-icon" />
										</Button>
									)}
									<Button
										icon="pi pi-filter"
										className="p-button-rounded p-button-text p-button-plain p-button-icon-only"
										tooltip="Show Charging Report Pivot"
										onClick={() => {
											console.log('PROCESS :: ', process.env);
											window.open(
												`${
													process.env.REACT_APP_ENV === 'staging'
														? '/web/XCharge'
														: '#'
												}/dataProcessing/chargingReportPivot`,
												'_blank'
											);
										}}
									>
										<DisplayIcon icon="tablerTable" className="action-icon" />
									</Button>

									{isAdmin && (
										<Button
											icon="pi pi-filter"
											className="p-button-rounded p-button-text p-button-plain p-button-icon-only"
											tooltip="Show Wild Card Services"
											onClick={() => {
												console.log('PROCESS :: ', process.env);
												window.open(
													`${
														process.env.REACT_APP_ENV === 'staging'
															? '/web/XCharge'
															: '#'
													}/dataProcessing/reportUpload/wildCard`,
													'_blank'
												);
											}}
										>
											<DisplayIcon
												icon="docmentQuestion"
												className="action-icon"
											/>
										</Button>
									)}
									{isAdmin && (
										<Button
											icon="pi pi-filter"
											className="p-button-rounded p-button-text p-button-plain p-button-icon-only"
											tooltip="Show New Services"
											onClick={() => {
												console.log('PROCESS :: ', process.env);
												window.open(
													`${
														process.env.REACT_APP_ENV === 'staging'
															? '/web/XCharge'
															: '#'
													}/dataProcessing/reportUpload/newServices`,
													'_blank'
												);
											}}
										>
											<DisplayIcon icon="add" className="action-icon" />
										</Button>
									)}
									<Button
										className="p-button-rounded p-button-text p-button-icon-only"
										tooltip="Export Charging Report As Excel"
										tooltipOptions={{ position: 'left' }}
										onClick={() => {
											downloadFile(
												`chargingReport/export?year=${new Date().getFullYear()}`,
												'Charging-Report_' + new Date().toISOString() + '.xlsx'
											);
										}}
									>
										<DisplayIcon icon="excel" className="excel-icon__small" />
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<UploadChargingReport />
			<UploadUserList />
		</>
	);
};

export { ChargingReportHeader };
export default ChargingReportUpload;

import { useEffect, useMemo, useState } from 'react';
import CostCenterCard from '../../components/cost-center-card';
import ExtraActions from '../../components/extra-actions';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../../views/actuals/styles.scss';
import {
	useGetAllActiveCostCentersQuery,
	useGetAllCostCentersQuery,
} from '../../store/apiSlice';
import DisplayIcon from '../../components/icon';
import {
	getIsAdmin,
	getSearchTerm,
	setSettingsModal,
	settingsModalState,
} from '../../store/commonSlice';
import { useSelector, useDispatch } from 'react-redux';
import AddCostCenter from './add-cost-center';
import useDownloadFile from '../../hooks/useDownloadFile';
import CustomAutoComplete from '../../components/autocomplete/autocomplete';
import { useSaveCommentMutation } from '../../store/servicesApi';
import CostCenterUpload from './cost-center-upload';
import NewUsersList from './display-new-users';
import { Tooltip } from 'primereact/tooltip';
import { Badge } from 'primereact/badge';
import {
	formatDateDDMMYYYY,
	formatDateDDMMYYYYhhmm,
} from '../../utils/utility-function';
import ReactTooltip from 'react-tooltip';
import { Button } from 'primereact/button';
import CCMasterUpload from './CCMasterUpload';
import useGetOU from '../../hooks/useGetOU';

export interface ICostCenterData {
	target_costcenter: string;
	name: string;
	target_responsible: string;
	costcenter_category: number;
	user_responsible: string;
	userResponsible: undefined | string;
	valid_from: string;
	valid_to: string;
	country: {
		id: number;
		name: string;
	};
	department: {
		id: number;
		name: string;
	};
	id: number;
	isvisible: boolean;
	region: {
		id: number;
		name: string;
	};
	requestedBy: string;
	updatedBy: {
		email: string;
		firstName: string;
		id: number;
		identifer: number;
		lastName: string;
		isApproval: boolean;
	};
	updatedOn: string;
	itM_CoOrdinator: string;
	itM_CIO: string;
	lastUpdatedBy: number;
	isnew: boolean;
	isupdated: boolean;
	isnameupdate: boolean;
	updated: string;
	old_validfrom: string;
	old_validto: string;
	isvalidityupdate: boolean;
	isfunctional: boolean;
	isnewresponsible: boolean;
	ccMaster: {
		id: number;
		cc_master: string;
	};
}

const CostCentersPage: React.FC = function () {

	const OU = useGetOU();
	const { data, isLoading, isSuccess, isError, error } =
		useGetAllCostCentersQuery({OU});
	const searchTerm = useSelector(getSearchTerm);
	const [searchKeyword, setSearchKeyword] = useState('');
	useEffect(() => {
		document.title = 'Cost Center | XCharge';
	}, []);
	useEffect(() => {
		const apiCallTimeoutId = setTimeout(() => {
			if (searchTerm !== '') {
				setSearchKeyword(searchTerm);
			} else {
				setSearchKeyword('');
			}
		}, 1000);

		return () => clearTimeout(apiCallTimeoutId);
	}, [searchTerm]);
	const newCostCenterUsers = useMemo(() => {
		let result = data;
		result =
			searchKeyword !== '' && data
				? data.filter((items: any) => {
						console.log(Object.values(items));
						return (
							Object.values(items).findIndex((chunk: any) =>
								chunk
									.toString()
									.toLowerCase()
									.includes(searchKeyword.toLowerCase())
							) !== -1
						);
				  })
				: data;

		return result
			? result.filter((cc: any) => cc.isvisible && cc.isnewresponsible)
			: [];
	}, [data, searchKeyword]);

	const { data: activeCostCenters = [] } = useGetAllActiveCostCentersQuery({OU});
	function onRowclick(data: any) {
		console.log(data);
	}
	const [selectedfilter, setSelectedFilter] = useState<{
		value: string;
		key: string;
	}>();

	const [selectedRow, setSelectedRow] = useState<any>();
	const [mode, setMode] = useState<string>();

	const dispatch = useDispatch();
	const openModal = useSelector(settingsModalState);
	const filteredCostCenters = useMemo(() => {
		let result =
			searchKeyword !== '' && data
				? data.filter((items: any) => {
						console.log(Object.values(items));
						return (
							Object.values(items).findIndex((chunk: any) =>
								chunk
									.toString()
									.toLowerCase()
									.includes(searchKeyword.toLowerCase())
							) !== -1
						);
				  })
				: data;
		if (selectedfilter) {
			return selectedfilter.key === 'active'
				? data?.filter(
						(costCenter) =>
							activeCostCenters.findIndex(
								(_active: any) => _active.costCenterId === costCenter.id
							) !== -1
				  )
				: data?.filter(
						(costCenter) =>
							activeCostCenters.findIndex(
								(_active: any) => _active.costCenterId === costCenter.id
							) === -1
				  );
		} else {
			return result;
		}
	}, [data, activeCostCenters, selectedfilter, searchKeyword]);

	const { downloadFile } = useDownloadFile();
	const filterOptions = [
		{ value: 'Actuals Active', key: 'active' },
		{ value: 'Actuals In-Active', key: 'inactive' },
	];
	const filterBySelection = (filterApplied: any) => {
		setSelectedFilter(filterApplied);
	};
	const isAdmin = useSelector(getIsAdmin);
	const CostCenterPageHeader = () => {
		const isAdmin = useSelector(getIsAdmin);
		return (
			<div className="flex flex-column justify-content-between align-items-center">
				<div className="flex justify-content-between w-full align-items-center">
					<div className="flex align-items-baseline w-full">
						<h5>Cost Center</h5>
					</div>

					<div className="actions flex align-items-center justify-content-between mr-4">
						<div className="app-icon mr-2">
							<DisplayIcon icon="filter" />
						</div>
						<CustomAutoComplete
							customId="filter-active"
							placeholder="Filter Active"
							inputList={filterOptions}
							field="key"
							target="value"
							callback={filterBySelection}
						/>
					</div>

					<div className="flex align-items-center justify-content-end icon-section">
						<div className="flex flex-columns">
							<div className="flex">
								<div className="actions flex ml-4">
									{isAdmin && (
										<>
										<Button
											className="p-button-rounded p-button-text p-button-plain p-button-icon-only"
											tooltip="Upload CC Master List"
											onClick={() => {
												dispatch(
													setSettingsModal({ ccMasterUpload: true })
												);
											}}
											icon="pi pi-filter"
										>
											<DisplayIcon icon="listUpload" className="action-icon" />
										</Button>
											<Button
												className="p-button-rounded p-button-text p-button-plain p-button-icon-only"
												tooltip="Upload Cost Center"
												onClick={() => {
													dispatch(
														setSettingsModal({ costCenterUpload: true })
													);
												}}
												icon="pi pi-filter"
											>
												<DisplayIcon icon="upload" className="action-icon" />
											</Button>

											<DisplayIcon
												icon="user"
												className=" ml-2 p-overlay-badge action-icon"
												onClick={() => {
													dispatch(
														setSettingsModal({ newCostCenterUsers: true })
													);
												}}
												data-pr-tooltip="Display New Cost Center Users"
											>
												<div className="icon-badge">
													<Badge
														value={
															newCostCenterUsers ? newCostCenterUsers.length : 0
														}
													/>
												</div>
											</DisplayIcon>
											<Button
												className="p-button-rounded p-button-text p-button-plain p-button-icon-only"
												tooltip="Add Cost Center"
												onClick={() =>
													dispatch(setSettingsModal({ addCostCenter: true }))
												}
												icon="pi pi-filter"
											>
												<DisplayIcon icon="add" className="action-icon" />
											</Button>
										</>
									)}
									<Button
										className="p-button-rounded p-button-text p-button-plain p-button-icon-only"
										tooltip="Display Mapping Difference Page"
										onClick={() => {
											console.log('PROCESS :: ', process.env);
											window.open(
												`${
													process.env.REACT_APP_ENV === 'staging'
														? '/web/XCharge'
														: '#'
												}/settings/mapping/mappingDifference`,
												'_blank'
											);
										}}
										icon="pi pi-filter"
									>
										<DisplayIcon icon="tablerInfo" className="action-icon" />
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};
	const header = () => {
		return (
			<div className="flex align-items-center justify-content-between">
				<h5>Cost Centers</h5>
				<div className="actions">
					<div className="button-row">
						<Button
							icon="pi pi-filter"
							className="p-button-rounded p-button-text p-button-plain p-button-icon-only"
							tooltip="Add New Mapping"
							onClick={() => {
								dispatch(setSettingsModal({ addMapping: true }));
							}}
						>
							<DisplayIcon
								icon="upload"
								className="row-icon"
								onClick={() =>
									dispatch(setSettingsModal({ addCostCenter: true }))
								}
							/>
						</Button>
						<DisplayIcon icon="tablerTable" className="row-icon" />
						<DisplayIcon icon="docmentQuestion" className="row-icon" />
						<DisplayIcon icon="add" className="row-icon" />
						<DisplayIcon icon="export" className="row-icon" />
					</div>
				</div>
			</div>
		);
	};

	const active = (rowData: any) => {
		return (
			<>
				{rowData.isvisible === true ? (
					<Button
						className="p-button-rounded p-button-text p-button-icon-only"
						tooltipOptions={{ position: 'right' }}
						tooltip="Active"
					>
						<DisplayIcon icon="tablerCheck" className="table-action" />
					</Button>
				) : (
					<Button
						className="p-button-rounded p-button-text p-button-icon-only"
						tooltipOptions={{ position: 'right' }}
						tooltip="In-Active"
					>
						<DisplayIcon
							icon="tablerWarning"
							className="table-action red-icon"
						/>
					</Button>
				)}
			</>
		);
	};

	const getMessage = (data: any) => {
		if (data.isupdated === true)
			return data.isnameupdated === true
				? 'Name has been updated from ' + data.updated + ' to ' + data.name
				: 'Target Responsible has been updated from ' +
						data.updated +
						' to ' +
						data.target_responsible;
		else
			return (
				'Valid from has been updated from ' +
				formatDateDDMMYYYY(data.old_validfrom) +
				' to ' +
				formatDateDDMMYYYY(data.valid_from) +
				' and Valid to has been updated from ' +
				formatDateDDMMYYYY(data.old_validto) +
				' to ' +
				formatDateDDMMYYYY(data.valid_to)
			);
	};
	const status = (rowData: any) => {
		return (
			<>
				{rowData.isnew === true ? (
					<Button
						className="p-button-rounded p-button-text p-button-icon-only"
						tooltipOptions={{ position: 'right' }}
						tooltip="New Cost Center"
					>
						<DisplayIcon icon="tablerExternalLink" className="table-action" />
					</Button>
				) : (
					<Button
						className="p-button-rounded p-button-text p-button-icon-only"
						tooltipOptions={{ position: 'right' }}
						tooltip={getMessage(rowData)}
					>
						<DisplayIcon icon="tablerExchange" className="table-action" />
					</Button>
				)}
			</>
		);
	};
	const rowClass = (data: any) => {
		return {
			'highlight-row': data.isfunctional == true,
		};
	};
	const actions = (rowData: any) => {
		return (
			<Button
				className="p-button-rounded p-button-text p-button-icon-only"
				tooltipOptions={{ position: 'right' }}
				tooltip="Edit Cost Center"
				onClick={() => {
					setMode('EDIT');
					setSelectedRow(rowData);
					dispatch(setSettingsModal({ addCostCenter: true }));
				}}
			>
				<DisplayIcon icon="tablerEdit" className="table-action" />
			</Button>
		);
	};
	const [first, setFirst] = useState(0);

	return (
		<div className={`grid`}>
			<div className="cards">
				<DataTable
					tableClassName='settings-costcenter'
					header={CostCenterPageHeader}
					value={filteredCostCenters}
					responsiveLayout="scroll"
					onRowClick={(event) => onRowclick(event)}
					rowClassName={rowClass}
					paginator
					rows={25}
					first={first}
					loading={isLoading}
					onPage={(e) => setFirst(e.first)}
				>
					<Column
						header="#"
						alignHeader="left"
						body={(data, props) => props.rowIndex + 1}
					></Column>
					<Column
						field="target_costcenter"
						header="Cost Center"
						sortable
					></Column>
					<Column field="name" header="Cost Center Name" sortable></Column>
					<Column
						field="target_responsible"
						header="ITM Target Responsible"
						sortable
					></Column>
					<Column
						field="ccMaster.cc_master"
						header="CC Master"
						sortable
					></Column>
					<Column header="Active" body={active}></Column>
					<Column header="Status" body={status}></Column>
					{isAdmin && <Column header="Actions" body={actions}></Column>}
				</DataTable>
			</div>
			{openModal.addCostCenter && (
				<AddCostCenter mode={mode} editData={selectedRow} />
			)}
			<CostCenterUpload />
			<CCMasterUpload />
			<NewUsersList />
		</div>
	);
};

export default CostCentersPage;

// Import the RTK Query methods from the React-specific entry point
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { IProp } from '../components/cost-center-card/index';
import { IChargingReport } from '../views/charging-report-upload';
import { ICostCenterData } from '../views/cost-center';
import { IMappingData } from '../views/mapping';
import { IServiceData, IServiceRequest } from '../views/service/index';
import { IAssetList } from '../views/asset-list-upload/set-asset-price';
import { IAllUsers, IUsers } from '../views/users';
import { baseApi } from './baseApi';
import { ICorrectionItem } from '../components/correction-approval';
import { IPicasDetails } from '../views/service/picas-dialog';
import { IAllAssets, IAssetSummary } from '../types/service.interfaces';
import { INewAsset } from '../types/picas.interface';
const actualsEndPoint = 'actuals';

export interface picasDetailsType {
	ccId: number;
	month: string | null;
	year: number | null;
}

const picasApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		getPicasDetails: builder.query<IPicasDetails, picasDetailsType>({
			query: ({ ccId, month, year }) =>
				`picasReport/getpicasreportdetails?costcenterId=${ccId}&month=${month}&year=${year}`,
		}),
		getPicasExcelExport: builder.mutation<any, picasDetailsType>({
			query: ({ ccId, month, year }) => ({
				url: `picasReport/exportdetails?ccId=${ccId}&monthYear=${month}-${year}`,
				method: 'POST',
			}),
		}),
		getPicasComments: builder.mutation<
			any,
			{ ccId: number; versionId: number }
		>({
			query: ({ ccId, versionId }) => ({
				url: `picasReport/getpicascomments?ccId=${ccId}&versionId=${versionId}`,
				method: 'POST',
				providesTags: ['PicasComment'],
			}),
		}),
		savePicasComments: builder.mutation<
			any,
			{ cc_Id: number; comment: string }
		>({
			query: ({ cc_Id, comment }) => ({
				url: `	picasReport/savepicascomments?ccId=${cc_Id}&comment=${comment}`,
				method: 'POST',
			}),
			invalidatesTags: (result, error, arg) => [
				{ type: 'Services', costCenterId: arg.cc_Id },
				'PicasComment',
			],
		}),
		deletePicasReport: builder.mutation<void, { id: number }>({
			query: (id) => ({
				url: `picasReport/delete/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['PicasReports'],
		}),
		deleteNewPicasReport: builder.mutation<void, { id: number }>({
			query: (id) => ({
				url: `picasReport/deletenew/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['PicasNewReports'],
		}),
		uploadAssetList: builder.mutation<
			void,
			{ file: FormData; month: string; vId: number }
		>({
			query: ({ file, month, vId }) => ({
				url: `picasReport/uploaddata?uploadedMonthYear=${month}&picasReportId=${vId}`,
				method: 'POST',
				body: file,
			}),
			invalidatesTags: ['PicasReports'],
		}),
		uploadAssetNewList: builder.mutation<
			void,
			{ file: FormData; month: string; vId: number }
		>({
			query: ({ file, month, vId }) => ({
				url: `picasReport/uploadpicasreport?uploadedMonthYear=${month}&picasReportId=${vId}`,
				method: 'POST',
				body: file,
			}),
			invalidatesTags: ['PicasNewReports'],
		}),
		getChargingOverview: builder.query<IAllAssets, void>({
			query: () => `picasReport/getccrecords`,
		}),
		getAssetListByCC: builder.query<any, number>({
			query: (CCId) => `picasReport/getccdetails?ccId=${CCId}`,
		}),
		getAssetList: builder.query<IAssetList[], void>({
			query: () => `picasReport/getassetlist`,
			providesTags: ['AssetList'],
		}),
		getInvalidAssetList: builder.query<IAssetList[], void>({
			query: () => `picasReport/getinvalidassetlist`,
			providesTags: ['InvalidAssetList'],
		}),

		addNewAsset: builder.mutation<any, INewAsset>({
			query: (newAsset) => ({
				url: `picasReport/saveassetdata`,
				method: 'POST',
				body: newAsset,
			}),
			invalidatesTags: ['AssetList'],
		}),

		deleteAsset: builder.mutation<any, number>({
			query: (id) => ({
				url: `picasReport/deleteassetdata/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['AssetList'],
		}),

		deleteInvalidAsset: builder.mutation<any, number>({
			query: (id) => ({
				url: `picasReport/deleteinvalidassetdata/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['InvalidAssetList'],
		}),

		uploadAssetPriceList: builder.mutation<
			void,
			{ file: FormData; month: string; }
		>({
			query: ({ file, month }) => ({
				url: `picasReport/uploadassetinfo?uploadedMonthYear=${month}`,
				method: 'POST',
				body: file,
			}),
			invalidatesTags: ['AssetList'],
		}),
		
		updateInvalidAsset: builder.mutation<any, INewAsset>({
			query: (invalidAsset) => ({
				url: `picasReport/updateinvalidasset`,
				method: 'POST',
				body: invalidAsset,
			}),
			invalidatesTags: ['InvalidAssetList'],
		}),

		getNewPicasDetails: builder.query<IPicasDetails, picasDetailsType>({
			query: ({ ccId, month, year }) =>
				`picasReport/getnewpicasreportdetails?costcenterId=${ccId}&month=${month}&year=${year}`,
		}),
	}),
});

export const {
	useGetPicasDetailsQuery,
	useLazyGetPicasDetailsQuery,
	useGetPicasExcelExportMutation,
	useDeletePicasReportMutation,
	useDeleteNewPicasReportMutation,
	useUploadAssetListMutation,
	useUploadAssetNewListMutation,
	useUploadAssetPriceListMutation,
	useAddNewAssetMutation,
	useGetChargingOverviewQuery,
	useDeleteAssetMutation,
	useLazyGetAssetListByCCQuery,
	useGetPicasCommentsMutation,
	useSavePicasCommentsMutation,
	useGetAssetListQuery,
	useLazyGetAssetListQuery,
	useGetInvalidAssetListQuery,
	useLazyGetInvalidAssetListQuery,
	useDeleteInvalidAssetMutation,
	useUpdateInvalidAssetMutation,
	useLazyGetNewPicasDetailsQuery
} = picasApi;

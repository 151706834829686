import { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { Divider } from 'primereact/divider';
import { useDispatch, useSelector } from 'react-redux';
import { getServiceOptions, setServiceOptions } from '../../store/commonSlice';
import InputField from '../../components/input-field/input-field';
import DisplayIcon from '../../components/icon';
import { InputTextarea } from 'primereact/inputtextarea';
import {
	useGetAllCommentsMutation,
	useGetAllMonthlyServiceDataMutation,
	useGetILVCommentMutation,
	useSaveCommentMutation,
	useSaveILVCommentMutation,
} from '../../store/servicesApi';
import { IServiceData } from '.';
import {
	ICostCenterServiceData,
	IService,
	IServiceDetails,
} from '../../types/service.interfaces';
import { ICostCenterData } from '../cost-center';
import {
	checkAzureService,
	formatDateDDMMYYYY,
} from '../../utils/utility-function';
import {
	useGetPicasCommentsMutation,
	useSavePicasCommentsMutation,
} from '../../store/picasApi';
import { useParams } from 'react-router-dom';
import { useGetAllArchivedCommentsMutation } from '../../store/archiveApi';
import { Skeleton } from 'primereact/skeleton';
import { toast } from 'react-toastify';
import useGetOU from '../../hooks/useGetOU';

const CommentsDialog = ({
	costCenter,
	service,
	isArchive,
	callback,
}: {
	costCenter: ICostCenterData;
	service: ICostCenterServiceData;
	isArchive: boolean;
	callback: (e?: any) => any;
}) => {
	const dispatch = useDispatch();
	const openModal = useSelector(getServiceOptions);
	const [formData, setFormData] = useState<any>({});
	const [ILVComment, setILVComment] = useState('');
	const [originalILVComment, setOriginalILVComment] = useState('');
	const [comment, setComment] = useState('');
	const [allComments, setAllComments] = useState<any>();
	const params = useParams();
	const archiveVersion = params.versionId
		? Number(params.versionId)
		: undefined;
	
	const OU = useGetOU();

	const [getILVComment, { isLoading: isLoadingILVComment }] =
		useGetILVCommentMutation();

	const [getPicasComment, { isLoading: isLoadingPicasComment }] =
		useGetPicasCommentsMutation();

	const [getAllComments, { isLoading: isLoadingComments }] =
		useGetAllCommentsMutation();

	const [getAllArchiveComments, { isLoading: isArchiveCommentsLoading }] =
		useGetAllArchivedCommentsMutation();

	const [saveILVComment, { isLoading: isSavingILVComment }] =
		useSaveILVCommentMutation();

	const [saveComment, { isLoading: isSavingComment }] =
		useSaveCommentMutation();
	const [savePicasComment, { isLoading: isSavingPicasComment }] =
		useSavePicasCommentsMutation();

	useEffect(() => {
		const getILVComments = async () => {
			const fetchComments = await getILVComment({
				additionalInformation: checkAzureService(service),
				cc_Id: costCenter.id,
				comment: 'null',
				said: service.said,
				serviceId: service.serviceId,
				serviceName: service.serviceName,
				versionId: 0,
				year: 0,
				months: [0],
				OU
			}).unwrap();
			console.log('ILV Comments :: ', fetchComments);

			let checkComment;
			try {
				checkComment = JSON.parse(fetchComments);
				checkComment = '';
			} catch (e) {
				checkComment = fetchComments;
				console.log('Setting comment from fetch ::', checkComment);
			}

			setILVComment(checkComment);
			setOriginalILVComment(checkComment);
		};
		const getComments = async () => {
			console.log('Get Comments :: ', service);
			let azureService = undefined;
			if (
				service.serviceName.toLocaleLowerCase().includes('gb4') &&
				service.serviceId === '20023510' &&
				Object.keys(service).indexOf('additionalInfo') === -1
			) {
				azureService = 'null';
			}

			if (!isArchive) {
				const fetchComments =
					service.serviceName !== 'Picas Report'
						? await getAllComments({
								cc_Id: costCenter.id.toString(),
								said: service.said,
								serviceId: service.serviceId,
								serviceName: service.serviceName,
								versionId: 0,
								additionalInformation: azureService || service.additionalInfo,
								comment: 'null',
								OU
						  }).unwrap()
						: await getPicasComment({
								ccId: costCenter.id,
								versionId: archiveVersion || 0,
						  }).unwrap();

				setAllComments(fetchComments);
			} else {
				console.log('Fetch Archive Comments');
				const fetchComments =
					service.serviceName !== 'Picas Report'
						? await getAllArchiveComments({
								cc_Id: costCenter.id.toString(),
								said: service.said,
								serviceId: service.serviceId,
								serviceName: service.serviceName,
								versionId: archiveVersion || 0,
								additionalInformation: azureService || service.additionalInfo,
								comment: 'null',
								OU
						  }).unwrap()
						: await getPicasComment({
								ccId: costCenter.id,
								versionId: archiveVersion || 0,
						  }).unwrap();

				setAllComments(fetchComments);
			}
		};
		if (!isArchive && service.serviceName !== 'Picas Report') {
			getILVComments();
		}
		getComments();
	}, [service]);

	const renderFooter = (name: any) => {
		return (
			<div onClick={(e) => e.stopPropagation()}>
				<Button
					label="Close"
					icon="pi pi-times"
					onClick={() => {
						dispatch(setServiceOptions({ showComments: false }));
						callback();
					}}
					className="p-button-text"
				/>
				{!isArchive && (
					<Button
						label="Save Comments"
						icon="pi pi-save"
						onClick={async () => {
							if (service.serviceName !== 'Picas Report') {
								let azureService = undefined;
								if (
									service.serviceName.toLocaleLowerCase().includes('gb4') &&
									service.serviceId === '20023510' &&
									Object.keys(service).indexOf('additionalInfo') === -1
								) {
									azureService = 'null';
								}

								if (ILVComment !== originalILVComment) {
									await saveILVComment({
										id: 0,
										costCenter_FK: costCenter.id,
										targetCostCenter: costCenter.target_costcenter,
										targetResponsible: costCenter.target_responsible,
										keyWord: '',
										comments: ILVComment,
										said: service.said,
										serviceId: service.serviceId,
										serviceName: service.serviceName,
										additionalInfo: azureService || service.additionalInfo,
										OU
									})
										.unwrap()
										.then((payload: any) =>
											toast.success('ILV Comment Added/Updated Successfully')
										)
										.catch((error: any) =>
											toast.error('Error Adding/Upadting ILV Comment')
										);
								} else {
									console.log('No Change in ILV Comments');
								}

								if (comment !== '') {
									await saveComment({
										cc_Id: costCenter.id.toString(),
										said: service.said,
										serviceId: service.serviceId,
										serviceName: service.serviceName,
										versionId: 0,
										additionalInformation:
											azureService || service.additionalInfo,
										comment: comment,
										OU
									})
										.unwrap()
										.then((payload: any) =>
											toast.success('Service Comment Updated Successfully')
										)
										.catch((error: any) =>
											toast.error('Error Adding Service Comment')
										);
								} else {
									console.log('Comments are not updated');
								}
							} else {
								await savePicasComment({
									cc_Id: costCenter.id,
									comment: comment,
								})
									.unwrap()
									.then(async (payload: any) => {
										toast.success('Picas Comment Updated Successfully');
										await getPicasComment({
											ccId: costCenter.id,
											versionId: archiveVersion || 0,
										});
									})
									.catch((error: any) =>
										toast.error('Error Adding Picas Comment')
									);
							}
							setComment('');
							setILVComment('');
							dispatch(setServiceOptions({ showComments: false }));
						}}
						loading={
							isLoadingComments ||
							isLoadingPicasComment ||
							isSavingComment ||
							isSavingPicasComment ||
							isSavingILVComment
						}
					/>
				)}
			</div>
		);
	};

	return (
		<Dialog
			header="Service Comments"
			visible={openModal.showComments}
			style={{ width: '75vw' }}
			footer={renderFooter('displayBasic')}
			onHide={() => {
				dispatch(setServiceOptions({ showComments: false }));
			}}
			onClick={(e) => e.stopPropagation()}
			className="service-comment"
		>
			{service.serviceName !== 'Picas Report' && !isArchive && (
				<>
					<h5>ILV Comments</h5>
					<div className="p-fluid grid formgrid mt-5">
						<div className="grid col-12">
							<div className="field col-12 mt-3">
								<span className="p-float-label">
									<InputTextarea
										id="ilvComment"
										name="ilvComment"
										rows={5}
										cols={30}
										value={ILVComment}
										onChange={(e) => setILVComment(e.target.value)}
										autoResize
										disabled={
											isLoadingComments ||
											isLoadingPicasComment ||
											isSavingComment ||
											isSavingPicasComment ||
											isSavingILVComment
										}
									/>
									<label htmlFor="ilvComment">ILV Comment</label>
								</span>
							</div>
						</div>
					</div>
					<Divider />
				</>
			)}
			<h5>Comments</h5>
			<div className="p-fluid grid formgrid mt-5">
				<div className="grid col-12">
					{isLoadingComments ||
					isLoadingPicasComment ||
					isSavingComment ||
					isSavingPicasComment ||
					isSavingILVComment ? (
						<div className="field col-6 mt-3 flex flex-column">
							<Skeleton className="w-full" height="1rem" />
							<Skeleton className="w-full mt-5" height="1rem" />
							<Skeleton className="w-full mt-5" height="1rem" />
						</div>
					) : (
						<div className="field col-6 mt-3 flex flex-column">
							{allComments ? (
								allComments.length === 0 ? (
									<span className="placeholder-text">No Comments...</span>
								) : (
									allComments.map((comment: any) => (
										<span className="ml-2 mt-2" key={comment.updatedOn}>
											<i>
												{formatDateDDMMYYYY(comment.updatedOn)} -
												{comment.updatedBy.firstName}{' '}
												{comment.updatedBy.lastName}
											</i>{' '}
											- <span className="bold-text"> {comment.comments}</span>
										</span>
									))
								)
							) : (
								<span className="placeholder-text">No Comments...</span>
							)}
						</div>
					)}
					{!isArchive && (
						<div className="field col-6 mt-3">
							<span className="p-float-label">
								<InputTextarea
									id="comment"
									name="comment"
									rows={5}
									cols={30}
									value={comment}
									onChange={(e) => setComment(e.target.value)}
									autoResize
									disabled={
										isLoadingComments ||
										isLoadingPicasComment ||
										isSavingComment ||
										isSavingPicasComment ||
										isSavingILVComment
									}
								/>
								<label htmlFor="comment">Comment</label>
							</span>
						</div>
					)}
				</div>
			</div>
			<Divider />
		</Dialog>
	);
};

export default CommentsDialog;

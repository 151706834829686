import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { Calendar } from 'primereact/calendar';
import { useDispatch, useSelector } from 'react-redux';
import InputField from '../../components/input-field/input-field';
import {
	getAssetListModalState,
	getIsCostController,
	setAssetListModal,
	setDataProcessingModal,
} from '../../store/commonSlice';
import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import {
	useDeleteAssetMutation,
	useLazyGetAssetListQuery,
	useLazyGetInvalidAssetListQuery,
	useDeleteInvalidAssetMutation,
	useUpdateInvalidAssetMutation,
} from '../../store/picasApi';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { formatCurrency, getFullMonth } from '../../utils/utility-function';
import DisplayIcon from '../../components/icon';
import { useAddNewAssetMutation } from '../../store/picasApi';
import { toast } from 'react-toastify';
import CustomAutoComplete from '../../components/autocomplete/autocomplete';
import { Months } from '../../utils/json/months';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

export interface IAssetList {
	id_PK: number;
	id: string;
	tafpId: string;
	name: string;
	price: number;
	month: string;
	uploadedBy: {
		email: string;
		firstName: string;
		id: number;
		identifer: number;
		lastName: string;
		isApproval: boolean;
	};
	uploadedOn: string;
}

const addMappingSchema = Yup.object().shape({
	id: Yup.string().required('Required !'),
	name: Yup.string().required('Required !'),
	price: Yup.string().required('Required !'),
	month: Yup.string().required('Required !'),
});

const SetAssetPrice = () => {
	const [getAssetList, {
		data,
		isLoading: isLoadingAssets,
		isSuccess,
		isError,
		error,
	}] = useLazyGetAssetListQuery();
	const [getInvalidAssetList, {
		data: invalidAssetdata,
		isLoading: isLoadingInvalidAssets,
	}] = useLazyGetInvalidAssetListQuery();
	const [addNewAsset, { isLoading: isAssetAdding }] = useAddNewAssetMutation();
	const [deleteAsset, { isLoading: isLoadingDelete }] =
		useDeleteAssetMutation();
	const [deleteInvalidAsset, { isLoading: isLoadingInvalidDelete }] =
		useDeleteInvalidAssetMutation();
	const [updateInvalidAsset, { isLoading: isAddingInvalidAsset }] = useUpdateInvalidAssetMutation();
	const [formData, setFormData] = useState<any>({});
	const dispatch = useDispatch();
	const assetModal = useSelector(getAssetListModalState);
	const [selectedMonth, setSelectedMonth] = useState(null);
	const formik = useFormik({
		initialValues: {
			id: '',
			tafpId: '',
			price: '',
			name: '',
			month: ''
		},
		validationSchema: addMappingSchema,
		onSubmit: (data) => {
			setFormData(data);
			formik.resetForm();
		},
		validateOnBlur: true,
	});
	const [deleteModal, setDeleteModal] = useState(false);
	const [id, setId] = useState(0);
	const [editAsset, setEditAsset] = useState<any>(undefined);
	const [date, setDate] = useState<any>(undefined);
	const [chargingTypeValue, setchargingType] = useState<any>(undefined);

	let today = new Date();
	let month = today.getMonth();
	let year = today.getFullYear();
	let prevMonth = (month === 0) ? 11 : month - 1;
	let prevYear = (prevMonth === 11) ? year - 1 : year;

	let minDate = new Date();
	minDate.setMonth(month);
	minDate.setFullYear(year);

	useEffect(() => {
		if (assetModal.assetPriceConfiguration) {
			const getAssetData = async () => {
				if (assetModal.showInvalidAsset) {
					getInvalidAssetList().unwrap();
				} else {
					getAssetList().unwrap();
				}
			}
			getAssetData();
		}
	}, [assetModal.assetPriceConfiguration])

	const action = (rowData: any) => {
		return (
			<div className="table-menu">
				{
					editAsset !== undefined && rowData.id_PK === editAsset.id_PK ? (
						<>
							<Button
								className="p-button-rounded p-button-text p-button-icon-only"
								tooltipOptions={{ position: 'left' }}
								// loading={isICAUpdate}
								onClick={() => {
									updateAssetPriceData()
									setEditAsset(undefined);
								}}
								tooltip="Update Asset"
							>
								<DisplayIcon icon="tablerSave" className="table-action" />
							</Button>
							<Button
								className="p-button-rounded p-button-text p-button-icon-only"
								tooltipOptions={{ position: 'left' }}
								onClick={() => {
									setEditAsset(undefined);
								}}
								tooltip="Cancel Update"
							>
								<DisplayIcon icon="tablerCancel" className="table-action" />
							</Button>

						</>
					) : (
						<>
							<Button
								className="p-button-rounded p-button-text p-button-icon-only"
								tooltipOptions={{ position: 'left' }}
								onClick={(e) => {
									e.stopPropagation();
									setId(rowData.id_PK);
									setDeleteModal(true);
								}}
								tooltip="Delete Asset"
							>
								<DisplayIcon icon="tablerTrash" className="table-action" />
							</Button>
							<Button
								className="p-button-rounded p-button-text p-button-icon-only"
								tooltipOptions={{ position: 'left' }}
								onClick={() => {
									// setModalType('ICA');
									console.log(rowData);
									setEditAsset(rowData);
								}}
								tooltip="Edit Asset"
							>
								<DisplayIcon icon="edit" className="table-action" />
							</Button>
						</>
					)
				}
			</div>
		);
	};
	const clickAddNewAsset = async () => {
		await addNewAsset({
			id: formik.values.id,
			tafpId: formik.values.tafpId,
			name: formik.values.name,
			price: formik.values.price,
			validity: date !== undefined && date !== null ? `${getFullMonth(date)}-${date.getFullYear()}` : '',
			chargingType: chargingTypeValue !== undefined && chargingTypeValue !== null ? chargingTypeValue : '',
			id_PK: 0,
		})
			.unwrap()
			.then((payload: any) =>
				toast.success('Added new asset successfully', {
					position: 'bottom-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				})
			)
			.catch((e: any) =>
				toast.error('Failed adding asset', {
					position: 'bottom-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				})
			);

		formik.resetForm();
		setDate(undefined)
		setchargingType(undefined)

	};
	const deleteFooter = () => {
		return (
			<div>
				<Button
					label="Cancel"
					icon="pi pi-times"
					onClick={() => {
						setDeleteModal(false);
						setId(0);
					}}
					className="p-button-text"
				/>
				<Button
					label="Delete"
					icon="pi pi-trash"
					onClick={() => deleteAssetData()}
				/>
			</div>
		);
	};
	const deleteAssetData = async () => {
		if (assetModal.showInvalidAsset) {
			await deleteInvalidAsset(id)
				.unwrap()
				.then((payload: any) =>
					toast.success('Invalid Asset Deleted successfully', {
						position: 'bottom-right',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					})
				)
				.catch((e: any) =>
					toast.error('Failed deleting asset', {
						position: 'bottom-right',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					})
				);
		} else {
			await deleteAsset(id)
				.unwrap()
				.then((payload: any) =>
					toast.success('Asset Deleted successfully', {
						position: 'bottom-right',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					})
				)
				.catch((e: any) =>
					toast.error('Failed deleting asset', {
						position: 'bottom-right',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					})
				);
		}

		setDeleteModal(false);
	}

	const validityBody = (rowData: any) => {

		if (editAsset !== undefined && rowData.id === editAsset.id) {
			return (
				<Calendar id="validityCalendar" value={(new Date(editAsset.new_Validity))} onChange={(item: any) => {
					const dateValue = item.value;
					setEditAsset({ ...editAsset, new_Validity: `${getFullMonth(dateValue)}-${dateValue.getFullYear()}` });
				}}
					view="month" dateFormat="MM-yy" minDate={minDate}
				/>
			)
		} else {
			return rowData.new_Validity;
		}
	}

	const chargingTypeList = [
		{ chargingType: "monatlich", value: "monatlich" },
		{ chargingType: "einmalig", value: "einmalig" },
	]
	
	const chargingTypeBody = (rowData: any) => {

		if (editAsset !== undefined && rowData.id === editAsset.id) {
			return (
				<Dropdown
					value={editAsset.chargingType}
					options={chargingTypeList}
					onChange={(item) => {
						setEditAsset({ ...editAsset, chargingType: item.value });
						console.log(editAsset)
					}}
					optionLabel="chargingType"
					id="chargingType"
					name="chargingType"
				/>
			)
		} else {
			return rowData.chargingType;
		}
	}

	const priceBody = (rowData: any) => {

		if (editAsset !== undefined && rowData.id === editAsset.id) {
			return (

				<InputText id="priceInput"
					value={editAsset.new_Price}
					onChange={(item: any) => {
						console.log(item.target.value);
						setEditAsset({ ...editAsset, new_Price: item.target.value });
					}}
				/>
			)
		} else {
			return formatCurrency(rowData.new_Price)
		}
	}

	const tafpIdBody = (rowData: any) => {

		if (editAsset !== undefined && rowData.id === editAsset.id) {
			return (

				<InputText id="tafpIdInput"
					value={editAsset.tafpId}
					onChange={(item: any) => {
						console.log(item.target.value);
						setEditAsset({ ...editAsset, tafpId: item.target.value });
					}}
				/>
			)
		} else {
			return rowData.tafpId
		}
	}

	const updateAssetPriceData = async () => {
		if (assetModal.showInvalidAsset) {
			await updateInvalidAsset({
				id: editAsset.id,
				tafpId: editAsset.tafpId,
				name: editAsset.name,
				price: editAsset.new_Price,
				validity: editAsset.new_Validity,
				chargingType: editAsset.chargingType,
				id_PK: editAsset.id_PK,
			})
				.unwrap()
				.then((payload: any) => {
					toast.success('Asset Updated Successfully')
					setEditAsset(undefined);
				})
				.catch((error: any) => toast.error(error.data.message));
		} else {
			await addNewAsset({
				id: editAsset.id,
				tafpId: editAsset.tafpId,
				name: editAsset.name,
				price: editAsset.new_Price,
				validity: editAsset.new_Validity,
				chargingType: editAsset.chargingType,
				id_PK: editAsset.id_PK,
			})
				.unwrap()
				.then((payload: any) => {
					toast.success('Asset Updated Successfully')
					setEditAsset(undefined);
				})
				.catch((error: any) => toast.error(error.data.message));
		}
	}
	
	const isCostController = useSelector(getIsCostController);

	return (
		<>
			<Dialog
				header="Asset Configuration Price"
				visible={assetModal.assetPriceConfiguration}
				style={{ width: '75vw' }}
				onHide={() =>
					dispatch(setAssetListModal({ assetPriceConfiguration: false }))
				}
			>
				<div className="p-fluid grid formgrid mt-2" style={{ display: assetModal.showInvalidAsset ? 'none' : 'block' }}>
					<div className="flex align-items-center justify-content-end grid col-12">
						{!isCostController && <Button
							icon="pi pi-filter"
							className="p-button-rounded p-button-text p-button-plain p-button-icon-only"
							tooltip="Upload Asset Price List"
							onClick={() =>
								dispatch(
									setDataProcessingModal({ uploadChargingReport: true, uploadAssetPriceList: true })
								)
							}
						>
							<DisplayIcon icon="upload" className="action-icon" />
						</Button>}
					</div>
					<div className="grid col-12">
						<InputField
							field="id"
							formik={formik}
							label="Id"
							colSpan={2}
							disabled={isAssetAdding}
						/>
						<InputField
							field="tafpId"
							formik={formik}
							label="TAFP Id"
							colSpan={2}
							disabled={isAssetAdding}
						/>
						<InputField
							field="name"
							formik={formik}
							label="Name"
							colSpan={2}
							disabled={isAssetAdding}
						/>
						<InputField
							field="price"
							formik={formik}
							label="Price"
							colSpan={2}
							disabled={isAssetAdding}
						/>
						{/* <CustomAutoComplete
							className='field col-2 mt-3'
							customId="select-month"
							placeholder="Select Month"
							inputList={Months}
							field="month"
							target="month"
							defaultValue={selectedMonth}
							callback={(item) => {
								if (item) {
									setSelectedMonth(item.month);
								} else {
									setSelectedMonth(null);
								}
							}}
						/> */}
						<div className={`field col-2 mt-3`}>
							<span className="p-float-label">
								<Calendar id="valitidy" value={date} onChange={(e: any) => {
									setDate(e.value);
								}}
									view="month" dateFormat="MM-yy" minDate={minDate}
								/>
								<label htmlFor="valitidy">Validity</label>
							</span>
						</div>
						<div className={`field col-2 mt-3`}>
							<span className="p-float-label">
								<Dropdown
									value={chargingTypeValue}
									options={chargingTypeList}
									onChange={(item) => {
										setchargingType(item.value);
									}}
									optionLabel="chargingType"
									id="chargingType"
									name="chargingType"
									disabled={isAssetAdding}
								/>
								<label htmlFor="chargingType">Charging Type</label>
							</span>
						</div>
						<div className={`field col-2 mt-3`}>
							<Button
								label="Add Asset"
								onClick={clickAddNewAsset}
								icon="pi pi-save"
								className="p-button-sm"
								loading={isAssetAdding}
							/>
						</div>
					</div>
				</div>
				<Divider style={{ display: assetModal.showInvalidAsset ? 'none' : 'block' }} />

				<DataTable
					header={<h5>Existing{assetModal.showInvalidAsset ? ' Invalid' : ''} Assets</h5>}
					value={assetModal.showInvalidAsset ? invalidAssetdata : data}
					responsiveLayout="scroll"
					lazy
					loading={isLoadingAssets || isLoadingDelete || isLoadingInvalidAssets || isLoadingInvalidDelete || isAddingInvalidAsset || isAssetAdding}
				>
					<Column field="id" header="Id" sortable></Column>
					<Column field="tafpId" header="TAFP Id" body={tafpIdBody} sortable></Column>
					<Column field="name" style={{ overflowWrap: 'break-word', width: '15%' }} header="Name" sortable></Column>
					<Column
						field="price"
						style={{ width: '10%' }}
						header="Price"
						body={(data) => {
							return assetModal.showInvalidAsset ? data.assetCost : formatCurrency(data.price)
						}}
						sortable
					></Column>
					<Column
						field="old_Price"
						style={{ width: '10%' }}
						header="Old Price"
						body={(data) => formatCurrency(data.old_Price)}
						sortable
					></Column>
					<Column field="old_Validity" header="Old Validity" style={{ width: '8%' }} sortable></Column>
					<Column field="new_Price" body={priceBody} style={{ width: '8%' }} header="New Price" sortable></Column>
					<Column field="new_Validity" header="New Validity" style={{ minWidth: "10em" }} body={validityBody} sortable></Column>
					<Column field="chargingType" header="Charging Type" style={{ width: '20%' }} body={chargingTypeBody} sortable></Column>
					<Column header="Actions" body={action} />
				</DataTable>
			</Dialog>

			<Dialog
				header="Asset Deletion Confirmation"
				visible={deleteModal}
				onHide={() => {
					setDeleteModal(false);
				}}
				footer={deleteFooter}
			>
				<div className="p-fluid grid formgrid mt-5">
					<div className="grid col-12">
						<p>
							Do you really want to delete the Asset ?
							<br />
							<br />
							Your action is irreversible !
						</p>
					</div>
				</div>
			</Dialog>
		</>
	);
};

export default SetAssetPrice;

import { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Divider } from 'primereact/divider';
import { useDispatch, useSelector } from 'react-redux';
import { setSettingsModal, settingsModalState } from '../../store/commonSlice';
import { useAddNewMappingMutation } from '../../store/servicesApi';
import * as Yup from 'yup';
import InputField from '../../components/input-field/input-field';
import CustomToast from '../../components/toaster/toaster';

const addMappingSchema = Yup.object().shape({
	serviceId: Yup.string().required('Required !'),
	serviceName: Yup.string().required('Required !'),
	said: Yup.string().required('Required !'),
	additionaInformation: Yup.string(),
	targetCostCenter: Yup.string().required(),
	keyWord: Yup.string(),
	comments: Yup.string(),
});

const AddMappingModal = () => {
	const dispatch = useDispatch();
	const openModal = useSelector(settingsModalState);
	const [formData, setFormData] = useState<any>({});

	const [
		saveNewMapping,
		{
			isLoading: isAddingMapping,
			isSuccess: isAddingSuccess,
			isError: isAddingError,
			error: addingError,
		},
	] = useAddNewMappingMutation();

	const formik = useFormik({
		initialValues: {
			comments: '',
			serviceId: '',
			serviceName: '',
			said: '',
			additionalInfo: '',
			targetCostCenter: '',
			targetResponsible: '',
			keyWord: '',
			id: 0,
			costCenter_FK: 0,
		},
		validationSchema: addMappingSchema,
		onSubmit: (data) => {
			setFormData(data);
			formik.resetForm();
		},
		validateOnBlur: true,
	});
	const renderFooter = (name: any) => {
		return (
			<div>
				<Button
					label="Cancel"
					icon="pi pi-times"
					onClick={() => {
						formik.resetForm();
						dispatch(setSettingsModal({ addMapping: false }));
					}}
					className="p-button-text"
				/>
				<Button
					label="Add Mapping"
					icon="pi pi-save"
					disabled={!formik.isValid || !formik.dirty}
					loading={isAddingMapping}
					onClick={async () => {
						await saveNewMapping(formik.values);
						dispatch(setSettingsModal({ addMapping: false }));
					}}
				/>
			</div>
		);
	};

	return (
		<>
			{' '}
			<Dialog
				header="Add Mapping"
				visible={openModal.addMapping}
				style={{ width: '75vw' }}
				footer={renderFooter('displayBasic')}
				onHide={() => {
					formik.resetForm();
					dispatch(setSettingsModal({ addMapping: false }));
				}}
			>
				<form onSubmit={formik.handleSubmit}>
					<h5 className="section-title">Mapping</h5>
					<div className="p-fluid grid formgrid mt-5">
						<div className="grid col-12">
							<InputField
								field="serviceId"
								formik={formik}
								label="Service Id"
							/>
							<InputField
								field="serviceName"
								formik={formik}
								label="Service Name"
							/>
							<InputField field="said" formik={formik} label="SAID" />
							<InputField
								field="additionalInfo"
								formik={formik}
								label="Additional Info."
							/>

							<InputField
								formik={formik}
								type="autocomplete-ccname"
								field="targetCostCenter"
								callback={(o) => {
									if (o !== undefined) {
										formik.setFieldValue(
											'targetCostCenter',
											o.target_costcenter
										);
										formik.setFieldValue(
											'targetResponsible',
											o.user_responsible
										);
										formik.setFieldValue('costCenter_FK', o.id);
									} else {
										console.log('executed :(');
										formik.setFieldValue('targetResponsible', '');
										formik.setFieldValue('targetCostCenter', '');
										formik.setFieldValue('costCenter_FK', 0);
									}
								}}
							/>
							<InputField
								field="targetResponsible"
								formik={formik}
								label="Target Responsible"
							/>
							<InputField field="keyWord" formik={formik} label="Keyword" />
						</div>
					</div>
					<Divider />
					<h5 className="section-title">Comments</h5>
					<div className="p-fluid grid formgrid mt-5">
						<div className="grid col-12">
							<InputField
								field="comments"
								formik={formik}
								label="Comments"
								type="textarea"
							/>
						</div>
					</div>
					<Divider />
					<h5 className="section-title">Updated</h5>
					<div className="p-fluid grid formgrid mt-5">
						<div className="grid col-12">
							<InputField
								field="updatedBy"
								formik={formik}
								label="Updated By"
							/>
							<InputField
								field="updatedOn"
								formik={formik}
								label="Updated On"
							/>
						</div>
					</div>
				</form>
			</Dialog>
			<CustomToast
				isSuccess={isAddingSuccess}
				isError={isAddingError}
				successSummary="Added"
				successMessage="New Mapping is added successfully"
				errorMessage={addingError}
			/>
		</>
	);
};

export default AddMappingModal;

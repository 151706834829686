import { useEffect, useMemo, useState } from 'react';
import { Card } from 'primereact/card';
import release from './release.json';

const ReleaseNotes: React.FC = function () {
    return (
        <>
            {
                release?.map((item, index) => {
                    return (
                        <Card key={index}>
                            {/* <h1 className='mb-2' style={{ fontSize: '2em', fontWeight: 'bold' }}>Latest Updates</h1> */}
                            {/* <p className="ml-4 mb-4" style={{ fontSize: '1.1em' }}>
                            <strong style={{ fontSize: '1.2em', fontWeight: '500', lineHeight: '2' }}>Dear XCharge users,</strong> <br />We have some exciting news to share with you!  <br />In the past few months, we have been working on developing new features for the XCharge application, and we are pleased to announce their release. Below is a brief overview of the major new functionalities.
                        </p> */}
                            <div>
                                <h1 className='mb-2' style={{ fontSize: '1.3em', fontWeight: 'bold' }}>{item.date}</h1>
                                <p className="ml-4 mb-4" style={{ fontSize: '1.1em' }}>
                                    {item.version}
                                </p>
                                {
                                    item.new &&
                                    <ul className="ml-4 mb-4">
                                        <h1 className='mb-2' style={{ fontSize: '1.3em', fontWeight: 'bold' }}>New</h1>
                                        {
                                            item.new.map((list, index) => {
                                                return (
                                                    <li className='ml-4' style={{ fontSize: '1.1em', lineHeight: '1.3em' }} key={index}>{list}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                }
                                {/* {
                                    item.bug &&
                                    <ul className="ml-4 mb-4">
                                        <h1 className='mb-2' style={{ fontSize: '1.3em', fontWeight: 'bold' }}>Bugs</h1>
                                        {
                                            item.bug.map((list, index) => {
                                                return (
                                                    <li className='ml-4' style={{ fontSize: '1.1em', lineHeight: '1.3em' }} key={index}>{list}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                } */}
                                {
                                    item.fix &&
                                    <ul className="ml-4">
                                        <h1 className='mb-2' style={{ fontSize: '1.3em', fontWeight: 'bold' }}>Bug Fixes</h1>
                                        {
                                            item.fix.map((list, index) => {
                                                return (
                                                    <li className='ml-4' style={{ fontSize: '1.1em', lineHeight: '1.3em' }} key={index}>{list}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                }
                            </div>
                            {/* <h1 className='mb-4' style={{ fontSize: '1.4em', fontWeight: 'bold' }}>Deputy Function:</h1> */}
                            {
                                item.misc &&
                                <p className="ml-4 mb-4" style={{ fontSize: '1.1em' }}>
                                    {item.misc}
                                </p>
                            }
                            {/* <p className="ml-4" style={{ fontSize: '1.1em' }}>
                            Best regards, <br /> The XCharge Team
                        </p> */}
                            {/* <hr className='mb-4' /> */}
                        </Card>
                    )
                })
            }
        </>
    )
}

export default ReleaseNotes;
import { render } from '@testing-library/react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { classNames } from 'primereact/utils';
import AutoCompleteCostCenterName from '../autocomplete-costcenter-name';
import CustomAutoComplete, {
	IAutoCompleteProps,
} from '../autocomplete/autocomplete';

import './style.scss';

type inputType =
	| 'input'
	| 'textarea'
	| 'autocomplete'
	| 'autocomplete-ccname'
	| 'autocomplete-ccid'
	| undefined;

interface ICommonInput {
	field: string;
	label: string;
	formik: any;
	id?: string;
}

type neuExp =
	| {
			type: 'autocomplete';
			colSpan?: number;
			field: string;
			target: string;
			label: string;
			formik: any;
			id?: string;
			multiple?: boolean;
			placeholder?: string;
			inputList: any[];
			itemTemplate?: (item: any) => JSX.Element;
			callback?: (result: any) => void;
			defaultValue?: any;
			hideClear?: boolean;
			disabled?: boolean;
			overrideSelectedValue?: any;
	  }
	| {
			type: 'autocomplete-ccname';
			colSpan?: number;
			field: string;
			target?: never;
			label?: never;
			formik: any;
			id?: never;
			multiple?: never;
			placeholder?: never;
			inputList?: never;
			itemTemplate?: never;
			callback?: (result: any) => void;
			defaultValue?: any;
			hideClear?: boolean;
			disabled?: boolean;
			overrideSelectedValue?: any;
	  }
	| {
			type?: 'input' | 'textarea' | undefined;
			colSpan?: number;
			field: string;
			label: string;
			formik: any;
			id?: string;
			target?: never;
			multiple?: never;
			placeholder?: string;
			inputList?: never;
			itemTemplate?: never;
			callback?: never;
			defaultValue?: any;
			hideClear?: never;
			disabled?: boolean;
			overrideSelectedValue?: never;
	  };

const InputField = ({
	field,
	colSpan = 4,
	label,
	formik,
	type,
	id,
	target,
	placeholder,
	callback,
	inputList,
	itemTemplate,
	defaultValue,
	multiple,
	hideClear,
	disabled,
	overrideSelectedValue,
}: neuExp) => {
	type fieldName = keyof typeof formik.values;

	const isFormFieldValid = (name: fieldName) =>
		!!(formik.touched[name] && formik.errors[name]);

	const getFormErrorMessage = (name: fieldName) => {
		return (
			isFormFieldValid(name) && (
				<small className="p-error">{formik.errors[name]}</small>
			)
		);
	};

	const renderInputByType = (type: inputType) => {
		if (type === 'textarea' && field) {
			return (
				<span className="p-float-label">
					<InputTextarea
						id={field}
						name={field}
						rows={5}
						cols={30}
						value={formik.values[field]}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						className={classNames({
							'p-invalid': isFormFieldValid(field),
						})}
						autoResize
						disabled={disabled}
					/>
					<label
						htmlFor={field}
						className={classNames({
							'p-error': isFormFieldValid(field),
						})}
					>
						{label}
					</label>
				</span>
			);
		} else if (type === 'autocomplete' && inputList !== undefined) {
			return (
				<CustomAutoComplete
					label={label}
					customId={id}
					callback={callback}
					placeholder={placeholder}
					inputList={inputList}
					itemTemplate={itemTemplate}
					target={target}
					field={field}
					defaultValue={defaultValue}
					multiple={multiple}
					className={classNames({
						'p-invalid': isFormFieldValid(field),
					})}
					classNameForLabel={classNames({
						'p-error': isFormFieldValid(field),
					})}
					onBlur={formik.handleBlur}
					hideClear={hideClear}
					disabled={disabled}
					overrideSelectedValue={overrideSelectedValue}
				/>
			);
		} else if (type === 'autocomplete-ccname') {
			console.log(
				'class name',
				isFormFieldValid(field),
				':: field name ::',
				field
			);

			return (
				<AutoCompleteCostCenterName
					callback={callback}
					defaultValue={defaultValue}
					className={classNames({
						'p-invalid': isFormFieldValid(field),
					})}
					classNameForLabel={classNames({
						'p-error': isFormFieldValid(field),
					})}
					onBlur={formik.handleBlur}
					field={field}
					disabled={disabled}
					overrideSelectedValue={overrideSelectedValue}
				/>
			);
		} else {
			return (
				<span className="p-float-label custom-placeholder">
					<InputText
						id={field}
						value={formik.values[field]}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						placeholder={placeholder}
						className={classNames({
							'p-invalid': isFormFieldValid(field),
						})}
						disabled={disabled}
					/>
					<label
						htmlFor={field}
						className={classNames({
							'p-error': isFormFieldValid(field),
						})}
					>
						{label}
					</label>
				</span>
			);
		}
	};
	return (
		<div className={`field col-${colSpan} mt-3`}>
			{renderInputByType(type)}
			{getFormErrorMessage(field)}
		</div>
	);
};

export default InputField;

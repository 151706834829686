import { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import {
	dataProcessingModalState,
	getCommonModal,
	setCommonModal,
	setDataProcessingModal,
} from '../../store/commonSlice';
import FileUploader from '../../components/file-uploader';
import { Dropdown } from 'primereact/dropdown';
import CustomAutoComplete from '../../components/autocomplete/autocomplete';
import { Months } from '../../utils/json/months';
import { useUploadGemsUserListMutation } from '../../store/chargingReportApi';
// import { Form, useFormik } from 'formik';
import CustomToast from '../../components/toaster/toaster';
import { toast } from 'react-toastify';
import { Skeleton } from 'primereact/skeleton';
import { useGetOwnActiveCostCentersQuery, useGetOwnActivePicasCostCentersQuery } from '../../store/apiSlice';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
	useDeputyListQuery,
	useGetAllUsersQuery,
	useGetCurrentUserQuery,
	useUpdateDeputyMutation,
	useAssignDeputyMutation,
	useDeleteDeputyMutation
} from '../../store/UserApi';
import DisplayIcon from '../../components/icon';
import { IDeputyList } from '../../types/user.interface';
import { IProp } from '../../components/cost-center-card';

import './style.scss';
import useDownloadFile from '../../hooks/useDownloadFile';
import { Calendar } from 'primereact/calendar';
import { Divider } from 'primereact/divider';
import * as Yup from 'yup';
import InputField from '../../components/input-field/input-field';
import { AutoComplete } from 'primereact/autocomplete';

const AssignDeputy = () => {
	const dispatch = useDispatch();
	const openModal = useSelector(getCommonModal);
	// const [editRow, setEditRow] = useState(-1);
	const [editDeputy, setEditDeputy] = useState<any>(undefined);
	// const [deleteDeputy, setDeleteDeputy] = useState<any>(undefined);

	const { data: currentUser, isLoading: isLoadingUser } =
		useGetCurrentUserQuery(0);

	const [assignDeputy, { isLoading: isAssigning }] = useAssignDeputyMutation();
	const [updateDeputy, { isLoading: isUpdating }] = useUpdateDeputyMutation();
	const [deleteDeputy, { isLoading: isDeputyDeleting }] = useDeleteDeputyMutation();

	
	const [selectedValue, setSelectedValue] = useState<any>(null);
	const [filteredValues, setFilteredValues] = useState<any>(null);
	const searchValues = (event: any) => {
		setTimeout(() => {
			let _filteredValues;
			let inputList = allUsers.map((user) => ({
				id: user.id,
				name: `${user.firstName} ${user.lastName}`,
				firstName: user.firstName,
				email: user.email,
			}))
			if (!event.query.trim().length) {
				_filteredValues = [...inputList];
			} else {
				_filteredValues = inputList.filter((listValue) =>
					{
						return listValue.name.toLowerCase().includes(event.query.toLowerCase());
					}
				);
			}
			setFilteredValues(_filteredValues);
		}, 100);
	};
		
	const [ccSelectedValue, setCcSelectedValue] = useState<any>(null);
	const [ccFilteredValues, setCcFilteredValues] = useState<any>(null);
	const ccSearchValues = (event: any) => {
		setTimeout(() => {
			let _filteredValues;
			let inputList = ownCostCenters
				.map((item) => ({
					costCenterId: item.costCenterId,
					costCenter: item.costCenter,
				}))
			if (!event.query.trim().length) {
				_filteredValues = [...inputList];
			} else {
				_filteredValues = inputList.filter((listValue) =>
					{
						return listValue.costCenter.toLowerCase().includes(event.query.toLowerCase());
					}
				);
			}
			setCcFilteredValues(_filteredValues);
		}, 100);
	};

	const { data: ownCostCenters = [], isLoading: isLoadingOwnCostCenters } =
	useGetOwnActivePicasCostCentersQuery();

	const {
		data: deputyList = [],
		isLoading: isLoadingDeputyList,
		isFetching: isFetchingDeputyList,
	} = useDeputyListQuery();
	const { downloadFile } = useDownloadFile();

	const [payload, setPayload] = useState<any>();
	const [costCenterData, setCostCenterData] = useState<any>(undefined);
	const [deputyData, setDeputyData] = useState<any>(undefined);
	const {
		data: allUsers = [],
		isLoading,
		isSuccess,
		isError,
		error,
	} = useGetAllUsersQuery();

	const footer = () => (
		<div>
			<Button
				label="Cancel"
				icon="pi pi-times"
				onClick={() => dispatch(setCommonModal({ assignDeputy: false }))}
			/>
			<Button label="Upload" icon="pi pi-check" />
		</div>
	);

	// const addDeputySchema = Yup.object().shape({
	// 	costcenter: Yup.string().required('Required !'),
	// 	deputy: Yup.string().required('Required !'),
	// });

	// const formik = useFormik({
	// 	initialValues: {
	// 		costcenter: '',
	// 		deputy: '',
	// 		valid: '',
	// 	},
	// 	validationSchema: addDeputySchema,
	// 	onSubmit: (data) => {
	// 		// setFormData(data);
	// 		formik.resetForm();
	// 	},
	// 	validateOnBlur: true,
	// });

	const actions = (rowData: IDeputyList) => {
		return (
			<div className="table-menu">
				{editDeputy !== undefined && rowData.ids === editDeputy.ids && editDeputy.deputyId === rowData.deputyId ? (
					<>
						<Button
							className="p-button-rounded p-button-text p-button-icon-only"
							tooltipOptions={{ position: 'left' }}
							tooltip="Cancel"
							onClick={() => {
								setEditDeputy(undefined);
							}}
						>
							<DisplayIcon icon="tablerCancel" className="table-action" />
						</Button>
						<Button
							className="p-button-rounded p-button-text p-button-icon-only"
							tooltipOptions={{ position: 'left' }}
							tooltip="Save Changes"
							onClick={() => {
								console.log('Payload', payload);
								setEditDeputy(undefined);
								setPayload(undefined);
							}}
						>
							<DisplayIcon
								icon="tablerSave"
								onClick={async () => {
									if (payload.DeputyId !== currentUser?.id) {
										payload.expdate = new Date(editDeputy.expdate)
										payload.old_ccId = editDeputy.ids
										payload.Old_DeputyId = editDeputy.deputyId
										await updateDeputy(payload)
											.unwrap()
											.then((payload: any) =>
												toast.success('Updated Deputy Successfully')
											)
											.catch((error: any) =>
												toast.error('Error Updating Deputy')
											);
									} else {
										toast.error('Sorry you cannot set yourself as Deputy');
									}
								}}
								className="table-action"
							/>
						</Button>
					</>
				) : (
					<>
						<Button
							className="p-button-rounded p-button-text p-button-icon-only"
							tooltipOptions={{ position: 'left' }}
							tooltip="Edit Deputy"
							onClick={() => {
								console.log(rowData);
								setEditDeputy(rowData);
							}}
						>
							<DisplayIcon icon="tablerPencil" className="table-action" />
						</Button>
						<Button
							className="p-button-rounded p-button-text p-button-icon-only"
							tooltipOptions={{ position: 'left' }}
							tooltip="Delete Deputy"
							onClick={async () => {
								console.log(rowData);
								// setEditDeputy(rowData);
								await deleteDeputy(rowData.userCostCenter_PK)
									.unwrap()
									.then((payload: any) =>
										toast.success('Deputy Deleted Successfully')
									)
									.catch((error: any) =>
										toast.error('Error Deleting Deputy')
									);
							}}
						>
							<DisplayIcon icon="tablerTrash" className="table-action" />
						</Button>
					</>
				)}
			</div>
		);
	};

	const itemTemplate = (item: any) => {
		return (
			<div className="country-item">
				<div>{item.name}</div>
			</div>
		);
	};
	const costCenterTemplate = (item: any) => {
		return (
			<div className="country-item">
				<div>{item.costCenter}</div>
			</div>
		);
	};

	const editOrDisplayCostCenter = (rowData: IDeputyList) => {
		const findDeputy = deputyList.find(
			(item) => item.ids === rowData.ids
		);
		const findUser =
			findDeputy !== undefined
				? allUsers.find((user) => user.id === findDeputy.deputyId)
				: findDeputy;
		if (editDeputy !== undefined && editDeputy.ids === rowData.ids && editDeputy.deputyId === rowData.deputyId) {
			return (
				<CustomAutoComplete
					customId={`dt-${rowData.ids}`}
					placeholder="Deputy"
					itemTemplate={itemTemplate}
					inputList={allUsers.map((user) => ({
						id: user.id,
						name: `${user.firstName} ${user.lastName}`,
						firstName: user.firstName,
						email: user.email,
					}))}
					field="id"
					target="name"
					callback={(e) => {
						if (e !== undefined) {
							setPayload({
								DeputyId: e.id,
								DeputyFName: e.firstName,
								DeputyMail: e.email,
								ccId: [rowData.ids],
								ccNames: [rowData.cc_ID],
							});
						} else {
							setPayload({
								DeputyId: 0,
								DeputyFName: '',
								DeputyMail: '',
								ccId: [rowData.ids],
								ccNames: [0],
							});
						}
					}}
					defaultValue={rowData.deputyFName}
				/>
			);
		} else {
			return rowData.deputyFName
		}
	};

	const [date, setDate] = useState<any>(undefined);
	const [ccValue, setCcValue] = useState<any>(undefined);
	const [deputyValue, setDeputyValue] = useState<any>(undefined);

	let today = new Date();
	let month = today.getMonth();
	let year = today.getFullYear();
	// let prevMonth = (month === 0) ? 11 : month - 1;
	// let prevYear = (prevMonth === 11) ? year - 1 : year;

	let minDate = new Date();
	minDate.setMonth(month);
	minDate.setFullYear(year);

	const formatExpDate = (expdate: string) => {
		const monthOptions = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
		];
		const today = new Date(expdate);
		const yyyy = today.getFullYear();
		let mm = today.getMonth();
		let dd = today.getDate();

		let day: any = dd
		let month = monthOptions[mm]

		if (dd < 10) day = '0' + dd;
		// if (mm < 10) month = '0' + mm;

		return day + '-' + month + '-' + yyyy;
	}

	const validityBody = (rowData: any) => {
		const findDeputy = deputyList.find(
			(item) => item.ids === rowData.ids
		);
		if (editDeputy !== undefined && editDeputy.ids === rowData.ids && editDeputy.deputyId === rowData.deputyId) {
			return (
				<Calendar id="deputyCalendar" value={(new Date(findDeputy !== undefined ? formatExpDate(findDeputy.expdate) : ""))} onChange={(item: any) => {
					const dateValue = item.value;
					setEditDeputy({ ...editDeputy, expdate: new Date(dateValue) });
				}}
					view="date" dateFormat="dd-MM-yy" minDate={minDate}
				/>
			)
		} else {
			return formatExpDate(rowData.expdate);
		}
	}

	const addNewDeputy = async () => {
		await assignDeputy({
			DeputyId: deputyData.id,
			DeputyFName: deputyData.firstName,
			DeputyMail: deputyData.email,
			ccId: costCenterData.costCenterId,
			ccNames: costCenterData.costCenter,
			expdate: date,
			Old_DeputyId: 0,
			old_ccId: 0,
		})
			.unwrap()
			.then((payload: any) => {
				toast.success('Deputy added successfully', {
					position: 'bottom-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				})
				setSelectedValue(null)
				setCcSelectedValue(null)
				// setFilteredValues(null)
					setDeputyValue(null)
					setCcValue(null)
				setTimeout(() => {
					setDate(undefined)
					setDeputyData(undefined)
					setCostCenterData(undefined)
				}, 100);
			})
			.catch((e: any) =>
				toast.error('Failed adding Deputy', {
					position: 'bottom-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				})
			);

		// formik.resetForm();
	};

	return (
		<Dialog
			header="Deputy Assignment"
			visible={openModal.assignDeputy}
			style={{ width: '60vw' }}
			onHide={() => dispatch(setCommonModal({ assignDeputy: false }))}
		>
			<div className="col-12">
				<div className="p-fluid grid formgrid mt-2">
					<div className="grid col-12 mt-2">
						{/* <CustomAutoComplete
							className='field col-2 mt-3'
							customId="select-month"
							placeholder="Select Month"
							inputList={Months}
							field="month"
							target="month"
							defaultValue={selectedMonth}
							callback={(item) => {
								if (item) {
									setSelectedMonth(item.month);
								} else {
									setSelectedMonth(null);
								}
							}}
						/> */}

						{/* <div className={`field col-3`}>
							<CustomAutoComplete
								className='field col-2'
								customId="cost-center-auto"
								placeholder="Cost Center"
								itemTemplate={costCenterTemplate}
								inputList={ownCostCenters
									.map((item) => ({
										costCenterId: item.costCenterId,
										costCenter: item.costCenter,
									}))
								}
								// hideClear={true}
								field="costCenter"
								target="costCenter"
								onClear={() => {
									setTimeout(() => {
										setCcValue(null)
										setCostCenterData(undefined)
									}, 100);
								}}
								setDefaultValue={setCcValue}
								defaultValue={ccValue}
								callback={(e) => {
									console.log(e, 'cc callback');
									setTimeout(() => {
										if (e !== undefined) {
											setCostCenterData({
												costCenterId: [e.costCenterId],
												costCenter: [e.costCenter],
											});
										} else {
											setCostCenterData(undefined);
										}
									}, 50);

								}}
							/>
						</div>
						<div className={`field col-3`}>
							<CustomAutoComplete
								className='field col-2'
								customId="assign-deputy-auto"
								placeholder="Deputy"
								itemTemplate={itemTemplate}
								inputList={allUsers.map((user) => ({
									id: user.id,
									name: `${user.firstName} ${user.lastName}`,
									firstName: user.firstName,
									email: user.email,
								}))}
								field="name"
								target="name"
								onClear={() => {
									setTimeout(() => {
										setDeputyValue(null);
										setDeputyData(undefined);
									}, 100);

								}}
								defaultValue={deputyValue}
								callback={(e) => {
									console.log(e, 'deputy callback');
									setTimeout(() => {
										
									if (e !== undefined) {
										setDeputyData({
											id: e.id,
											firstName: e.firstName,
											email: e.email,
										});
									} else {
										setDeputyData(undefined);
									}
									}, 50);
								}}
							/>
						</div> */}

						
						<div className={`field col-3`}>
							<span className="p-float-label">
								<AutoComplete 
									className={`auto-complete animate-block field col-2`}
									itemTemplate={costCenterTemplate}
									value={ccSelectedValue} 
									field="costCenter"
									forceSelection
									suggestions={ccFilteredValues} 
									completeMethod={ccSearchValues} 
									onChange={(e) => {
										// setTimeout(() => {

										// }, 50)
										if(e.value === null) {
											e.value = ''
										}
										setCcSelectedValue(e.value)
										console.log(e, 'e from autocomplete');
										if (e !== undefined) {
											setCostCenterData({
												costCenterId: [e.value.costCenterId],
												costCenter: [e.value.costCenter],
											});
										} else {
											setCostCenterData(undefined);
										}
										
									}} 
									id='cost-center-auto'
									inputId='costCenter'
									dropdown
								/>
								<label htmlFor="cost-center-auto">Cost Center</label>
							</span>
						</div>
						
						<div className={`field col-3`}>
							<span className="p-float-label">
								<AutoComplete 
									className={`auto-complete animate-block field col-2`}
									itemTemplate={itemTemplate}
									value={selectedValue} 
									field="name"
									forceSelection
									suggestions={filteredValues} 
									completeMethod={searchValues} 
									onChange={(e) => {
										// setTimeout(() => {

										// }, 50)
										if(e.value === null) {
											e.value = ''
										}
										setSelectedValue(e.value)
										console.log(e.value);
										if (e !== undefined) {
											setDeputyData({
												id: e.value.id,
												firstName: e.value.firstName,
												email: e.value.email,
											});
										} else {
											setDeputyData(undefined);
										}
										
									}} 
									id='assign-deputy-auto'
									inputId='name'
									dropdown
								/>
								<label htmlFor="assign-deputy-auto">Deputy</label>
							</span>
						</div>

						<div className={`field col-3`}>
							<span className="p-float-label">
								<Calendar id="valitidy" value={date} onChange={(e: any) => {
									setDate(new Date(e.value));
								}}
									view="date" dateFormat="dd-MM-yy" minDate={minDate}
								/>
								<label htmlFor="valitidy">Valid Until</label>
							</span>
						</div>
						<div className={`field col-3`}>
							<Button
								label="Add Deputy"
								onClick={addNewDeputy}
								icon="pi pi-save"
								className="p-button-sm"
								loading={isAssigning}
								disabled={!deputyData || !costCenterData || !date}
							/>
						</div>
					</div>
				</div>

				<Divider />

				<DataTable
					loading={isFetchingDeputyList || isUpdating || isDeputyDeleting || isAssigning}
					value={deputyList}
					dataKey="id"
					tableClassName="deputy-table"
					scrollable scrollHeight="400px"
				>
					<Column
						header="Cost Center"
						field="cc_ID"
						style={{ minWidth: '20%' }}
					></Column>
					<Column
						header="Deputy"
						field="deputyFName"
						body={editOrDisplayCostCenter}
						style={{ minWidth: '35%' }}
					></Column>
					<Column
						field='expdate'
						header="Valid Until"
						style={{ minWidth: '30%' }}
						body={validityBody}
					></Column>
					<Column
						header="Actions"
						body={actions}
						style={{ minWidth: '10%' }}
					></Column>
				</DataTable>
			</div>
		</Dialog>
	);
};

export default AssignDeputy;

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect } from "react";
import DisplayIcon from "../../components/icon";
import useDownloadFile from "../../hooks/useDownloadFile";
import { useGetallnewassetQuery } from "../../store/apiSlice";
import { formatCurrency } from "../../utils/utility-function";

const NewAssets = () => {
	const { downloadFile } = useDownloadFile();
	useEffect(() => {
		document.title = 'New Assets | XCharge';
	}, []);
    
	const { data, isFetching} = useGetallnewassetQuery();
	
	const header = () => {
		return (
			<div className="flex align-items-center justify-content-between">
				<div className="flex align-items-center">
					<h5>New Assets</h5>
				</div>
				<div className="actions flex">
					<Button
						className="p-button-rounded p-button-text p-button-plain p-button-icon-only"
						tooltip="Export New Assets"
						tooltipOptions={{ position: 'left' }}
						onClick={async () => {
							downloadFile(
								`picasReport/exportnewassets`,
								`New_Assets.xlsx`
							);
						}}
					>
						<DisplayIcon icon="excel" className="excel-icon__small" />
					</Button>
				</div>
			</div>
		);
	};

    return (
        <>
            <div className={`grid`}>
				<div className="cards">
					<DataTable
						value={data}
						responsiveLayout="scroll"
						className='new-assets'
						dataKey="id"
						rowHover
                        header={header}
                        loading={isFetching}
					>
						<Column field="id" header="ID" sortable></Column>
						<Column field="materialno" header="Materialnummer" sortable></Column>
						<Column field="asset" header="Beschreibung" sortable></Column>
						<Column field="price" header="EK-Preis" sortable body={(data) => formatCurrency(data.price)}></Column>
						<Column field="uploadMonth" header="Uploaded On" sortable 
							body={(data) => `${data.uploadMonth}-${data.uploadYear}`}>
						</Column>
						{/* <Column field="serviceName" header="Service Name" sortable></Column>
						<Column
							field="aditionalInfo"
							header="Additional Information"
							sortable
						></Column> */}
					</DataTable>
				</div>
			</div>
        </>
    )
}

export default NewAssets;
import { Outlet } from 'react-router-dom';

const SettingsPage: React.FC = function () {
	return (
		<>
			<Outlet />
		</>
	);
};

export default SettingsPage;

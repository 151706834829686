import { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import {
	dataProcessingModalState,
	setDataProcessingModal,
} from '../../store/commonSlice';
import FileUploader from '../../components/file-uploader';
import { Dropdown } from 'primereact/dropdown';
import CustomAutoComplete from '../../components/autocomplete/autocomplete';
import { Months } from '../../utils/json/months';
import { useClearCacheMutation, useUploadChargingReportMutation } from '../../store/chargingReportApi';
import { Form } from 'formik';
import { useUploadAssetListMutation, useUploadAssetNewListMutation, useUploadAssetPriceListMutation } from '../../store/picasApi';
import { toast } from 'react-toastify';
import { Skeleton } from 'primereact/skeleton';
import { useGetAssetListUploadQuery, useGetAssetNewListUploadQuery } from '../../store/apiSlice';
import useFindCurrentLocation from '../../hooks/useFindCurrentLocation';
import PAGES from '../../utils/pages.enum';

const UploadAssetList = () => {
	const dispatch = useDispatch();
	const openModal = useSelector(dataProcessingModalState);
	const [selectedFile, setSelectedFile] = useState<File | undefined>();
	const [selectedMonth, setSelectedMonth] = useState('');

	const [clearCache] = useClearCacheMutation();
	const [UploadAssetList, { isLoading: isUploading }] =
		useUploadAssetListMutation();
	const [UploadAssetNewList, { isLoading: isAssetUploading }] =
		useUploadAssetNewListMutation();
	const [UploadAssetPriceList, { isLoading: isPriceUploading }] =
		useUploadAssetPriceListMutation();
	const { data, isLoading, isSuccess, isFetching, isError, error } =
		useGetAssetListUploadQuery();
	const { data: newData } = useGetAssetNewListUploadQuery();
	const footer = () => (
		<div>
			<Button
				label="Cancel"
				icon="pi pi-times"
				onClick={() =>
					dispatch(setDataProcessingModal({ uploadChargingReport: false, uploadAssetPriceList: false }))
				}
			/>
			<Button
				label="Upload"
				icon="pi pi-save"
				disabled={selectedFile === undefined || selectedMonth.length === 0}
				loading={(openModal.uploadAssetPriceList ? isPriceUploading : isUploading)}
				onClick={() => uploadData()}
			/>
		</div>
	);

	const fileCallback = (file: File | undefined) => {
		setSelectedFile(file);
	};

	const uploadData = async () => {
		if (openModal.uploadAssetPriceList) {
			if (selectedFile) {
				const payload = new FormData();
				payload.append('importdata', selectedFile);
				await UploadAssetPriceList({
					file: payload,
					month: `${selectedMonth}-${new Date().getFullYear()}`
				})
					.unwrap()
					.then((payload) => {
						toast.success('Asset Price List Report Uploaded Successfully!');
					})
					.catch((e: any) => toast.error(e.data.message));
				dispatch(setDataProcessingModal({ uploadChargingReport: false, uploadAssetPriceList: false }));
				// console.log(`${selectedMonth}-${new Date().getFullYear()}`);

			}
		} else {
			const assetList = currentPage === PAGES.PICAS_LIST ? data : newData;
			const reportAlreadyExist = assetList
				? assetList.filter(
					(report: any) =>
						report.uploadedMonthYear ===
						`${selectedMonth}-${new Date().getFullYear()}`
				)
				: [];

			const exceluploadId =
				reportAlreadyExist.length > 0 ? reportAlreadyExist[0].id : 0;
			console.log(selectedFile);
			//dispatch(setDataProcessingModal({ uploadChargingReport: false }))
			if (selectedFile) {
				const payload = new FormData();
				payload.append('importdata', selectedFile);
				if (currentPage === PAGES.PICAS_LIST) {
					await UploadAssetList({
						file: payload,
						month: `${selectedMonth}-${new Date().getFullYear()}`,
						vId: exceluploadId,
					})
						.unwrap()
						.then((payload) =>
							toast.success('Picas List Report Uploaded Successfully!')
						)
						.catch((e: any) => toast.error(e.data.message));
				} else {
					await UploadAssetNewList({
						file: payload,
						month: `${selectedMonth}-${new Date().getFullYear()}`,
						vId: exceluploadId,
					})
						.unwrap()
						.then((payload) => {
							toast.success('Workplace List Report Uploaded Successfully!')
							clearCache('workplace').then(() => console.log('cache cleared successfully'));
						})
						.catch((e: any) => toast.error(e.data.message));
				}

				dispatch(setDataProcessingModal({ uploadChargingReport: false, uploadAssetPriceList: false }));
			}
		}
	}
	const currentPage = useFindCurrentLocation();

	return (
		<Dialog
			header={openModal.uploadAssetPriceList ? "Upload Asset Price List" : currentPage === PAGES.PICAS_LIST ? "Upload Picas List" : "Upload Workplace List"}
			visible={openModal.uploadChargingReport}
			style={{ width: '50vw' }}
			footer={footer}
			onHide={() =>
				dispatch(setDataProcessingModal({ uploadChargingReport: false, uploadAssetPriceList: false }))
			}
		>
			<div className="grid p-fluid">
				<div className="grid col-12">
					<FileUploader callback={fileCallback} disabled={(openModal.uploadAssetPriceList ? isPriceUploading : currentPage === PAGES.PICAS_LIST ? isUploading : isAssetUploading)} />

					{(openModal.uploadAssetPriceList ? isPriceUploading : currentPage === PAGES.PICAS_LIST ? isUploading : isAssetUploading) ? (
						<Skeleton height="2rem" className="w-full mt-3" />
					) : (
						<div className="flex field col-6 justify-content-end  col-offset-2 mt-3">
							<CustomAutoComplete
								customId="select-month"
								placeholder="Select Month"
								inputList={Months}
								field="month"
								target="month"
								callback={(item) => {
									if (item) {
										setSelectedMonth(item.month);
									} else {
										setSelectedMonth('');
									}
								}}
							/>
						</div>
					)}
				</div>
			</div>
		</Dialog>
	);
};

export default UploadAssetList;

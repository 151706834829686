import { IconScaleOutlineOff } from '@tabler/icons';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getToken } from '../store/commonSlice';

const useDownloadFile = () => {
	const token = useSelector(getToken);
	const downloadFile = useCallback(
		async (url: string, fileName: string, body?: any) => {

			toast.loading('Export will be generated, Please wait!');
			const response = await fetch(`${process.env.REACT_APP_API_URL}/${url}`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
				body: body ? JSON.stringify(body) : body,
			})



			if (response.status === 400) {
				const result = await response.json()
				toast.dismiss();
				toast.error(result.message)
			}


			else if (response.status === 200) {
				let link: any = document.createElement('a');
				link.href = window.URL.createObjectURL(await response.blob());
				//const appendVersion = versionId != undefined ? `_${versionId}` : '';
				link.download = fileName;
				link.click();
				toast.dismiss();
				toast.success('File Downloaded Successfully')
			} else {
				toast.dismiss();
				toast.error('Error Downloading Requested File');
			}
		},
		[]
	);

	return { downloadFile };
};

export default useDownloadFile;

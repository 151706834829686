import { Outlet } from 'react-router-dom';

const DataProcessing: React.FC = function () {
	return (
		<>
			<Outlet />
		</>
	);
};

export default DataProcessing;

import { toast } from 'react-toastify';

const CustomToast = ({
	isSuccess,
	successMessage,
	isError,
	errorMessage,
	successSummary,
	errorSummary,
}: {
	isSuccess?: boolean;
	successMessage?: string;
	isError?: boolean;
	errorSummary?: string;
	successSummary?: string;
	errorMessage?: any;
}) => {
	if (isError) {
		toast.error('🦄 Wow so easy!', {
			position: 'bottom-right',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}
	return null;
};

export default CustomToast;

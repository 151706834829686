import { useLocation } from 'react-router-dom';

const useGetOU = () => {
    const location = useLocation();
    let OU: number = 98;
    if (location.pathname.includes('itServices_103') || location.pathname.includes('workplace_103')) {
        OU = 103
    } else {
        OU = 98
    }
    return OU;
}

export default useGetOU;
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useMemo, useState } from "react";
import { formatCurrency, formatDateDDMMYYYY } from "../../utils/utility-function";
import { getSearchTerm, setDataProcessingModal } from '../../store/commonSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import DisplayIcon from "../../components/icon";
import { toast } from "react-toastify";
import { AutoComplete } from 'primereact/autocomplete';
import { useGetAllUsersQuery } from "../../store/UserApi";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import AddOrEditCrossCharge from "./add-edit-cross-charge";
import { useGetCRFormDataQuery, useDeleteCrossChargeMutation } from "../../store/chargingReportApi";
import { Dialog } from "primereact/dialog";

const CrossCharging = () => {

	const dispatch = useDispatch();

	const statusList = [
		{ status: "Active", value: "Active" },
		{ status: "InActive", value: "InActive" },
	]
	const [editData, setEditData] = useState<any>(undefined);
	const [showDetails, setShowDetails] = useState<Boolean>(false);
	const [deleteDialog, setDeleteDialog] = useState(false);
	const [deleteId, setDeleteId] = useState(undefined);

	const { data, isLoading } = useGetCRFormDataQuery();

	const searchTerm = useSelector(getSearchTerm);

	const [searchKeyword, setSearchKeyword] = useState('');
	const [editService, setEditService] = useState<any>(undefined);

	const [deleteCrosscharge, { isLoading: isDeleting }] = useDeleteCrossChargeMutation();

	useEffect(() => {
		document.title = 'Cross Charging | XCharge';
	}, []);

	useEffect(() => {
		const apiCallTimeoutId = setTimeout(() => {
			if (searchTerm !== '') {
				setSearchKeyword(searchTerm);
			} else {
				setSearchKeyword('');
			}
		}, 1000);

		return () => clearTimeout(apiCallTimeoutId);
	}, [searchTerm]);

	const computedData = useMemo(() => {
		let result = data;

		result =
			searchKeyword !== '' && data
				? data.filter((items: any) => {
					console.log(Object.values(items));
					return (
						Object.values(items).findIndex((chunk: any) =>
							chunk
								.toString()
								.toLowerCase()
								.includes(searchKeyword.toLowerCase())
						) !== -1
					);
				})
				: data;

		return result;
	}, [data, searchKeyword]);

	const header = () => {
		return (
			<div className="flex align-items-center justify-content-between">
				<div className="flex align-items-center">
					<h5>Cross Charging</h5>
				</div>
				<div className="actions flex">
					<Button
						icon="pi pi-filter"
						className="p-button-rounded p-button-text p-button-plain p-button-icon-only"
						tooltip="Add Cross Charging"
						tooltipOptions={{ position: 'left' }}
						onClick={() => {
							dispatch(setDataProcessingModal({ addCrossCharge: true }));
							setEditData(undefined);
							setShowDetails(false);
						}}
					>
						<DisplayIcon icon="add" className="action-icon" />
					</Button>
				</div>
			</div>
		);
	};

	const actions = (rowData: any) => {
		return (
			<div className="table-menu">
				<Button
					className="p-button-rounded p-button-text p-button-icon-only"
					tooltipOptions={{ position: 'left' }}
					onClick={() => {
						dispatch(setDataProcessingModal({ addCrossCharge: true }));
						setEditData(rowData);
						setShowDetails(true);
					}}
					tooltip="More Details"
				>
					<DisplayIcon icon="info" className="table-action" />
				</Button>
				{rowData.icaStatus !== "Booked" &&
					<>
						<Button
							className="p-button-rounded p-button-text p-button-icon-only"
							tooltipOptions={{ position: 'left' }}
							onClick={() => {
								dispatch(setDataProcessingModal({ addCrossCharge: true }));
								setEditData(rowData);
								setShowDetails(false);
							}}
							tooltip="Edit Cross Charge"
						>
							<DisplayIcon icon="edit" className="table-action" />
						</Button>
						<Button
							className="p-button-rounded p-button-text p-button-icon-only"
							tooltipOptions={{ position: 'left' }}
							onClick={() => {
								setDeleteId(rowData.id);
								setDeleteDialog(true);
							}}
							tooltip="Delete Cross Charge"
						>
							<DisplayIcon icon="tablerTrash" className="table-action" />
						</Button>
					</>
				}
			</div>
		);
	};

	const itemTemplate = (item: any) => {
		return (
			<div className="country-item">
				<div>{item.name}</div>
			</div>
		);
	};

	const { data: allUsers = [] } = useGetAllUsersQuery();

	const [ownerData, setOwnerData] = useState<any>(undefined);
	const [filteredValues, setFilteredValues] = useState<any>(null);

	const searchValues = (event: any) => {
		setTimeout(() => {
			let _filteredValues;
			let inputList = allUsers.map((user) => ({
				id: user.id,
				name: `${user.firstName} ${user.lastName}`,
				firstName: user.firstName,
				email: user.email,
			}))
			if (!event.query.trim().length) {
				_filteredValues = [...inputList];
			} else {
				_filteredValues = inputList.filter((listValue) => {
					return listValue.name.toLowerCase().includes(event.query.toLowerCase());
				}
				);
			}
			setFilteredValues(_filteredValues);
		}, 100);
	};


	const statusBody = (rowData: any) => {
		if (editService !== undefined && rowData.id === editService.id) {
			return (
				<Dropdown
					value={editService.status}
					options={statusList}
					onChange={(item: any) => {
						console.log(item)
						setEditService({ ...editService, status: item.value });
						console.log(editService)
					}}
					optionLabel="status"
					id="Status"
					name="Status"
				/>
			)
		} else {
			return rowData.status;
		}
	}

	const descriptionBody = (rowData: any) => {
		if (editService !== undefined && rowData.id === editService.id) {
			return (
				<InputText
					name={`description-${rowData.id}`}
					value={editService.description}
					onChange={(e) => {
						console.log(e.target.value);
						console.log(editService);
						setEditService({
							...editService,
							description: e.target.value
						});
					}}
				/>
			)
		} else {
			return rowData.description;
		}
	}

	const editOrDisplayOwner = (rowData: any) => {

		if (editService !== undefined && editService.id === rowData.id) {
			return (
				<AutoComplete
					className={`auto-complete animate-block`}
					itemTemplate={itemTemplate}
					value={editService.serviceOwner}
					field="name"
					forceSelection
					suggestions={filteredValues}
					completeMethod={searchValues}
					onChange={(e) => {
						if (e.value === null) {
							e.value = ''
						}
						console.log(e.value);
						if (e !== undefined && e.value) {
							setEditService({
								...editService,
								serviceOwner: e.value.name,
							})
						}
						// else {
						// 	setOwnerData(undefined);
						// }

					}}
					id='assign-deputy-auto'
					inputId='name'
					dropdown
				/>
			);
		} else {
			return rowData.serviceOwner
		}
	};

	const ApprovalFooter = () => (
		<div>
			<Button
				label="Cancel"
				icon="pi pi-times"
				onClick={() => {
					setDeleteDialog(false);
					setDeleteId(undefined);
				}}
			/>
			<Button
				label="Confirm"
				icon="pi pi-times"
				onClick={async () => {
					deleteId &&
					await deleteCrosscharge(deleteId)
						.unwrap()
						.then((payoad: any) => {
							toast.success('Cross Charge Deleted Successfully');
							setDeleteDialog(false);
							setDeleteId(undefined);
						})
						.catch((e: any) => {
							toast.error(e.data.message);
						});
				}}
			/>
		</div>
	)

	return (
		<>
			<div className={`grid`}>
				<div className="cards">

					<DataTable
						tableClassName='bug-report-track'
						value={computedData}
						responsiveLayout="scroll"
						header={header}
						loading={isLoading}
					>
						<Column field="serviceId" header="Service ID" sortable></Column>
						<Column field="serviceName" header="Service Name" sortable></Column>
						<Column field='billingMonth' header="Billing On" body={(rowData) => `${rowData.billingMonth}-${rowData.billingYear}`} sortable></Column>
						<Column field="billingAmount" header="Costs" body={(rowData) => formatCurrency(rowData.billingAmount)} sortable></Column>
						<Column field="sourceCostcenter" header="Source cost center" sortable></Column>
						<Column field="targetCostcenter" header="Receiving cost center" sortable></Column>
						<Column field="said" header="SA ID" sortable></Column>
						<Column field="coNumber" header="CO Number" sortable></Column>
						{/* <Column field="additionalinfo" header="Additional Information" sortable></Column> */}
						<Column field="icaStatus" header="Status" sortable></Column>
						<Column body={actions}>
						</Column>
					</DataTable>
				</div>
			</div>
			<AddOrEditCrossCharge editData={editData} showDetails={showDetails} />

			<Dialog
				header="Delete Cross Charge"
				visible={deleteDialog}
				footer={ApprovalFooter}
				onHide={() => {
					setDeleteDialog(false);
					setDeleteId(undefined);
				}}
			>
				<div className='flex flex-wrap justify-content-center'>
					<DisplayIcon className='flex justify-items-center warning-icon' icon="warning" />
				</div>
				<p className="mt-2 px-5 bold-text">
					Do you want to delete this Cross charge?
				</p>
			</Dialog>
		</>
	)
}

export default CrossCharging;

import { Icon } from '@iconify/react';
import { Skeleton } from 'primereact/skeleton';
import { useEffect, useRef, useState } from 'react';
import DisplayIcon from '../icon';
import './style.scss';
import { useSelector } from 'react-redux';
import { getMailers } from '../../store/commonSlice';

const ImageUploader = ({
	imageCallback,
	disabled,
}: {
	imageCallback: (file: File[] | undefined) => any;
	disabled?: boolean;
}) => {
	const imagePicker = useRef<HTMLInputElement>(null);
	const [selectedImage, setSelectedImage] = useState<File[]>([]);
	const mailers = useSelector(getMailers);

	function onChange(e:any) {
        const images = e.target.files
        
        const fileListAsArray: File[] = Array.from(images)
        const imageOnlyFilter = fileListAsArray.filter((file) => {
            return file.type.includes('image/');
        })
        const stateImages = Array.from(selectedImage ? selectedImage : [])
        const duplicateFilter = imageOnlyFilter.filter(o1 => !stateImages.some(o2 => o1.name === o2.name))
        
		// if (imagePicker.current !== null && imagePicker.current.files !== null) {
			const updateImage = selectedImage.concat(duplicateFilter);
            console.log(stateImages, 'state')
            console.log(duplicateFilter, 'duplicate')
            console.log(updateImage, 'update')
            // const key = 'name';
            // const arrayUniqueByKey = [...new Map(updateImage?.map(item => [item[key], item])).values()];
            setSelectedImage(updateImage)
		// }
	}

	useEffect(() => {
		if (imageCallback) imageCallback(selectedImage);
	}, [selectedImage]);


	useEffect(() => {
		const attachments = mailers.minimizeData.rawdata;
		setSelectedImage(attachments ? attachments : [])
		console.log(attachments, "selected image -------------------")
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mailers.minimizeData.rawdata]);

	return (
		<>
			{disabled === true ? (
				<div className="flex flex-column field col-12 mt-3">
					<Skeleton height="15rem" className="w-full" />
				</div>
			) : (
				<>
					<div className="flex flex-column field col-12 mt-3">
						<div
							className="flex flex-column align-items-center w-full uploader"
						>
							<input
								type="file"
								name=""
                                multiple
                                accept='image/*'
								id="imageUpload"
								ref={imagePicker}
								onChange={(e) => onChange(e)}
							/>
							<label
								htmlFor="imageUpload"
								id="open-uploader"
								className="flex flex-column align-items-center"
							>
								<DisplayIcon icon="imageAdd" className="upload-icon" />
								<DisplayIcon
									icon="documentShadow"
									className="upload-shadow upload-shadow-hidden"
								/>
								<DisplayIcon
									icon="documentOk"
									className="upload-ok upload-ok-hidden"
								/>
							</label>
						</div>
					</div>

					{selectedImage?.length !== 0 && (
                        selectedImage?.map((data: any, index) => (
                            <div className="uploaded flex justify-content-between field col-3" key={index}>
                                <div className="flex justify-content-between">
                                    <div className="flex align-items-center justify-content-arround">
                                        <Icon icon="fluent:image-20-regular"></Icon>
                                        <span
                                            className="ml-2 mr-3 filename"
                                            style={{
                                                maxWidth: '175px',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            {data.name}
                                        </span>
                                    </div>
                                    <DisplayIcon
                                        icon="dismiss"
                                        onClick={() => {
                                            document
                                                .querySelector('.upload-icon')
                                                ?.classList.remove('upload-icon-hidden');
                                            document
                                                .querySelector('.upload-ok')
                                                ?.classList.add('upload-ok-hidden');
                                            document
                                                .querySelector('.uploader')
                                                ?.classList.remove('green-hue');
                                            const deletedItem = selectedImage.filter((file, fileIndex) => {
                                                return fileIndex !== index;
                                            })
                                            setSelectedImage(deletedItem);
                                        }}
                                        className="dismiss-file red-icon"
                                    />
                                </div>
                            </div>
                        ))
					)}
				</>
			)}
		</>
	);
};

export default ImageUploader;

import axios from 'axios';
import type { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';

function handleError(error: AxiosError) {
	const response = error.response;
	console.log('Axios Error ::', response?.status ?? -1);
}

interface IAxiosInstance {
	get(url: string, config?: AxiosRequestConfig): Promise<Record<string, any>>;
	delete(
		url: string,
		config?: AxiosRequestConfig
	): Promise<Record<string, any>>;
	post(
		url: string,
		data?: any,
		config?: AxiosRequestConfig
	): Promise<Record<string, any>>;
	put(
		url: string,
		data?: any,
		config?: AxiosRequestConfig
	): Promise<Record<string, any>>;
}

const httpInstance: IAxiosInstance & AxiosInstance = axios.create({
	timeout: 60000,
	baseURL: '',
});

httpInstance.defaults.headers.common.isLoading = 'true';
httpInstance.defaults.headers.common.errorAlert = 'true';
Object.setPrototypeOf(httpInstance, axios);

export default httpInstance;

import { Skeleton } from 'primereact/skeleton';
import { useDispatch } from 'react-redux';
import useGetOU from '../../hooks/useGetOU';
import { useGetAllCostCentersQuery } from '../../store/apiSlice';
import { setCostCenterFilter } from '../../store/commonSlice';
import CustomAutoComplete from '../autocomplete/autocomplete';

const AutoCompleteCostCenterName = ({
	defaultValue,
	callback,
	onBlur,
	className,
	classNameForLabel,
	field,
	disabled,
	overrideSelectedValue,
}: {
	field?: any;
	onBlur?: any;
	defaultValue?: any;
	callback?: (param?: any) => any;
	className?: string;
	classNameForLabel?: string;
	disabled?: boolean;
	overrideSelectedValue?: any;
}) => {
	const dispatch = useDispatch();

	const OU = useGetOU();

	const {
		data: allList = [],
		isLoading: isListLoading,
		isSuccess: isListSuccess,
		isError: isListError,
		error: listError,
	} = useGetAllCostCentersQuery({OU});

	const itemTemplate = (item: any) => {
		return (
			<div className="country-item">
				<div>
					{item.target_costcenter} | {item.target_responsible}
				</div>
			</div>
		);
	};

	const filterCostCenter = (
		{
			name,
			target_costcenter,
		}: {
			name: string;
			target_costcenter: string;
		} = {
			name: '',
			target_costcenter: '',
		}
	) => {
		dispatch(
			setCostCenterFilter({ costCenterFilter: { name, target_costcenter } })
		);
	};

	return isListLoading ? (
		<Skeleton height="2rem" className="mb-2"></Skeleton>
	) : (
		<CustomAutoComplete
			onBlur={onBlur}
			className={className}
			classNameForLabel={classNameForLabel}
			target="target_costcenter"
			customId={`target_costcenter`}
			label="Cost Center"
			itemTemplate={itemTemplate}
			inputList={allList
				.filter((item) => item.isfunctional && item.isvisible)
				.map((item) => ({
					target_costcenter: item.target_costcenter,
					user_responsible: item.user_responsible,
					target_responsible: item.target_responsible,
					id: item.id,
				}))}
			field={field}
			callback={callback}
			defaultValue={
				defaultValue
					? {
							target_costcenter: defaultValue.target_costcenter,
							user_responsible: allList.find(
								(item) => item.id === defaultValue.id
							)?.user_responsible,
							target_responsible: allList.find(
								(item) => item.id === defaultValue.id
							)?.target_responsible,
							id: defaultValue.id,
					  }
					: null
			}
			overrideSelectedValue={overrideSelectedValue}
		/>
	);
};

export default AutoCompleteCostCenterName;

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { getMailers, setMailers } from '../../store/commonSlice';
import { useState } from 'react';
import Mailer from './mailer';
import {
	useMailBugReportMutation,
	useSaveBugReportMutation,
} from '../../store/mailApi';
import { IMailer } from '../../types/common.interface';
import { toast } from 'react-toastify';
import DisplayIcon from '../icon';

const MailBugReport = ({
	defaultTo,
	defaultCC,
	showAttachment,
	subjectPlaceholder,
	EditorPlaceHolder
}: {
	defaultTo: string[];
	defaultCC: string[];
	showAttachment: boolean;
	subjectPlaceholder: string;
	EditorPlaceHolder: string;
}) => {
	const dispatch = useDispatch();
	const mailers = useSelector(getMailers);
	const [mailerData, setMailerData] = useState<IMailer>();
	const [mailBug, { isLoading: isMailingBug }] = useMailBugReportMutation();
	const [saveBug, { isLoading: isSavingBug }] = useSaveBugReportMutation();

	const footer = () => (
		<div>
			<Button
				label="Cancel"
				icon="pi pi-times"
				onClick={() => dispatch(setMailers({ bugReportMailer: false, minimizeData: {}, minimizeBugReport: false }))}
			/>
			<Button
				label="Send"
				icon="pi pi-check"
				loading={isMailingBug}
				disabled={
					mailerData?.subject === '' || mailerData?.message === null
				}
				onClick={async () => {
					if (mailerData) {
						console.log(mailerData);
						const savePayload = {
							"id": 0,
							"emailIdTo": mailerData.emailIds,
							"emailIdCc": mailerData.ccIds,
							"emailIdBcc": mailerData.bccIds,
							"subject": mailerData.subject,
							"comment": mailerData.message,
							"status": ""
						}
						
						const rawFile: any = []
						const imageFiles = Array.from(mailerData?.rawdata!);
						const imageToBase64 = imageFiles?.map(async (file: any) => {
							const reader = new FileReader();
							// let blob = await fetch(file).then(r => r.blob()); //blob:url
							reader.readAsDataURL(file);
							reader.onloadend = async () => {
								const base64data = reader.result;
								rawFile.push(base64data)
								// setBase64Data((state: any) => [...state, base64data])
							}

						})
						setTimeout(() => {
							const properRawData = rawFile.map((val: any) => {
								return val = val.split(",").pop();
							})
							if (mailerData?.rawdata) {
								mailerData.rawdata = [];
								mailerData.rawdata.push(...properRawData)
							}

							mailBug({ ...mailerData })
								.unwrap()
								.then((payload: any) => {
									toast.success('Bug reported successfully.')
									dispatch(setMailers({ bugReportMailer: false }));
									saveBug(savePayload)
										.unwrap()
										.then((res: any) => {  
											console.log("save bug successful");
										})
										.catch((error: any) =>
											toast.error('Error saving Bug')
										);
								})
								.catch((e: any) => {
									toast.error('Error Sending Mail');
								});
						}, 3000)
					} else {
						dispatch(setMailers({ bugReportMailer: false, minimizeData: {}, minimizeBugReport: false }));
					}
				}}
			/>
		</div>
	);
	const mailerCallback = (data: any) => {
		setMailerData(data);
	};


	return (
		<Dialog
			header="Report Bug"
			visible={mailers.bugReportMailer}
			style={{ width: '75vw' }}
			footer={footer}
			onHide={() => dispatch(setMailers({ bugReportMailer: false, minimizeData: {}, minimizeBugReport: false }))}
		>
			<Mailer
				defaultTo={mailers.minimizeData.emailIds ? mailers.minimizeData.emailIds : defaultTo}
				defaultCC={mailers.minimizeData.ccIds ? mailers.minimizeData.ccIds : defaultCC}
				defaultBCC={mailers.minimizeData.bccIds}
				defaultSubject={mailers.minimizeData.subject}
				defaultMessage={mailers.minimizeData.message}
				showAttachment={showAttachment}
				subjectPlaceholder={subjectPlaceholder}
				EditorPlaceHolder={EditorPlaceHolder}
				callback={mailerCallback}
			/>

			<DisplayIcon icon="minimize" className="bug-report-minimize"
				onClick={() => {
					dispatch(setMailers({ minimizeBugReport: true, bugReportMailer: false, minimizeData: mailerData }));
					console.log(mailerData, "Mailer Data -----------------------")
				}}
			/>
		</Dialog>
	);
};

export default MailBugReport;

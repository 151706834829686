import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { useDispatch, useSelector } from 'react-redux';
import timeline from './../../assets/XCharge_Timeline.png'
import {
	getCommonModal,
	setCommonModal,
} from '../../store/commonSlice';

const Timeline = () => {

    const dispatch = useDispatch();
    const commonModal = useSelector(getCommonModal);
    
    return (
        <>
			<Dialog
				header="XCharge Timeline"
				visible={commonModal.timeline}
                className="flex justify-content-center"
				style={{ width: '85vw' }}
				onHide={() => dispatch(setCommonModal({ timeline: false }))}
			>
                <Image className='flex align-items-center justify-content-center' src={timeline} alt="XCharge Timeline" preview />
            </Dialog>
        </>
    )
}

export default Timeline;
// Import the RTK Query methods from the React-specific entry point
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { IProp } from '../components/cost-center-card/index';
import { IChargingReport } from '../views/charging-report-upload';
import { ICostCenterData } from '../views/cost-center';
import { IMappingData } from '../views/mapping';
import { IServiceData, IServiceRequest } from '../views/service/index';
import { IAssetList } from '../views/asset-list-upload/set-asset-price';
import { IAllUsers, IUsers } from '../views/users';
import { baseApi } from './baseApi';
import { ICorrectionItem } from '../components/correction-approval';
const actualsEndPoint = 'actuals';

const notificationApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		getUserNotifications: builder.query<ICorrectionItem[], number>({
			query: (userId) => `actuals/getrequestinfo?userId=${userId}`,
			providesTags: ['Notifications'],
		}),
	}),
});

export const { useGetUserNotificationsQuery } = notificationApi;

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { AutoComplete } from 'primereact/autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { commonState, getMailers, setMailers } from '../../store/commonSlice';
import { useGetAllUsersQuery } from '../../store/UserApi';
import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InputField from '../input-field/input-field';
import Mailer from './mailer';
import {
	useMailAllUsersMutation,
	useMailResponsibleMutation,
} from '../../store/mailApi';
import { IMailer } from '../../types/common.interface';
import { toast } from 'react-toastify';

const MailAllUsers = () => {
	const dispatch = useDispatch();
	const mailers = useSelector(getMailers);
	const [mailerData, setMailerData] = useState<IMailer>();
	const { data, isLoading, isSuccess, isError, error } = useGetAllUsersQuery();
	const [formData, setFormData] = useState<any>({});
	const [mailUsers, { isLoading: isMailing }] = useMailAllUsersMutation();

	const footer = () => (
		<div>
			<Button
				label="Cancel"
				icon="pi pi-times"
				onClick={() => dispatch(setMailers({ allUsers: false }))}
			/>
			<Button
				label="Send"
				icon="pi pi-check"
				loading={isMailing}
				disabled={
					mailerData?.subject.length === 0 || mailerData?.message.length === 0
				}
				onClick={async () => {
					if (mailerData) {
						console.log('Mail Data :: ', mailerData);
						mailUsers({
							subject: mailerData.subject,
							message: mailerData.message,
						})
							.unwrap()
							.then((payoad: any) => toast.success('Mail Sent Successfully'))
							.catch((e: any) => {
								toast.error('Error Sending Mail');
							});
					}
					dispatch(setMailers({ allUsers: false }));
				}}
			/>
		</div>
	);
	const mailerCallback = (data: any) => {
		setMailerData(data);
	};

	return (
		<Dialog
			header="Mail All Users"
			visible={mailers.allUsers}
			style={{ width: '75vw' }}
			footer={footer}
			onHide={() => dispatch(setMailers({ allUsers: false }))}
		>
			<Mailer
				hideTo={true}
				hideCC={true}
				hideBCC={true}
				callback={mailerCallback}
			/>
		</Dialog>
	);
};

export default MailAllUsers;

import { Icon } from '@iconify/react';

import React, { useState, useEffect } from 'react';
import { AutoComplete } from 'primereact/autocomplete';

import './style.scss';
import { classNames } from 'primereact/utils';
import { allCostCenters } from '../../store/actualSlice';
import { object } from 'yup';

export interface IAutoCompleteProps {
	onBlur?: any;
	customId?: string;
	placeholder?: string;
	inputList: any[];
	itemTemplate?: (item: any) => JSX.Element;
	className?: string;
	classNameForLabel?: string;
	field: string;
	target: string;
	callback?: (result: any) => void;
	defaultValue?: any;
	multiple?: any;
	label?: string;
	hideClear?: boolean;
	shellClass?: string;
	disabled?: boolean;
	overrideSelectedValue?: any;
	tooltip?: string;
	tooltipOptions?: any;
}

const CustomAutoComplete: React.FC<IAutoCompleteProps> = ({
	onBlur,
	customId,
	className,
	classNameForLabel,
	placeholder,
	inputList,
	label,
	itemTemplate,
	target,
	field,
	callback,
	defaultValue,
	multiple,
	hideClear,
	shellClass,
	disabled,
	overrideSelectedValue,
	tooltip,
	tooltipOptions,
}) => {
	const [defaultContent, setDefaultContent] = useState<any>();
	const [listValues, setListValues] = useState(inputList);
	const [selectedValue, setSelectedValue] = useState<any>(defaultValue);
	const [filteredValues, setFilteredValues] = useState<any>(null);
	const searchValues = (event: { query: string }) => {
		setDecision(true);
		setTimeout(() => {
			let _filteredValues;
			if (!event.query.trim().length) {
				_filteredValues = [...inputList];
			} else {
				_filteredValues = inputList.filter((listValue) =>
					Object.values(listValue)
						.map((entry: any) => entry.toString().toLocaleLowerCase())
						.find((item) => item.includes(event.query.toLocaleLowerCase()))
				);
			}
			setFilteredValues(_filteredValues);
		}, 100);
	};

	useEffect(() => {
		setDefaultContent(defaultValue);
	}, []);

	useEffect(() => {
		setSelectedValue(overrideSelectedValue);
	}, [overrideSelectedValue]);

	useEffect(() => {
		setListValues(inputList);
	}, [inputList]);
	useEffect(() => {
		if (
			callback &&
			selectedValue !== null &&
			typeof selectedValue === 'object'
		) {
			callback(selectedValue);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedValue]);

	useEffect(() => {
		if (defaultContent !== undefined) {
			setSelectedValue(defaultContent);
			setDefaultContent(undefined);
		}
	}, [defaultContent]);

	const [overDisplayed, setOverlayDisplayed] = useState(false);
	const [decision, setDecision] = useState(false);
	useEffect(() => {
		if (!overDisplayed && selectedValue == null) {
			setDecision(false);
			const myb: HTMLButtonElement | null = document.querySelector(
				`#${customId}--container button`
			);
			myb?.classList.remove('hideButton');
		}
	}, [customId, overDisplayed, selectedValue]);

	return (
		<div
			style={{
				position: 'relative',
			}}
			className={shellClass}
		>
			<span className="p-float-label">
				<AutoComplete
					tooltip={tooltip}
					tooltipOptions={tooltipOptions}
					disabled={disabled}
					className={`auto-complete animate-block ${className}`}
					onBlur={onBlur}
					id={`${customId}--container`}
					value={selectedValue}
					suggestions={filteredValues}
					completeMethod={searchValues}
					field={target}
					inputId={field}
					multiple={multiple}
					dropdown
					itemTemplate={
						itemTemplate ||
						((item: any) => (
							<div className="option-item">
								<h4 className="left">{item[target]}</h4>
							</div>
						))
					}
					onChange={(e) => setSelectedValue(e.value)}
					onFocus={(e) => {
						const myb: HTMLButtonElement | null = document.querySelector(
							`#${customId}--container button`
						);

						console.log(
							'looking for container with ID ::' +
								`#${customId}--container button`,
							myb
						);
						myb!.click();
						setOverlayDisplayed(true);
					}}
					onShow={() => {
						setOverlayDisplayed(true);
						const myb: HTMLButtonElement | null = document.querySelector(
							`#${customId}--container button`
						);
						myb?.classList.add('hideButton');
					}}
					onHide={() => {
						if (selectedValue === null) {
							const myb: HTMLButtonElement | null = document.querySelector(
								`#${customId}--container button`
							);
							myb?.classList.remove('hideButton');
							setDecision(false);
						} else {
							setDecision(true);
						}
						setOverlayDisplayed(false);
					}}
				/>
				<label htmlFor={field} className={classNameForLabel}>
					{label ? label : placeholder}
				</label>
			</span>
			{!hideClear && decision && (
				<Icon
					style={{
						position: 'absolute',
						height: '1.25rem',
						width: '1.25rem',
						top: '25%',
						right: '5%',
						strokeWidth: 1,
						cursor: 'pointer',
						color: 'red',
						background: 'transparent',
					}}
					className="icon-x"
					icon="ph:x"
					onClick={(e) => {
						console.log('Setting Value to null 😢');
						e.stopPropagation();
						if (callback) callback(undefined);
						setSelectedValue(null);
						setFilteredValues([...listValues]);
						if (!overDisplayed && selectedValue === null) {
							setDecision(false);
							const myb: HTMLButtonElement | null = document.querySelector(
								`#${customId}--container button`
							);
							myb?.classList.remove('hideButton');
						}
					}}
				></Icon>
			)}
		</div>
	);
};

export default CustomAutoComplete;

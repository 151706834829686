import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import useFindCurrentLocation from '../../hooks/useFindCurrentLocation';
import { useGetWildCardMappingQuery } from '../../store/chargingReportApi';
import {
	useConfirmMappingMutation,
	useUnMapServicesMutation,
} from '../../store/servicesApi';
import { ChargingReportHeader } from '../charging-report-upload';

const WildCardMapping = () => {
	useEffect(() => {
		document.title = 'Wildcard Mapping | XCharge';
	}, []);
	const { data, isLoading, isSuccess, isError, error } =
		useGetWildCardMappingQuery();
	const [selectedServices, setSelectedServices] = useState<any>();
	const [unMapServices, { isLoading: isUnMapUpdating }] =
		useUnMapServicesMutation();
	const [confirmMapping, { isLoading: isConfirmMappingUpdating }] =
		useConfirmMappingMutation();
	const currentPage = useFindCurrentLocation();
	const performMapping = async () => {
		await confirmMapping(selectedServices.map((_service: any) => _service.id))
			.unwrap()
			.then((payload: any) => toast.success('Mapped Services Successfully'))
			.catch((e: any) => toast.error('Error Mapping Services'));
	};
	const performUnMapping = async () => {
		await confirmMapping(selectedServices.map((_service: any) => _service.id))
			.unwrap()
			.then((payload: any) => toast.success('Unmapped Services Successfully'))
			.catch((e: any) => toast.error('Error Unmapping Services'));
	};

	return (
		<>
			<div className={`grid`}>
				<div className="cards">
					<DataTable
						className="wildcard-mapping"
						value={data}
						responsiveLayout="scroll"
						onRowClick={() => {}}
						header={
							<ChargingReportHeader
								title="Wild Card Mapping"
								currentPage={currentPage}
								mapCallback={performMapping}
								unmapCallback={performUnMapping}
								options={{
									disableButton:
										selectedServices === undefined ||
										selectedServices.length === 0,
								}}
							/>
						}
						dataKey="id_pk"
						rowHover
						selection={selectedServices}
						onSelectionChange={(e) => setSelectedServices(e.value)}
					>
						<Column
							selectionMode="multiple"
							headerStyle={{ width: '3em' }}
						></Column>
						<Column
							header="#"
							alignHeader="left"
							body={(data, props) => props.rowIndex + 1}
						></Column>
						<Column field="uploadedMonthYear" header="SAID" sortable></Column>
						<Column field="uploadedOn" header="ID" sortable></Column>
						<Column
							field="uploadedBy.firstName"
							header="Service Name"
							sortable
						></Column>
						<Column
							field="status"
							header="Additional Information"
							sortable
						></Column>
						<Column field="status" header="Cost Center" sortable></Column>
						<Column
							field="status"
							header="ITM Target Responsible"
							sortable
						></Column>
					</DataTable>
				</div>
			</div>
		</>
	);
};

export default WildCardMapping;

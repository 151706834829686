import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import CostCenterCard from '../../components/cost-center-card';
import ExtraActions from '../../components/extra-actions';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../../views/actuals/styles.scss';
import { useGetAllCostCentersQuery } from '../../store/apiSlice';

import { useGetAllUsersQuery } from '../../store/UserApi';
import { Icon } from '@iconify/react';
import { Dialog } from 'primereact/dialog';

import { useDispatch, useSelector } from 'react-redux';
import EditUserDialog from './edit-user-dialog';
import {
	getSearchTerm,
	setSettingsModal,
	settingsModalState,
} from '../../store/commonSlice';
import DisplayIcon from '../../components/icon';
import { formatDateDDMMYYYY } from '../../utils/utility-function';
import ReactTooltip from 'react-tooltip';
import { Button } from 'primereact/button';

export interface IAllUsers {
	active: boolean;
	country: {
		id: number;
		name: string;
	};
	department: {
		id: number;
		name: string;
	};
	domain: string;
	email: string;
	firstName: string;
	id: number;
	identifer: number;
	isNotifyForNewChargingReportUpload: boolean;
	isNotifyForNewCloudChargingReportUpload: boolean;
	lastName: string;
	lastUpdatedByComment: string;
	costCenters: {
		name: string;
		costcenter_category: number;
		valid_from: string;
		valid_to: string;
		country: {
			id: number;
			name: string;
		};
		department: {
			id: number;
			name: string;
		};
		id: number;
		isReadOnly: boolean;
		isvisible: boolean;
		region: {
			id: number;
			name: string;
		};
		lastUpdatedBy: number;
		isnew: boolean;

		isupdated: boolean;
		isnameupdate: boolean;
		old_validfrom: string;
		old_validto: string;
		isvalidityupdate: boolean;
		isfunctional: boolean;
		isnewresponsible: boolean;
	}[];
	ntid: string;
	phone: string;
	photo: string;
	region: {
		id: number;
		name: string;
	};
	role: {
		id: number;
		isSuperAdmin: boolean;
		isAdmin: boolean;
		isCostCenterOwner: boolean;
		isCostController: boolean;
		isUserRole: boolean;
		name: string;
	};
	uid: string;
	updatedBy: {
		email: string;
		firstName: string;
		id: number;
		identifer: number;
		lastName: string;
		isApproval: boolean;
	};
	updatedOn: string;
	countryNew: string;
	departmentNew: string;
	departmentDescription: string;
	plant: string;
	isApproval: boolean;
	isDeputy: boolean;
}

export interface IUsers {
	active: boolean;
	country: {
		id: number;
		name: string;
	};
	department: {
		id: number;
		name: string;
	};
	domain: string;
	email: string;
	firstName: string;
	id: number;
	identifer: number;
	isNotifyForNewChargingReportUpload: boolean;
	isDeputy: boolean;
	lastName: string;
	lastUpdatedByComment: string;
	ntid: string;
	phone: string;
	photo: string;
	region: {
		id: number;
		name: string;
	};
	role: {
		id: number;
		isSuperAdmin: boolean;
		isAdmin: boolean;
		isCostCenterOwner: boolean;
		isUserRole: boolean;
		name: string;
	};
	uid: string;
	updatedBy: {
		email: string;
		firstName: string;
		id: number;
		identifer: number;
		lastName: string;
		isApproval: boolean;
	};
	updatedOn: string;
	countryNew: string;
	departmentNew: string;
	departmentDescription: string;
	plant: string;
	isApproval: boolean;
}

interface filep {
	name: string;
	email: string;
	password: string;
	date: null;
	country: null;
	accept: boolean;
}

const UsersPage: React.FC = function () {
	const [name, setName] = useState('');
	const dispatch = useDispatch();
	const { data, isLoading, isSuccess, isError, error } = useGetAllUsersQuery();

	const [selectedRow, setSelectedRow] = useState<any>();
	const [displayBasic, setDisplayBasic] = useState(false);

	const editModal = useSelector(settingsModalState);

	function onRowclick(data: any) {
		console.log(data);
	}
	const actions = (rowData: any) => {
		return (
			<div className="table-menu">
				<Button
					className="p-button-rounded p-button-text p-button-icon-only"
					tooltipOptions={{ position: 'left' }}
					tooltip="Edit Users"
					onClick={() => {
						console.log(rowData);
						setSelectedRow(rowData);
						dispatch(setSettingsModal({ editUser: true }));
					}}
				>
					<DisplayIcon icon="tablerPencil" className="table-action" />
				</Button>
			</div>
		);
	};
	const UsersPageHeader = () => {
		return (
			<div className="flex flex-column justify-content-between align-items-center">
				<div className="flex justify-content-between w-full align-items-center">
					<div className="flex align-items-baseline w-full">
						<h5>Users</h5>
					</div>

					<div className="actions flex align-items-center justify-content-between mr-4"></div>

					<div className="flex align-items-center justify-content-end">
						<div className="flex flex-columns">
							<div className="flex">
								<div className="actions flex ml-4">
									<DisplayIcon
										data-tip="Show Mapping Difference Page"
										icon="tablerInfo"
										className="action-icon"
										onClick={() => {
											console.log('PROCESS :: ', process.env);
											window.open(
												`${
													process.env.REACT_APP_ENV === 'staging'
														? '/web/XCharge'
														: '#'
												}/settings/mapping/mappingDifference`,
												'_blank'
											);
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const active = (rowData: any) => {
		return (
			<>
				{rowData.role.name !== 'NoRole' ? (
					<Button
						className="p-button-rounded p-button-text p-button-icon-only"
						tooltipOptions={{ position: 'left' }}
						tooltip="Active"
					>
						<DisplayIcon icon="tablerCheck" className="table-action" />
					</Button>
				) : (
					<Button
						className="p-button-rounded p-button-text p-button-icon-only"
						tooltipOptions={{ position: 'left' }}
						tooltip="In-Active"
					>
						<DisplayIcon
							icon="tablerWarning"
							className="table-action red-icon"
						/>
					</Button>
				)}
			</>
		);
	};

	const searchTerm = useSelector(getSearchTerm);
	const [searchKeyword, setSearchKeyword] = useState('');
	useEffect(() => {
		const apiCallTimeoutId = setTimeout(() => {
			if (searchTerm !== '') {
				setSearchKeyword(searchTerm);
			} else {
				setSearchKeyword('');
			}
		}, 1000);

		return () => clearTimeout(apiCallTimeoutId);
	}, [searchTerm]);
	const computedData = useMemo(() => {
		let result = data;

		result =
			searchKeyword !== '' && data
				? data.filter((items: any) => {
						console.log(Object.values(items));
						return (
							Object.values(items).findIndex((chunk: any) =>
								chunk
									.toString()
									.toLowerCase()
									.includes(searchKeyword.toLowerCase())
							) !== -1
						);
				  })
				: data;

		return result;
	}, [data, searchKeyword]);

	return (
		<div className={`grid`}>
			<div className="cards">
				<DataTable
					header={UsersPageHeader}
					value={computedData}
					responsiveLayout="scroll"
					onRowClick={(event) => onRowclick(event)}
					loading={isLoading}
					tableClassName="all-users"
				>
					<Column
						header="#"
						alignHeader="left"
						body={(data, props) => props.rowIndex + 1}
					></Column>
					<Column field="firstName" header="First Name" sortable></Column>
					<Column field="lastName" header="Last Name" sortable></Column>
					<Column field="email" header="E-Mail" sortable></Column>
					<Column field="role.name" header="User Role" sortable></Column>
					<Column field="countryNew" header="Country" sortable></Column>
					<Column field="departmentNew" header="Department" sortable></Column>
					<Column
						field="departmentDescription"
						header="Department Description"
						sortable
					></Column>
					<Column
						field='updatedOn'
						header="Updated On"
						body={(rowData) => formatDateDDMMYYYY(rowData.updatedOn)}
						sortable
					></Column>
					<Column header="Active" body={active}></Column>
					<Column header="Actions" body={actions}></Column>
				</DataTable>
			</div>

			{editModal.editUser && selectedRow && (
				<EditUserDialog selectedRow={selectedRow} />
			)}
		</div>
	);
};

export default UsersPage;

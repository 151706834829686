import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import DisplayIcon from "../../components/icon";
import { getSearchTerm, setSettingsModal } from "../../store/commonSlice";
import { useDeleteNewsAlertMutation, useGetAllNewsQuery } from "../../store/settingApi";
import { formatDateDDMMYYYY } from "../../utils/utility-function";
import AddOrEditNewsAlerts from "./add-news-alerts";

const NewsAlerts = () => {
    
	const dispatch = useDispatch();
    const searchTerm = useSelector(getSearchTerm);
    
	const { data, isLoading } = useGetAllNewsQuery();
	const [deleteNewsAlert, { isLoading: isDeleting }] = useDeleteNewsAlertMutation();
    
	useEffect(() => {
		document.title = 'News Alerts | XCharge';
	}, []);
    
	const [deleteDialog, setDeleteDialog] = useState(false);
	const [deleteId, setDeleteId] = useState(undefined);
	const [searchKeyword, setSearchKeyword] = useState('');    
	const [editData, setEditData] = useState<any>(undefined);

	useEffect(() => {
		const apiCallTimeoutId = setTimeout(() => {
			if (searchTerm !== '') {
				setSearchKeyword(searchTerm);
			} else {
				setSearchKeyword('');
			}
		}, 1000);

		return () => clearTimeout(apiCallTimeoutId);
	}, [searchTerm]);

	const computedData = useMemo(() => {
		let result = data;

		result =
			searchKeyword !== '' && data
				? data.filter((items: any) => {
					console.log(Object.values(items));
					return (
						Object.values(items).findIndex((chunk: any) =>
							chunk
								.toString()
								.toLowerCase()
								.includes(searchKeyword.toLowerCase())
						) !== -1
					);
				})
				: data;

		return result;
	}, [data, searchKeyword]);

    const header = () => {
		return (
			<div className="flex align-items-center justify-content-between">
				<div className="flex align-items-center">
					<h5>News Alerts</h5>
				</div>
				<div className="actions flex">
					<Button
						icon="pi pi-filter"
						className="p-button-rounded p-button-text p-button-plain p-button-icon-only"
						tooltip="News Alerts"
						tooltipOptions={{ position: 'left' }}
						onClick={() => {
							setEditData(undefined);
							dispatch(setSettingsModal({ addOrEditNewsAlerts: true }));
						}}
					>
						<DisplayIcon icon="add" className="action-icon" />
					</Button>
				</div>
			</div>
		);
	};
    
    const actions = (rowData: any) => {
        return (
            <div className="table-menu">
                <Button
                    className="p-button-rounded p-button-text p-button-icon-only"
                    tooltipOptions={{ position: 'left' }}
                    onClick={() => {
                        dispatch(setSettingsModal({ addOrEditNewsAlerts: true }));
                        setEditData(rowData);
                    }}
                    tooltip="Edit News Alert"
                >
                    <DisplayIcon icon="edit" className="table-action" />
                </Button>
                <Button
                    className="p-button-rounded p-button-text p-button-icon-only"
                    tooltipOptions={{ position: 'left' }}
                    onClick={() => {
                        setDeleteId(rowData.id_pk);
                        setDeleteDialog(true);
                    }}
                    tooltip="Delete News Alert"
                >
                    <DisplayIcon icon="tablerTrash" className="table-action" />
                </Button>
            </div>
        );
    };
    
    const confirmDelete = () => (
		<div>
			<Button
				label="Cancel"
				icon="pi pi-times"
				onClick={() => {
					setDeleteDialog(false);
					setDeleteId(undefined);
				}}
			/>
			<Button
				label="Confirm"
				icon="pi pi-times"
                loading={isDeleting}
				onClick={async () => {
					deleteId &&
					await deleteNewsAlert(deleteId)
						.unwrap()
						.then((payoad: any) => {
							toast.success('News Alert Deleted Successfully');
							setDeleteDialog(false);
							setDeleteId(undefined);
						})
						.catch((e: any) => {
							toast.error(e.data.message);
						});
				}}
			/>
		</div>
	)
    
    return (
        <>
            <div className={`grid`}>
                <div className="cards">

                    <DataTable
                        tableClassName='bug-report-track'
                        value={computedData}
                        responsiveLayout="scroll"
                        header={header}
                        loading={isLoading}
                    >
                        <Column field="id_pk" header="ID" sortable></Column>
                        <Column field="title" header="Title" sortable></Column>
                        <Column field="description" header="Description" sortable></Column>
                        <Column
                            field='start_date'
                            header="Start Date"
                            body={(rowData) => formatDateDDMMYYYY(rowData.start_date)}
                            sortable
                        ></Column>
                        <Column
                            field='expiry_date'
                            header="Expiry Date"
                            body={(rowData) => formatDateDDMMYYYY(rowData.expiry_date)}
                            sortable
                        ></Column>
                        <Column
                            body={actions}
                            align="left"
                            bodyStyle={{ width: '10%' }}
                        ></Column>
                    </DataTable>
                </div>
            </div>
            <AddOrEditNewsAlerts editData={editData} />
			<Dialog
				header="Delete News Alert"
				visible={deleteDialog}
				footer={confirmDelete}
				onHide={() => {
					setDeleteDialog(false);
					setDeleteId(undefined);
				}}
			>
				<div className='flex flex-wrap justify-content-center'>
					<DisplayIcon className='flex justify-items-center warning-icon' icon="warning" />
				</div>
				<p className="mt-2 px-5 bold-text">
					Do you want to delete this News Alert?
				</p>
			</Dialog>
        </>
    )
}

export default NewsAlerts;
import { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Divider } from 'primereact/divider';
import { useDispatch, useSelector } from 'react-redux';
import { setSettingsModal, settingsModalState } from '../../store/commonSlice';
import * as Yup from 'yup';
import InputField from '../../components/input-field/input-field';
import { formatDateDDMMYYYYhhmm } from '../../utils/utility-function';
import { InputTextarea } from 'primereact/inputtextarea';
import { useUpdateMappedServiceMutation } from '../../store/servicesApi';
import { IMappingData } from '.';
import { toast } from 'react-toastify';

const EditMappingModal = ({ mappingData }: { mappingData: IMappingData }) => {
	const dispatch = useDispatch();
	const openModal = useSelector(settingsModalState);

	const [keyword, setKeyword] = useState(mappingData.keyWord);
	const [comment, setComment] = useState(mappingData.comments);

	const [updateMapping, { isLoading: isMappingUpdating }] =
		useUpdateMappedServiceMutation();

	useEffect(() => {
		setComment(mappingData.comments);
	}, []);
	const resetData = () => {
		setComment('');
		setKeyword('');
	};

	const renderFooter = (name: any) => {
		return (
			<div>
				<Button
					label="Cancel"
					icon="pi pi-times"
					onClick={() => {
						resetData();
						dispatch(setSettingsModal({ editMapping: false }));
					}}
					className="p-button-text"
				/>
				<Button
					label="Save"
					icon="pi pi-save"
					onClick={() => {
						updateMapping({
							comments: comment,
							costCenter_FK: mappingData.costCenter_FK,
							id: mappingData.id,
							keyWord: keyword,
							said: mappingData.said,
							serviceId: mappingData.serviceId,
							serviceName: mappingData.serviceName,
							targetCostCenter: mappingData.targetCostCenter,
							targetResponsible: mappingData.targetResponsible,
						})
							.unwrap()
							.then((payload: any) => {
								toast.success('Updated Mapping');
							})
							.catch((e: any) => toast.error('Error Updating Mapping'));
						resetData();
						dispatch(setSettingsModal({ editMapping: false }));
					}}
				/>
			</div>
		);
	};

	return (
		<Dialog
			header="Edit Mapping"
			visible={openModal.editMapping}
			style={{ width: '75vw' }}
			footer={renderFooter('displayBasic')}
			onHide={() => {
				resetData();
				dispatch(setSettingsModal({ editMapping: false }));
			}}
		>
			<h5 className="section-title">Mapping</h5>
			<div className="p-fluid grid formgrid mt-5">
				<div className="grid col-12">
					<div className={`field col-4 mt-3`}>
						<span className="p-float-label">
							<InputText
								id="costCenter"
								value={mappingData.targetCostCenter}
								disabled
							/>
							<label htmlFor="costCenter">Target Cost Center</label>
						</span>
					</div>
					<div className={`field col-4 mt-3`}>
						<span className="p-float-label">
							<InputText
								id="costCenter"
								value={mappingData.targetResponsible}
								disabled
							/>
							<label htmlFor="costCenter">Target Responsbile</label>
						</span>
					</div>
					<div className={`field col-4 mt-3`}>
						<span className="p-float-label">
							<InputText
								id="costCenter"
								value={keyword}
								onChange={(e) => setKeyword(e.target.value)}
							/>
							<label htmlFor="costCenter">Keyword</label>
						</span>
					</div>
				</div>
			</div>
			<h5 className="section-title mt-3">Comments</h5>
			<div className="p-fluid grid formgrid mt-5">
				<div className="grid col-12">
					<InputTextarea
						value={comment}
						onChange={(e) => setComment(e.target.value)}
						autoResize
					/>
				</div>
			</div>
			<h5 className="section-title mt-3">Updated By</h5>
			<div className="p-fluid grid formgrid mt-5">
				<div className="grid col-12">
					<div className={`field col-4 mt-3`}>
						<span className="p-float-label">
							<InputText
								id="updatedBy"
								value={
									mappingData.updatedBy === null || !mappingData.updatedBy
										? '-'
										: `${mappingData.updatedBy.firstName} ${mappingData.updatedBy.lastName} (${mappingData.updatedBy.email})`
								}
								disabled
							/>
							<label htmlFor="updatedBy">Updated By</label>
						</span>
					</div>
					<div className={`field col-4 mt-3`}>
						<span className="p-float-label">
							<InputText
								id="updatedOn"
								value={formatDateDDMMYYYYhhmm(mappingData.updatedOn)}
								disabled
							/>
							<label htmlFor="updatedOn">Updated On</label>
						</span>
					</div>
				</div>
			</div>
		</Dialog>
	);
};

export default EditMappingModal;

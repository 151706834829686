import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

import store from './store';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import ReactTooltip from 'react-tooltip';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
	<React.StrictMode>
		<CookiesProvider>
			<Provider store={store}>
				<App />
				<ReactTooltip
					type="light"
					className="z-5000 border-1"
					border
					borderColor="#4d69fa"
				/>
				<ReactTooltip
					type="light"
					className="z-5000 border-1"
					border
					borderColor="#4d69fa"
					id="mapping"
				/>
			</Provider>
		</CookiesProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Import the RTK Query methods from the React-specific entry point
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { IProp } from '../components/cost-center-card/index';
import { IChargingReport } from '../views/charging-report-upload';
import { ICostCenterData } from '../views/cost-center';
import { IMappingData } from '../views/mapping';
import { IServiceData, IServiceRequest } from '../views/service/index';
import { IAssetList } from '../views/asset-list-upload/set-asset-price';
import { IAllUsers, IUsers } from '../views/users';
import { baseApi } from './baseApi';
import { ICreateNews, IGetServiceID, INewCostCenter, INewsAlerts, IRequestServiceID } from '../types/common.interface';
const actualsEndPoint = 'actuals';

const settingApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		getAllMapping: builder.query<IMappingData[], void>({
			query: () => `serviceDetails/mappedservices`,
			providesTags: ['Mapping'],
		}),
		getMappingUpdates: builder.query<any, void>({
			query: () => `serviceDetails/mappingupdates`,
			providesTags: ['MappingUpdates'],
		}),
		getMappingDifference: builder.query<any, void>({
			query: () => `serviceDetails/mappingdifference`,
		}),
		addCostCenter: builder.mutation<any, INewCostCenter>({
			query: (requestData) => ({
				url: `costcenter`,
				method: 'PUT',
				body: requestData,
			}),
			invalidatesTags: ['costCenter'],
		}),
		
		getAllServiceID: builder.query<IGetServiceID[], void>({
			query: () => `serviceDetails/getallserviceID`,
			providesTags: ['RequestServiceID'],
		}),
		generateServiceID: builder.mutation<any, IRequestServiceID>({
			query: (serviceData) => ({
				url: `serviceDetails/GenerateServiceID`,
				method: 'POST',
				body: serviceData,
			}),
			invalidatesTags: ['RequestServiceID'],
		}),
		
		updateServiceID: builder.mutation<any, IRequestServiceID>({
			query: (serviceData) => ({
				url: `serviceDetails/UpdateServiceID`,
				method: 'PUT',
				body: serviceData,
			}),
			invalidatesTags: ['RequestServiceID'],
		}),
		
		getAllNews: builder.query<INewsAlerts[], void>({
			query: () => `popup/getnewsall`,
			providesTags: ['NewsAlerts'],
		}),
		
		getNewsAlerts: builder.query<INewsAlerts[], void>({
			query: () => `popup/getnews`
		}),
		
		addNewsAlert: builder.mutation<any, ICreateNews>({
			query: (newsAlertData) => ({
				url: `popup/addnews`,
				method: 'POST',
				body: newsAlertData,
			}),
			invalidatesTags: ['NewsAlerts'],
		}),
		
		editNewsAlert: builder.mutation<any, ICreateNews>({
			query: (newsAlertData) => ({
				url: `popup/updatenews`,
				method: 'PUT',
				body: newsAlertData,
			}),
			invalidatesTags: ['NewsAlerts'],
		}),

		deleteNewsAlert: builder.mutation<void, { id: number }>({
			query: (id) => ({
				url: `popup/deletenews?id_pk=${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['NewsAlerts'],
		}),
		
		updateNewsPrefs: builder.mutation<any, void>({
			query: () => ({
				url: `user/updateNewsPreference`,
				method: 'PUT',
			})
		}),
	}),
});

export const {
	useGetAllMappingQuery,
	useAddCostCenterMutation,
	useGetMappingUpdatesQuery,
	useGetMappingDifferenceQuery,
	useGetAllServiceIDQuery,
	useGenerateServiceIDMutation,
	useUpdateServiceIDMutation,
	useGetAllNewsQuery,
	useAddNewsAlertMutation,
	useEditNewsAlertMutation,
	useDeleteNewsAlertMutation,
	useGetNewsAlertsQuery,
	useUpdateNewsPrefsMutation,
} = settingApi;

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useMemo, useState } from 'react';
import { MappingHeader } from '.';
import useFindCurrentLocation from '../../hooks/useFindCurrentLocation';
import {
	useGetNewServicesQuery,
	useGetWildCardMappingQuery,
} from '../../store/chargingReportApi';
import { getSearchTerm } from '../../store/commonSlice';
import { useGetMappingDifferenceQuery } from '../../store/settingApi';
import { ChargingReportHeader } from '../charging-report-upload';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

const MappingDifference = () => {
	const { data, isLoading, isSuccess, isError, error } =
		useGetMappingDifferenceQuery();

	const searchTerm = useSelector(getSearchTerm);
	const [searchKeyword, setSearchKeyword] = useState('');

	useEffect(() => {
		document.title = 'Mapping Difference | XCharge';
	}, []);

	useEffect(() => {
		const apiCallTimeoutId = setTimeout(() => {
			if (searchTerm !== '') {
				setSearchKeyword(searchTerm);
			} else {
				setSearchKeyword('');
			}
		}, 1000);

		return () => clearTimeout(apiCallTimeoutId);
	}, [searchTerm]);
	const computedData = useMemo(() => {
		let result = data;

		result =
			searchKeyword !== '' && data
				? data.filter((items: any) => {
						console.log(Object.values(items));
						return (
							Object.values(items).findIndex((chunk: any) =>
								chunk
									.toString()
									.toLowerCase()
									.includes(searchKeyword.toLowerCase())
							) !== -1
						);
				  })
				: data;

		return result;
	}, [data, searchKeyword]);

	const currentPage = useFindCurrentLocation();
	return (
		<>
			<div className={`grid`}>
				<div className="cards">
					<DataTable
						tableClassName='mapping-difference'
						responsiveLayout="scroll"
						header={() => {
							ReactTooltip.rebuild();
							return <MappingHeader title="Mapping Difference" />;
						}}
					>
						<Column
							header="#"
							alignHeader="left"
							body={(data, props) => {
								return props.rowIndex + 1;
							}}
						></Column>
						<Column field="serviceId" header="Service Id" sortable></Column>
						<Column field="serviceName" header="Service Name" sortable></Column>
						<Column
							field="addInfo"
							header="Additional Information"
							sortable
						></Column>
						<Column
							field="old_TargetCostCenter"
							header="Old Cost Center"
							sortable
						></Column>
						<Column
							field="old_TargetResponsible"
							header="Old Target Responsible"
							sortable
						></Column>
						<Column
							field="old_Name"
							header="Old Cost Center Name"
							sortable
						></Column>
						<Column
							field="new_TargetCostCenter"
							header="New Cost Center"
							sortable
						></Column>
						<Column
							field="new_TargetResponsible "
							header="New Target Responsible"
							sortable
						></Column>
						<Column
							field="new_Name "
							header="New Cost Center Name"
							sortable
						></Column>
					</DataTable>
				</div>
			</div>
		</>
	);
};

export default MappingDifference;

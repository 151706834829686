import { Skeleton } from 'primereact/skeleton';

const CardSkeleton = () => {
	return (
		<>
			{new Array(4).fill(0).map((_, index) => (
				<div
					className="flex flex-column justify-content-between col-6 mt-6"
					key={index}
				>
					<Skeleton height="3rem" className="mb-2 w-full"></Skeleton>
					<div className="flex justify-content-between">
						<Skeleton height="3rem" width="5rem" className="mb-2"></Skeleton>
						<Skeleton height="3rem" width="5rem" className="mb-2"></Skeleton>
						<Skeleton height="3rem" width="5rem" className="mb-2"></Skeleton>
					</div>
					<Skeleton height="3rem" className="mb-2 w-full"></Skeleton>
				</div>
			))}
		</>
	);
};

export default CardSkeleton;

import { Icon } from '@iconify/react';
import ReactTooltip from 'react-tooltip';
import {
	IconTable,
	IconList,
	IconEdit,
	IconClick,
	IconTrash,
	IconPencil,
	IconFileExport,
	IconMail,
	IconHistory,
	IconMessageCircle2,
	IconDeviceFloppy,
	IconX,
	IconCheck,
	IconBoxMultiple,
	IconSquare,
	IconUser,
	IconUsers,
	IconAlertTriangle,
	IconBell,
	IconArrowsSort,
	IconBook2,
	IconAdjustmentsAlt,
	IconExternalLink,
	IconExchange,
	IconArrowsLeftRight,
	IconInfoCircle,
	IconBarrierBlock,
	IconChevronsRight,
	IconReport,
	IconDevices,
	IconBookUpload,
} from '@tabler/icons';
import React, { ReactElement } from 'react';

const iconMapping = {
	home: 'fluent:home-20-regular',
	processing: 'fluent:layer-20-regular',
	settings: 'fluent:settings-20-regular',
	archive: 'fluent:archive-20-regular',

	upload: 'fluent:arrow-upload-20-regular',
	export: 'fluent:arrow-export-ltr-20-regular',
	tablerTable: IconTable,

	expand: 'fluent:add-circle-20-regular',
	collapse: 'fluent:subtract-circle-20-regular',

	search: '',

	circle: 'fluent:circle-20-filled',

	add: 'fluent:add-20-regular',
	docmentQuestion: 'fluent:document-question-mark-20-regular',

	booked: 'ph:coins-thin',
	planned: 'fluent:arrow-trending-lines-20-regular',
	picas: 'fluent:phone-laptop-20-regular',

	history: 'fluent:history-20-regular',
	edit: 'fluent:edit-20-regular',
	comment: 'fluent:comment-20-regular',

	map: 'fluent:map-20-regular',
	calendar: 'fluent:calendar-ltr-20-regular',
	contactCard: 'fluent:contact-card-20-regular',
	ticket: 'fluent:ticket-diagonal-20-regular',

	dismissCircle: 'fluent:dismiss-circle-20-regular',
	dismiss: 'fluent:dismiss-20-regular',
	swap: 'fluent:arrow-swap-20-regular',
	filter: 'fluent:filter-20-filled',

	reset: 'fluent:arrow-reset-20-regular',
	toggleMenu: 'fluent:arrow-fit-20-regular',
	info: 'fluent:error-circle-20-regular',
	back: 'fluent:arrow-left-20-regular',
	documentShadow: 'fluent:document-copy-20-regular',
	documentAdd: 'fluent:document-add-20-regular',
	documentOk: 'fluent:document-table-checkmark-20-regular',
	alert: 'fluent:alert-20-regular',
	warning: 'fluent:warning-20-regular',
	mail: 'fluent:mail-20-regular',
	tablerList: IconList,
	tablerEdit: IconEdit,
	tablerPencil: IconPencil,
	tablerFileExport: IconFileExport,
	tablerMail: IconMail,
	tablerHistory: IconHistory,
	tablerMessage: IconMessageCircle2,
	tablerSave: IconDeviceFloppy,
	tablerCancel: IconX,
	tablerCheck: IconCheck,
	tablerCluster: IconBoxMultiple,
	tablerNoCluster: IconSquare,

	excel: 'vscode-icons:file-type-excel',
	forecast: 'fluent:arrow-trending-20-regular',
	tablerTrash: IconTrash,
	user: 'fluent:person-20-regular',
	tablerUser: IconUser,
	tablerUsers: IconUsers,
	tablerWarning: IconAlertTriangle,
	tablerBell: IconBell,
	tablerSort: IconArrowsSort,
	tablerBook: IconBook2,
	tablerAdjustment: IconAdjustmentsAlt,
	tablerExternalLink: IconExternalLink,
	tablerExchange: IconExchange,
	tablerArrowSwap: IconArrowsLeftRight,
	colorLock: 'flat-color-icons:lock',
	colorDeputy: 'flat-color-icons:collaboration',
	tablerInfo: IconInfoCircle,
	tablerBarrier: IconBarrierBlock,
	tablerLogo: IconChevronsRight,
	bugReport: 'fluent:bug-20-regular',
	imageAdd: 'fluent:image-add-20-regular',
	invalidAsset: 'fluent:error-circle-settings-20-regular',
	minimize: 'fluent:subtract-12-regular',
	maximize: 'fluent:open-folder-16-regular',
	dashboard: 'fluent:document-landscape-data-20-regular',
	reportTrack: IconReport,
	releaseNote: 'fluent:clipboard-bullet-list-ltr-20-regular',
	version: 'fluent:document-bullet-list-clock-20-regular',
	tablerDevices: IconDevices,
	serviceInfo: 'fluent:book-information-20-regular',
	addUser: 'fluent:person-add-20-regular',
	requestAccess: 'fluent:person-arrow-back-20-regular',
	timeline: 'fluent:timeline-20-regular',
	error: 'subway:error',
	listUpload: IconBookUpload,
};

export type iconKey = keyof typeof iconMapping;

const CustomIcon = ({
	icon,
	className,
	onClick,
}: {
	className?: string;
	icon: iconKey;
	onClick?: (e?: any) => any;
}) => {
	const MyIcon = iconMapping[icon];
	return (
		<MyIcon strokeLinejoin="miter" className={className} onClick={onClick} />
	);
};

const DisplayIcon = ({
	className,
	icon,
	onClick,
	children,
	...props
}: {
	className?: string;
	icon: iconKey;
	onClick?: (e?: any) => any;
	children?: JSX.Element;
}) => {
	return (
		<>
			<div className={`${icon} ${className}`} {...props}>
				{typeof iconMapping[icon] === 'string' ? (
					<Icon icon={iconMapping[icon] as string} onClick={onClick}></Icon>
				) : (
					<CustomIcon
						icon={icon}
						className={`${className}--svg`}
						onClick={onClick}
					/>
				)}
				{children}
			</div>
		</>
	);
};

export default DisplayIcon;

import { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import {
	dataProcessingModalState,
	setDataProcessingModal,
} from '../../store/commonSlice';
import FileUploader from '../../components/file-uploader';
import { Dropdown } from 'primereact/dropdown';
import CustomAutoComplete from '../../components/autocomplete/autocomplete';
import { Months } from '../../utils/json/months';
import {
	useUploadChargingReportMutation,
	useSendCRUploadNotificationMutation,
	useGetUploadTypesQuery,
	useClearCacheMutation,
} from '../../store/chargingReportApi';
import { Form } from 'formik';
import CustomToast from '../../components/toaster/toaster';
import { toast } from 'react-toastify';
import { Skeleton } from 'primereact/skeleton';

const UploadChargingReport = () => {
	const dispatch = useDispatch();
	const openModal = useSelector(dataProcessingModalState);
	const [selectedFile, setSelectedFile] = useState<File | undefined>();
	const [selectedMonth, setSelectedMonth] = useState('');

	const [
		uploadChargingReport,
		{
			isLoading: isUploading,
			isSuccess: isUploadSuccess,
			isError: isUploadError,
			error: uploadError,
		},
	] = useUploadChargingReportMutation();
	const [clearCache] = useClearCacheMutation();
	const { data: uploadType = [], isLoading: isFetching, } = useGetUploadTypesQuery();
	const [sendCRUploadNotification, { }] = useSendCRUploadNotificationMutation();
	const ouList = [
		{name: 98, value: 98},
		{name: 103, value: 103},
	]
	const footer = () => (
		<div>
			<Button
				label="Cancel"
				icon="pi pi-times"
				onClick={() => {
					setSelectedFile(undefined);
					setSelectedMonth('');
					setSelectedOU(undefined);
					setSelectedUploadType(undefined);
					dispatch(setDataProcessingModal({ uploadChargingReport: false }))
				}}
			/>
			<Button
				label="Upload"
				icon="pi pi-check"
				disabled={(selectedUploadType?.name?.toLowerCase() === 'itservice' && (selectedFile === undefined || selectedOU === undefined)) || selectedMonth.length === 0}
				loading={isUploading}
				onClick={async () => {
					console.log(selectedFile);
					//dispatch(setDataProcessingModal({ uploadChargingReport: false }))
					// if (selectedFile) {
					const payload = new FormData();
					if (selectedUploadType?.name?.toLowerCase() === 'itservice' && selectedFile) payload.append('importdata', selectedFile)
					payload.append('importdata', '');
					await uploadChargingReport({
						file: payload,
						month: `${selectedMonth}-${new Date().getFullYear()}`,
						uploadType: selectedUploadType.name,
						OU: selectedOU,
						vId: 0,
					})
						.unwrap()
						.then((payload) => {
							toast.success('Charging Report Uploaded Successfully!');
							process.env.REACT_APP_ENV === 'production' && sendCRUploadNotification({uploadType: selectedUploadType.name}).unwrap().then().catch();
							window.open(
								`${process.env.REACT_APP_ENV === 'staging'
									? '/web/XCharge'
									: '#'
								}/dataProcessing/reportUpload/newServices`,
								'_blank'
							);
							clearCache('itservice').then(() => console.log('cache cleared successfully'));
							setSelectedFile(undefined);
							setSelectedMonth('');
							setSelectedOU(undefined);
							setSelectedUploadType(undefined);
						})
						.catch((e: any) =>
							toast.error('Error Uploading Charging Report')
						);
					dispatch(setDataProcessingModal({ uploadChargingReport: false }));
					// }
				}}
			/>
		</div>
	);

	const fileCallback = (file: File | undefined) => {
		setSelectedFile(file);
	};

	const [selectedUploadType, setSelectedUploadType] = useState<any>(undefined);
	const [selectedOU, setSelectedOU] = useState<any>(undefined);

	return (
		<Dialog
			header="Upload Charging Report"
			visible={openModal.uploadChargingReport}
			style={{ width: '50vw' }}
			footer={footer}
			onHide={() => {
				setSelectedFile(undefined);
				setSelectedMonth('');
				setSelectedUploadType(undefined);
				setSelectedOU(undefined);
				dispatch(setDataProcessingModal({ uploadChargingReport: false }))
			}}
		>
			<div className="grid p-fluid">
				<div className="grid col-12">
					{selectedUploadType?.name?.toLowerCase() === 'itservice' && <Message
						severity="info"
						text="Please upload your file in the .xlsx format"
						className="mt-4 col-12"
					/>}


					{isUploading ? (
						<Skeleton height="2rem" className="col-12 w-full mt-3" />
					) : (
						<div className="grid col-12 mt-4">
							<div className='field col-6'>
								<span className="p-float-label">
									<Dropdown
										value={selectedUploadType}
										options={uploadType}
										onChange={(item) => {

											setSelectedUploadType(item.value);
											console.log(selectedUploadType);

										}}
										optionLabel="name"
										id="uploadType"
										name="Upload Type"
									/>
									<label htmlFor="uploadType">Upload Type</label>
								</span>
							</div>

							{
							selectedUploadType?.name?.toLowerCase() === 'itservice' &&
							<div className='field col-6'>
								<span className="p-float-label">
									<Dropdown
										value={selectedOU}
										options={ouList}
										onChange={(item) => {
											setSelectedOU(item.value);
										}}
										optionLabel="name"
										id="OU"
										name="Select OU"
									/>
									<label htmlFor="OU">Select OU</label>
								</span>
							</div>
							}

							<div className="field col-6">
								<CustomAutoComplete
									customId="select-month"
									placeholder="Select Month"
									inputList={Months}
									field="month"
									target="month"
									callback={(item) => {
										if (item) {
											setSelectedMonth(item.month);
										} else {
											setSelectedMonth('');
										}
									}}
								/>
							</div>

						</div>
					)}

					{selectedUploadType?.name?.toLowerCase() === 'itservice' && <FileUploader callback={fileCallback} disabled={isUploading} />}

					{/* {isUploading ? (
						<Skeleton height="2rem" className="col-6 w-full mt-3" />
					) : (
						<div
							className={`flex field col-6 justify-content-end mt-3`}
						>
							<CustomAutoComplete
								customId="select-month"
								placeholder="Select Month"
								inputList={Months}
								field="month"
								target="month"
								callback={(item) => {
									if (item) {
										setSelectedMonth(item.month);
									} else {
										setSelectedMonth('');
									}
								}}
							/>
						</div>
					)} */}
				</div>
				<Message
					severity="warn"
					text="Already existing Actuals will be overwritten"
					className="mt-4"
				/>
			</div>
		</Dialog>
	);
};

export default UploadChargingReport;

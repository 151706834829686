import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { useDispatch, useSelector } from 'react-redux';
import {
    settingsModalState,
    setSettingsModal,
} from '../../store/commonSlice';
import { AutoComplete } from "primereact/autocomplete";
import { toast } from "react-toastify";
import { useEffect, useRef, useState } from 'react';
import { useGenerateServiceIDMutation } from "../../store/settingApi";
import { useGetAllUsersQuery } from '../../store/UserApi';
import { Button } from 'primereact/button';
import InputField from '../../components/input-field/input-field';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';

const AddServiceID = () => {

    const dispatch = useDispatch();
    const serviceModal = useSelector(settingsModalState);

    const [ownerData, setOwnerData] = useState<any>(undefined);
    const [serviceName, setServiceName] = useState<any>(undefined);
    const [description, setDescription] = useState<any>(undefined);
    const [status, setStatus] = useState<any>(undefined);

    const itemTemplate = (item: any) => {
        return (
            <div className="country-item">
                <div>{item.name}</div>
            </div>
        );
    };
    const { data: allUsers = [] } = useGetAllUsersQuery();

    const [serviceOwner, setServiceOwner] = useState<any>(null);
    const [filteredValues, setFilteredValues] = useState<any>(null);

    const searchValues = (event: any) => {
        setTimeout(() => {
            let _filteredValues;
            let inputList = allUsers.map((user) => ({
                id: user.id,
                name: `${user.firstName} ${user.lastName}`,
                firstName: user.firstName,
                email: user.email,
            }))
            if (!event.query.trim().length) {
                _filteredValues = [...inputList];
            } else {
                _filteredValues = inputList.filter((listValue) => {
                    return listValue.name.toLowerCase().includes(event.query.toLowerCase());
                }
                );
            }
            setFilteredValues(_filteredValues);
        }, 100);
    };

    const [generateServiceID, { isLoading: isGeneratingID }] = useGenerateServiceIDMutation();

    const requestServiceID = async () => {
        await generateServiceID({
            serviceName,
            serviceOwner: ownerData.name,
            serviceOwnerEmail: ownerData.email,
            description,
            status
        })
            .unwrap()
            .then((payload: any) => {
                toast.success('Service ID Generated successfully', {
                    position: 'bottom-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                dispatch(setSettingsModal({ addServiceID: false }))
            })
            .catch((e: any) =>
                toast.error('Failed Generating Service ID', {
                    position: 'bottom-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            );

        // formik.resetForm();
    };

    const statusList = [
        { status: "Active", value: "Active" },
        { status: "InActive", value: "InActive" },
    ]

    return (
        <>
            <Dialog
                header="Request Service ID"
                visible={serviceModal.addServiceID}
                className="flex justify-content-center"
                style={{ width: '40vw' }}
                onHide={() => {
                    setServiceName(undefined)
                    setDescription(undefined)
                    setServiceOwner(undefined)
                    setStatus(undefined)
                    setOwnerData(undefined)
                    dispatch(setSettingsModal({ addServiceID: false }))
                }}
            >

                <div className="p-fluid grid formgrid mt-2">
                    <div className="grid col-12 mt-4">

                        {/* <InputField
							field="id"
							formik={formik}
							label="Id"
							colSpan={2}
							disabled={isGeneratingID}
						/> */}

                        <div className={`field col-6`}>
                            <span className="p-float-label">
                                <InputText
                                    id="serviceName"
                                    value={serviceName}
                                    onChange={(e) => {
                                        console.log(e);
                                        e.target.value ? setServiceName(e.target.value) : setServiceName(undefined);
                                    }}
                                />
                                <label htmlFor="serviceName">Service Name</label>
                            </span>
                        </div>


                        <div className={`field col-6`}>
                            <span className="p-float-label">
                                <AutoComplete
                                    className={`auto-complete animate-block field col-2`}
                                    itemTemplate={itemTemplate}
                                    value={serviceOwner}
                                    field="name"
                                    forceSelection
                                    suggestions={filteredValues}
                                    completeMethod={searchValues}
                                    onChange={(e) => {
                                        if (e.value === null) {
                                            e.value = ''
                                        }
                                        setServiceOwner(e.value)
                                        console.log(e.value);
                                        if (e !== undefined && e.value) {
                                            setOwnerData({
                                                id: e.value.id,
                                                name: e.value.name,
                                                email: e.value.email,
                                            });
                                        } else {
                                            setOwnerData(undefined);
                                        }

                                    }}
                                    id='assign-deputy-auto'
                                    inputId='name'
                                    dropdown
                                />
                                <label htmlFor="assign-deputy-auto">Service Owner Name</label>
                            </span>
                        </div>

                        <div className={`field col-12`}>
                            <span className="p-float-label">
                                <InputTextarea
                                    id="description"
                                    value={description}
                                    rows={5}
                                    onChange={(e) => {
                                        console.log(e);
                                        setDescription(e.target.value);
                                    }}
                                />
                                <label htmlFor="description">Description</label>
                            </span>
                        </div>

                        <div className={`field col-6`}>
                            <span className="p-float-label">
                                <Dropdown
                                    value={status}
                                    options={statusList}
                                    onChange={(item) => {
                                        setStatus(item.value);
                                    }}
                                    optionLabel="status"
                                    id="Status"
                                    name="Status"
                                />
                                <label htmlFor="status">Status</label>
                            </span>
                        </div>

                        <div className={`field col-6`}>
                            <Button
                                label="Request Service ID"
                                onClick={requestServiceID}
                                icon="pi pi-save"
                                className="p-button-sm"
                                loading={isGeneratingID}
                                disabled={!ownerData || !status || !serviceName}
                            />
                        </div>
                    </div>
                </div>

            </Dialog>
        </>
    )
}

export default AddServiceID;
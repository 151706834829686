import React from 'react';
import { Outlet } from 'react-router-dom';

const ArchiveShellPage: React.FC = function () {
	//const allCostCenters = useAppSelector((state) => state.actual.actualAll);

	return (
		<>
			<Outlet />
		</>
	);
};

export default ArchiveShellPage;

import { useEffect, useMemo, useState } from 'react';
import CostCenterCard, { IProp } from '../../components/cost-center-card';
import ExtraActions from '../../components/extra-actions';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ScrollTop } from 'primereact/scrolltop';
import { ProgressSpinner } from 'primereact/progressspinner';
import {
	useGetOwnActiveCostCentersQuery,
	useLazyGetAllActiveCostCentersQuery,
	useLazyGetOwnActiveCostCentersQuery,
	useGetOwnActivePicasCostCentersQuery,
	useLazyGetOwnActivePicasCostCentersQuery,
	useLazyGetAllActivePicasCostCentersQuery,
} from '../../store/apiSlice';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	commonState,
	setCostcenterId,
	getIsAdmin,
	getSearchTerm,
	selectHomeFilters,
	setFilteredCostCenters,
	setServiceOptions,
	getServiceOptions,
	getIsCostController,
	getInvalidUser,
	setTriggerIntro,
} from '../../store/commonSlice';
import { useUseArchiveSearchMutation, useUseSearchMutation } from '../../store/commonApi';
import { ISearchResult } from '../../types/common.interface';
import useFindCurrentLocation from '../../hooks/useFindCurrentLocation';
import {
	dualSearch,
	formatCurrency,
	searchHighlighter,
	sortingFunction,
} from '../../utils/utility-function';
import {
	useLazyGetAllArchiveDataQuery,
	useLazyGetOwnArchiveDataQuery,
} from '../../store/archiveApi';
import PAGES from '../../utils/pages.enum';
import {
	useGetCurrentUserQuery,
	useIsUserDeputyQuery,
} from '../../store/UserApi';
import CardSkeleton from '../../components/card-skeleton';
import { Message } from 'primereact/message';
import DisplayIcon from '../../components/icon';

import './styles.scss';
import PicasDialog from '../service/picas-dialog';
import getOU from '../../hooks/useGetOU';

const ActualsPage = function () {
	const dispatch = useDispatch();
	const params = useParams();
	const archiveVersion = params.versionId ? params.versionId : undefined;

	const [triggerAllCostCenterData, { isFetching: isLoadingAllActualsData }] =
		useLazyGetAllActiveCostCentersQuery();
	const [triggerOwnCostCenterData, { isFetching: isLoadingOwnActualsData }] =
		useLazyGetOwnActiveCostCentersQuery();

	const [triggerAllPicasCostCenterData, { isFetching: isLoadingAllPicasActualsData }] =
		useLazyGetAllActivePicasCostCentersQuery();
	const [triggerOwnPicasCostCenterData, { isFetching: isLoadingOwnPicasActualsData }] =
		useLazyGetOwnActivePicasCostCentersQuery();

	const [primaryData, setPrimaryData] = useState<IProp[]>([]);
	const [ownPrimaryData, setOwnPrimaryData] = useState<IProp[]>([]);
	const [isArchive, setIsArchive] = useState(false);
	
	const archiveData = useSelector(commonState).archiveData;
	const ownArchiveData = useSelector(commonState).ownArchiveData;
	const selectedArchiveId = useSelector(commonState).selectedArchiveId;
	
	const OU = getOU();

	// const { data: ownCostCenters = [], isLoading: isLoadingOwnCostCenters } =
	// 	useGetOwnActiveCostCentersQuery();

	const { data: deputyList = [], } = useIsUserDeputyQuery();

	const isAdmin = useSelector(getIsAdmin);
	const isInvalidUser = useSelector(getInvalidUser);
	const [triggerArchiveData, { isFetching: isLoadingAllArchiveData }] =
		useLazyGetAllArchiveDataQuery();
	const [triggerOwnArchiveData, { isFetching: isLoadingOwnArchiveData }] =
		useLazyGetOwnArchiveDataQuery();
	const pageName = useFindCurrentLocation();
	const location = useLocation();
	const navigate = useNavigate();
	const { data: currentUser, isLoading: isLoadingUser } =
		useGetCurrentUserQuery(0);

	const allModals = useSelector(getServiceOptions);
	useEffect(() => {
		document.title = 'Actuals | XCharge';
	}, []);

	useEffect(() => {
		isInvalidUser && navigate('/invalidUser', { state: { prevPath: location.pathname } })
	}, [isInvalidUser])

	useEffect(() => {
		const fetchData = async () => {
			if (pageName === PAGES.ARCHIVE) {
				setIsArchive(true);
				if (selectedArchiveId) {
					const {
						data = [],
						isLoading,
						isError,
						error,
					} = await triggerArchiveData(Number(selectedArchiveId));
					const { data: ownData = [] } = await triggerOwnArchiveData(
						Number(selectedArchiveId)
					);
					setOwnPrimaryData(ownData);
					setPrimaryData(data);
				}
				// setOwnPrimaryData(ownArchiveData);
				// setPrimaryData(archiveData);
			} else if (pageName === PAGES.PICAS || pageName === PAGES.WORKPLACE_103) {
				const { data = [] } = await triggerAllPicasCostCenterData();
				const { data: ownData = [] } = await triggerOwnPicasCostCenterData();
				
				let filteredData;
				let filteredOwnData;
				if (pageName === PAGES.WORKPLACE_103) {
					filteredData = data.filter(item => item.ou === 103)
					filteredOwnData = ownData.filter(item => item.ou === 103)
				} else {
					filteredData = data.filter(item => item.ou === 98)
					filteredOwnData = ownData.filter(item => item.ou === 98)
				}

				setPrimaryData(filteredData);
				setOwnPrimaryData(filteredOwnData);
			} else if (pageName === PAGES.ITSERVICES || pageName === PAGES.ITSERVICES_103) {
				// console.log(pageName)
				// console.log(PAGES)
				// if(pageName === PAGES.ITSERVICES) {
					const { data = [] } = await triggerAllCostCenterData({OU});
					const { data: ownData = [] } = await triggerOwnCostCenterData({OU});
					setPrimaryData(data);
					setOwnPrimaryData(ownData);
					dispatch(setTriggerIntro(true));
				// } else {
				// 	const { data = [] } = await triggerAllCostCenterData({OU: 103});
				// 	const { data: ownData = [] } = await triggerOwnCostCenterData({OU: 103});
				// 	setPrimaryData(data);
				// 	setOwnPrimaryData(ownData);
				// }
				
				// let filteredData;
				// let filteredOwnData;
				// if (pageName === PAGES.ITSERVICES_103) {
				// 	filteredData = data.filter(item => item.ou === 103)
				// 	filteredOwnData = ownData.filter(item => item.ou === 103)
				// } else {
				// 	filteredData = data.filter(item => item.ou === 98)
				// 	filteredOwnData = ownData.filter(item => item.ou === 98)
				// }
				
				// setPrimaryData(filteredData);
				// setOwnPrimaryData(filteredOwnData);

				// setPrimaryData(data);
				// setOwnPrimaryData(ownData);
			}
		};
		console.log('PAGE NAME ::', pageName);

		fetchData();
	}, [archiveVersion, pageName, OU, selectedArchiveId]);

	const [startSearch, { isLoading: isSearching }] = useUseSearchMutation();
	const [startArchiveSearch, { isLoading: isArchiveSearching }] = useUseArchiveSearchMutation();

	const searchTerm = useSelector(getSearchTerm);

	const [table, setTable] = useState(true);

	const homeFilters = useSelector(selectHomeFilters);

	const [searchResult, setSearchResult] = useState<ISearchResult[]>();

	useEffect(() => {
		const search = () => {

			if (pageName !== PAGES.ARCHIVE) {

				return startSearch({
					search: searchTerm,
					versionId: params.versionId ? Number(params.versionId) : 0,
				});
			} else {
				return startArchiveSearch({
					search: searchTerm,
					versionId: params.versionId ? Number(params.versionId) : 0,
				});
			}

		}


		const apiCallTimeoutId = setTimeout(async () => {
			if (searchTerm !== '') {
				let result = await search().unwrap();
				setSearchResult(result);
			} else {
				setSearchResult([]);
			}
		}, 1000);

		return () => clearTimeout(apiCallTimeoutId);
	}, [searchTerm]);

	const isFilterActive =
		useSelector(commonState).searchTerm !== '' ||
		homeFilters.costCenterFilter.target_costcenter !== '' ||
		homeFilters.targetResponsilbeFilter.user_responsible !== '' ||
		homeFilters.ccMasterFilter.ccMaster !== '' ||
		homeFilters.ownCostCenter;

	const outputCostCenters = useMemo(() => {
		console.log(primaryData);
		let output: IProp[];
		let selectedData = homeFilters.ownCostCenter
			? [
				...ownPrimaryData,
				...primaryData.filter((cc) =>
					deputyList!.map((iList) => iList.ids).includes(cc.costCenterId)
				),
			]
			: primaryData;

		if (selectedData === undefined) return [];

		// if (pageName === PAGES.ARCHIVE) {
		// 	if (archiveData) {
		// 		selectedData = archiveData;
		// 	}
		// }

		if (selectedData) {
			output =
				searchTerm !== ''
					? selectedData.filter(
						(items) =>
							Object.values(items).findIndex((chunk) =>
								chunk
									.toString()
									.toLowerCase()
									.includes(searchTerm.toLowerCase())
							) !== -1 ||
							dualSearch(
								Object.values(items).join('').toLowerCase(),
								searchTerm.toLowerCase().split('+')
							)
					)
					: selectedData;
			if (searchResult && searchResult.length > 0) {
				const resultAfterSearch = [
					...selectedData.filter(
						(_item) =>
							searchResult.findIndex(
								(_i) => _i.costCenterId === _item.costCenterId
							) !== -1
					),
				];
				output = [
					...resultAfterSearch,
					...output.filter(
						(_item) =>
							searchResult.findIndex(
								(_i) => _i.costCenterId === _item.costCenterId
							) === -1
					),
				];
			}
			output =
				homeFilters.costCenterFilter.target_costcenter !== ''
					? output.filter(
						(d) =>
							d.costCenter === homeFilters.costCenterFilter.target_costcenter
					)
					: output;
			output =
				homeFilters.targetResponsilbeFilter.user_responsible !== ''
					? output.filter(
						(d) =>
							d.userResponsible ===
							homeFilters.targetResponsilbeFilter.user_responsible
					)
					: output;

			output =
				homeFilters.ccMasterFilter.ccMaster !== ''
					? output.filter(
						(d) =>
							d.ccMaster ===
							homeFilters.ccMasterFilter.ccMaster
					)
					: output;

			if (homeFilters.sort.field !== '' && homeFilters.sort.order !== '') {
				output = sortingFunction(
					output,
					homeFilters.sort.field,
					homeFilters.sort.order
				);

				console.log(':: After Sorting ::', output);
			}
			console.log(':: Final Cost Centers ::', output);

			return output;
		}
	}, [
		searchTerm,
		searchResult,
		primaryData,
		ownPrimaryData,
		homeFilters.targetResponsilbeFilter.user_responsible,
		homeFilters.costCenterFilter.target_costcenter,
		homeFilters.ownCostCenter,
		homeFilters.ccMasterFilter.ccMaster,
		homeFilters.sort,
	]);

	const filterSearchTerm = (items: any) =>
		Object.values(items).findIndex((chunk: any) =>
			chunk.toString().toLowerCase().includes(searchTerm.toLowerCase())
		) !== -1 ||
		dualSearch(
			Object.values(items).join('').toLowerCase(),
			searchTerm.toLowerCase().split('+')
		);

	useEffect(() => {
		if (Array.isArray(outputCostCenters)) {
			dispatch(setFilteredCostCenters(outputCostCenters));
		}
	}, [outputCostCenters]);

	function onRowclick(data: any) {
		console.log(data);
	}

	useEffect(() => {
		console.log(':: Home Filters Changed ::');
	}, [homeFilters]);

	const isCostController = useSelector(getIsCostController);

	return (
		<div className={`grid`}>
			<ExtraActions></ExtraActions>

			{homeFilters.ownCostCenter && ownPrimaryData?.length === 0 ? (
				<Message
					severity="warn"
					text="User does not own any cost centers, Please switch the Display Own Cost Center Option"
				/>
			) : (isSearching || isArchiveSearching) ? (
				<>
					<div className="w-full flex justify-content-start align-items-center ml-5 mt-5">
						<ProgressSpinner style={{ width: 25, height: 25, margin: 0 }} />
						<div>
							<h6 className="ml-5">
								Fetching Additional Details From Server ...
							</h6>
						</div>
					</div>
					{
						outputCostCenters?.length !== 0 ? (
							<>
								<Message
									severity="warn"
									content={
										<div className="flex align-items-center">
											<DisplayIcon icon="tablerWarning" className="mr-3" />
											No results found for the term{' '}
											<span className="bold-text ml-3">{searchTerm}</span>
										</div>
									}
									children
									className="mt-5 ml-3"
								></Message>
							</>
						) :
						(
							outputCostCenters?.map((e: any) => (
								<CostCenterCard
									key={e.costCenterId}
									data={e}
									isArchive={isArchive}
									ownData={ownPrimaryData}
									showPicas={(pageName === PAGES.PICAS || pageName === PAGES.WORKPLACE_103) &&
										((isAdmin || isCostController ||
											e.userResponsible.toLowerCase() ===
											currentUser?.ntid.toLocaleLowerCase()) &&
											searchTerm.length === 0)
									}
								/>
							))
						)
					}
				</>
			) : searchTerm.length > 0 && outputCostCenters?.length === 0 ? (
				<Message
					severity="warn"
					content={
						<div className="flex align-items-center">
							<DisplayIcon icon="tablerWarning" className="mr-3" />
							No results found for the term{' '}
							<span className="bold-text ml-3">{searchTerm}</span>
						</div>
					}
					children
					className="mt-5 ml-3"
				></Message>
			) : homeFilters.viewType === 'list' ? (
				isLoadingOwnArchiveData === true ||
					isLoadingAllArchiveData === true ||
					isLoadingAllActualsData === true ||
					isLoadingOwnActualsData === true ||
					isLoadingAllPicasActualsData === true ||
					isLoadingOwnPicasActualsData === true ? (
					<CardSkeleton></CardSkeleton>
				) : (
					outputCostCenters?.length === 0 ? (
						<>
							<Message
								severity="warn"
								content={
									<div className="flex justify-content-start" style={{fontSize: "1.2em"}}>
										{/* <DisplayIcon icon="tablerWarning" className="mr-3" /><br/> */}
											{/* IT-Services data for 2024 will be available at end of March.<br/><br/>

											Due to process changes at central billing the upload is delayed.<br/><br/>

											In case you have any questions please contact us via our npm xcharge@mercedes-benz.com<br/><br/>

											Regards <br/><br/>

											Your XCharge Team */}
											No Records Found
									</div>
								}
								children
								className="mt-5 ml-3"
							></Message>
						</>
					) :
					(
					outputCostCenters?.map((e: any) => (
						<CostCenterCard
							key={e.costCenterId}
							data={e}
							isArchive={isArchive}
							ownData={ownPrimaryData}
							showPicas={(pageName === PAGES.PICAS || pageName === PAGES.WORKPLACE_103) &&
								((isAdmin || isCostController ||
									e.userResponsible.toLowerCase() ===
									currentUser?.ntid.toLocaleLowerCase()) &&
									searchTerm.length === 0)
							}
						/>
					)))
				)
			) : (
				outputCostCenters?.length === 0 ? (
					<>
						<Message
							severity="warn"
							content={
								<div className="" style={{fontSize: "1.2em"}}>
									{/* <DisplayIcon icon="tablerWarning" className="mr-3" /><br/> */}
										{/* IT-Services data for 2024 will be available at end of March.<br/><br/>

										Due to process changes at central billing the upload is delayed.<br/><br/>

										In case you have any questions please contact us via our npm <span><a href={"mailto:xcharge@mercedes-benz.com"}>xcharge@mercedes-benz.com</a></span><br/><br/>

										Regards <br/><br/>

										Your XCharge Team */}
										No Records Found
								</div>
							}
							children
							className="mt-5 ml-3"
						></Message>
					</>
				) :
				(
				<div className="cards">
					<DataTable
						data-cy="table-costcenters"
						value={outputCostCenters}
						responsiveLayout="scroll"
						onRowClick={({ data }) => {
							if (pageName === PAGES.PICAS || pageName === PAGES.WORKPLACE_103) {
								if (isAdmin || isCostController ||
									data?.userResponsible === currentUser?.ntid ||
									deputyList.map((item) => item.ids).includes(data.costCenterId) ||
									currentUser?.ntid && data?.deputy && data?.deputy.split(',').includes(currentUser?.ntid) ||
									ownPrimaryData?.some(e => e.costCenterId === data.costCenterId)) {
									dispatch(setCostcenterId(`${data.costCenterId}`));
									dispatch(setServiceOptions({ picasDetails: true }));
								}
							} else {
								if (
									window.getSelection() &&
									window.getSelection()!.toString().length > 0
								)
									return true;
								navigate(`${data.costCenterId}`, {
									state: { prevPath: location.pathname },
								});
							}
						}}
						loading={
							isLoadingOwnArchiveData === true ||
							isLoadingAllArchiveData === true ||
							isLoadingAllActualsData === true ||
							isLoadingOwnActualsData === true ||
							isLoadingAllPicasActualsData === true ||
							isLoadingOwnPicasActualsData === true
						}
					>
						<Column
							header="#"
							alignHeader="left"
							body={(data, props) => props.rowIndex + 1}
						></Column>
						<Column
							field="name"
							header="Name"
							body={(rowData) => (
								<span
									dangerouslySetInnerHTML={searchHighlighter(
										rowData.name,
										searchTerm
									)}
								></span>
							)}
							sortable
						></Column>
						<Column
							field="costCenter"
							header="Cost Center"
							body={(rowData) => (
								<span
									dangerouslySetInnerHTML={searchHighlighter(
										rowData.costCenter,
										searchTerm
									)}
								></span>
							)}
							sortable
						></Column>
						{(pageName === PAGES.ITSERVICES || pageName === PAGES.ITSERVICES_103) && <Column
							header="Booked"
							field="bookedAmount"
							body={(data) =>
								data.services.filter(filterSearchTerm).length === 0
									? formatCurrency(data.bookedAmount)
									: formatCurrency(
										data.services
											.filter(filterSearchTerm)
											.filter((_service: any) => _service.serviceName !== "Picas Report")
											.reduce(
												(acc: any, item: any) => (acc += item.bookedAmount),
												0
											)
									)
							}
							sortable
						></Column>}
						{/* {(pageName === PAGES.PICAS || pageName === PAGES.WORKPLACE_103) && <Column
							header="Picas"
							field="picasAmount"
							body={(data) =>
								isAdmin || isCostController ||
									(data.userResponsible.toLowerCase() ===
										currentUser?.ntid.toLocaleLowerCase() &&
										searchTerm.length === 0)
									? formatCurrency(data.picasAmount)
									: ''
							}
							sortable
						></Column>} */}
						{(pageName === PAGES.PICAS || pageName === PAGES.WORKPLACE_103) && <Column
							header="Workplace"
							field="newPicasAmount"
							body={(data) =>
								isAdmin || isCostController ||
									(data.userResponsible.toLowerCase() ===
										currentUser?.ntid.toLocaleLowerCase() &&
										searchTerm.length === 0)
									? formatCurrency(data.newPicasAmount)
									: ''
							}
							sortable
						></Column>}
						{(pageName === PAGES.ITSERVICES || pageName === PAGES.ITSERVICES_103) && <Column
							header="Planned"
							field="plannedAmount"
							body={(data) =>
								data.services.filter(filterSearchTerm).length === 0
									? formatCurrency(data.plannedAmount)
									: formatCurrency(
										data.services
											.filter(filterSearchTerm)
											.reduce(
												(acc: any, item: any) => (acc += item.plannedAmount),
												0
											)
									)
							}
							sortable
						></Column>}
					</DataTable>
				</div>
				)
			)}
			<ScrollTop />
			{allModals.picasDetails === true && <PicasDialog />}
		</div>
	);
};

export default ActualsPage;

import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import {
	getCommonModal,
	setCommonModal,
	setSettingsModal,
} from '../../store/commonSlice';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useGetMappingUpdatesQuery } from '../../store/settingApi';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.min.css';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import DisplayIcon from '../icon';
import { Divider } from 'primereact/divider';

import { useUpdateProfileImageMutation } from '../../store/UserApi';
import CustomToast from '../toaster/toaster';
import { toast } from 'react-toastify';
const ImageUpdater = ({
	currentImage,
	id,
}: {
	currentImage: string;
	id: number;
}) => {
	const [
		updateImage,
		{
			isLoading: isUpdateLoading,
			isError: isUpdateError,
			isSuccess: isUpdateSuccess,
			error: updateError,
		},
	] = useUpdateProfileImageMutation();
	const [apiMessage, setApiMessage] = useState({ summary: '', message: '' });
	const [image, setImage] = useState<any>();
	const [file, setFile] = useState();
	const [sourceImg, setSourceImg] = useState('');
	const dispatch = useDispatch();
	const commonModal = useSelector(getCommonModal);
	useEffect(() => {
		setSourceImg(currentImage);
	}, []);

	const newImage = (e: any) => {
		const file = e.target.files[0];
		setFile(file.name);

		if (!file.type.includes('image/')) {
			console.log('Please select an image file');

			return;
		}

		if (typeof FileReader === 'function') {
			const reader = new FileReader();
			reader.onload = (e) => {
				if (e !== null && e.target !== null) {
					setSourceImg(e.target.result as string);
					image.replace(e.target.result as string);
				}
			};
			reader.readAsDataURL(file);
		} else {
			console.log('Sorry, FileReader API not supported');
		}
	};
	const uploadImage = async (blob: any, rawData: any) => {
		const payload = {
			rawData,
			name: file || 'xxx.jpg',
			size: 0,
			previousFileName: currentImage,
			id,
		};

		await updateImage(payload)
			.unwrap()
			.then((payload) => toast.success('Profile Image Updated Successfully !'))
			.catch((e: any) => toast.error('Error Updating Profile Image !!!'));
		dispatch(setCommonModal({ imageUpdater: false }));
		dispatch(setSettingsModal({ editUser: false }));
		setSourceImg('');
	};
	const get = () => {
		setSourceImg(
			image
				.getCroppedCanvas({
					width: 250,
					height: 250,
					imageSmoothingEnabled: true,
					imageSmoothingQuality: 'medium',
				})
				.toDataURL()
		);
		if (image.toBlob) {
			image.toBlob((blob: any) => uploadImage(blob, sourceImg));
		} else if (image.msToBlob) {
			uploadImage(image.msToBlob(), sourceImg);
		} else {
			uploadImage(
				undefined,
				image
					.getCroppedCanvas({
						width: 250,
						height: 250,
						imageSmoothingEnabled: true,
						imageSmoothingQuality: 'medium',
					})
					.toDataURL()
			);
		}
	};
	const cropper = useCallback((node) => {
		if (node != null) {
			setImage(
				new Cropper(node, {
					aspectRatio: 1 / 1,
					viewMode: 2,
					dragMode: 'crop',
					autoCropArea: 1.0,
					minContainerHeight: 250,
					minContainerWidth: 250,
					background: true,
					rotatable: false,
					zoomOnWheel: false,
				})
			);
		} else {
			console.log('node is null');
		}
	}, []);

	const renderFooter = () => {
		return (
			<div>
				<Button
					label="Cancel"
					icon="pi pi-times"
					onClick={() => {
						console.log('Image ::', sourceImg);
						dispatch(setCommonModal({ imageUpdater: false }));
						dispatch(setSettingsModal({ editUser: false }));
					}}
					className="p-button-text"
				/>
				<Button
					label="Save Changes"
					loading={isUpdateLoading}
					icon="pi pi-check"
					onClick={() => {
						get();
					}}
				/>
			</div>
		);
	};
	return (
		<>
			<Dialog
				header="Update Or Crop Image"
				visible={commonModal.imageUpdater}
				style={{ width: '400px' }}
				footer={renderFooter}
				onHide={() => {
					setImage('');
					setSourceImg('');
					dispatch(setCommonModal({ imageUpdater: false }));
					dispatch(setSettingsModal({ editUser: false }));
				}}
			>
				<input
					type="file"
					onChange={newImage}
					id="upload-image"
					style={{ visibility: 'hidden' }}
				/>
				<div className={`grid`}>
					<div className="cards">
						<img
							style={{ maxWidth: 250, maxHeight: 250 }}
							src={sourceImg}
							alt=""
							ref={cropper}
						/>
						<div className="flex justify-content-evenly my-5 ">
							<DisplayIcon
								className="rounded-button"
								icon="tablerCancel"
								onClick={async () => {
									await updateImage({
										rawData: 'null',
										name: 'xxx.jpg',
										size: 0,
										previousFileName: '',
										id,
									})
										.unwrap()
										.then((payload) =>
											toast.success('Profile Image Deleted Successfully!')
										)
										.catch((e: any) =>
											toast.error('Error Deleting Profile Image !!!')
										);
									setSourceImg('');
									dispatch(setCommonModal({ imageUpdater: false }));
									dispatch(setSettingsModal({ editUser: false }));
								}}
							/>
							<label htmlFor="upload-image">
								<DisplayIcon className="rounded-button" icon="upload" />
							</label>
						</div>
						<Divider />
					</div>
				</div>{' '}
				<CustomToast
					isSuccess={isUpdateSuccess}
					isError={isUpdateError}
					successSummary="Updated"
					successMessage="Profile Image Updated Succeessfully"
					errorMessage={JSON.stringify(updateError)}
				/>
			</Dialog>
		</>
	);
};

export default ImageUpdater;

import { Checkbox } from 'primereact/checkbox';
import { Message } from 'primereact/message';
import { Skeleton } from 'primereact/skeleton';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import DisplayIcon from '../../components/icon';
import useGetOU from '../../hooks/useGetOU';
import { setServicesToUpdate } from '../../store/commonSlice';
import { useGetAllMonthlyServiceDataMutation } from '../../store/servicesApi';
import { IService, IServiceDetails, IServiceMonthlyData } from '../../types/service.interfaces';
import { checkAzureService } from '../../utils/utility-function';

const DisplayReassignMonths = ({
	serviceDetail,
	removeService
	
}: {
	serviceDetail: IService;
	removeService: (value: any) => any
	
}) => {
	const [getServiceData, { isLoading: isFetchingServiceData }] =
		useGetAllMonthlyServiceDataMutation();
	const params = useParams();
	const [selectedCategories, setSelectedCategories] = useState<any>([]);
const dispatch = useDispatch();
	const onCategoryChange = (e: { value: any; checked: boolean }) => {
		console.log(":: e ::", e)
		let _selectedCategories = [...selectedCategories];

		if (e.checked) {
			_selectedCategories.push(e.value);
		} else {
			for (let i = 0; i < _selectedCategories.length; i++) {
				const selectedCategory = _selectedCategories[i];

				if (selectedCategory.serviceMonth === e.value.serviceMonth) {
					_selectedCategories.splice(i, 1);
					break;
				}
			}
		}

		setSelectedCategories(_selectedCategories);
		console.log("Selected Categories :: ", {key: monthlyDetail, value: _selectedCategories})
		
		dispatch(setServicesToUpdate({key: monthlyDetail, value: _selectedCategories}))
		
	};
	const [monthlyDetail, setMonthlyDetails] = useState<IServiceDetails>();
	const OU = useGetOU();
	useEffect(() => {
		const monthlyData = async () => {
			const result = await getServiceData({
				additionalInformation: checkAzureService(serviceDetail),
				cc_Id: Number(params.costCenterId),
				comment: 'null',
				said: serviceDetail.said,
				serviceId: serviceDetail.serviceId,
				serviceName: serviceDetail.serviceName,
				versionId: 0,
				year: 0,
				OU
			}).unwrap();
			setMonthlyDetails(result);
            setSelectedCategories(result.data.filter((_data: IServiceMonthlyData) => _data.plannedAmount !== _data.bookedAmount))
			dispatch(setServicesToUpdate({key: result, value: result.data.filter((_data : IServiceMonthlyData) => _data.plannedAmount !== _data.bookedAmount)}))
			//selectedMonths({key: result, value: result.data.filter((_data : IServiceMonthlyData) => _data.plannedAmount !== _data.bookedAmount)})
		};

		monthlyData();
        
	}, []);

	return (
		<div className='flex justify-content-between w-full align-items-baseline'>

			{isFetchingServiceData ? <Skeleton height="2rem" width="5rem" /> :  monthlyDetail?.data.map((category) => (
				<div key={category.month} className=" flex align-items-center mr-4">
					<Checkbox
						inputId={category.month}
						name="months"
						key={category.month}
						value={category}
						onChange={onCategoryChange}
						checked={selectedCategories.some(
							(item: any) => item.month === category.month
						)}
						disabled={category.bookedAmount === category.plannedAmount}
						tooltip={
							category.bookedAmount === category.plannedAmount
								? 'Booked services cannot be re-assigned'
								: ''
						}
						tooltipOptions={{ position: 'top' }}
					/>
					<label className='ml-2' htmlFor={category.month}>{category.month}</label>
				</div>
			))}
            {monthlyDetail?.data.filter(
								(item) => item.bookedAmount !== item.plannedAmount
							).length === 0 ? 
								<Message severity="warn" text="All Services Are Booked" className='message-info'/> : null }

								<DisplayIcon icon="tablerTrash" className='action-icon' onClick={() =>{
									dispatch(setServicesToUpdate({key: "REMOVE", value:monthlyDetail}))
									removeService(monthlyDetail)
									}}/>
		</div>
	);
};

export default DisplayReassignMonths;

import { useEffect, useMemo, useState } from 'react';
import DisplayIcon from '../../components/icon';

const InvalidUser: React.FC = function () {

    const openInNewTab = (url: string) => {
		window.open(url, '_blank', 'noreferrer');
	};

    return (
        <>
            {
                <div className='invalid-wrap'>
                    <div className='flex flex-column  justify-content-center'>
                        <DisplayIcon icon="error" className="invalid-icon flex justify-content-center" />
                        <h1 className='invalid-text flex justify-content-center'>You do not have valid role to access this page</h1>
                        <h1 className='flex justify-content-center'>Please request a vaild role (XCharge User) in&nbsp;<span className='can-mail-user' 
                            onClick={() => openInNewTab('https://alice.mercedes-benz.com/access')}>Alice</span>&nbsp;tool
                        </h1>
                        <h1 className='flex justify-content-center'>For further queries, please contact&nbsp;
                            <a href={"mailto:xcharge@mercedes-benz.com"}>xcharge@mercedes-benz.com</a>
                        </h1>
                    </div>
                </div>
            }
        </>
    )
}

export default InvalidUser;
import { baseApi } from './baseApi';
import { ISearchResult, ISearchRequest } from '../types/common.interface';
import { request } from 'http';

const commonApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		useSearch: builder.mutation<ISearchResult[], ISearchRequest>({
			query: ({ search, versionId }) => ({
				url: `actuals/search`,
				method: 'POST',
				body: { search, versionId },
			}),
		}),
		useArchiveSearch: builder.mutation<ISearchResult[], ISearchRequest>({
			query: ({ search, versionId }) => ({
				url: `archive/search`,
				method: 'POST',
				body: { search, versionId },
			}),
		}),
		AddClusteringKeyword: builder.mutation<any, string>({
			query: (keyword) => ({
				url: `actuals/saveclusterkeywords?keyWord=${keyword}`,
				method: 'POST',
			}),
		}),
		DeleteClusteringKeyword: builder.mutation<any, number>({
			query: (id) => ({
				url: `actuals/deleteclusterkeywords/${id}`,
				method: 'DELETE',
			}),
		}),
		getAllCountries: builder.query<any, void>({
			query: () => `/country/all`,
		}),
		getAllRegions: builder.query<any, void>({
			query: () => `/region/all`,
		}),
		getAllDepartments: builder.query<any, void>({
			query: () => `/department/all`,
		}),
		getMaintenanceMode: builder.query<any, void>({
			query: () => ({
				url: `deploy/config`,
				method: 'GET',
				responseHandler: (response) => response.text(),
			}),
			providesTags: ['Maintenance'],
		}),
	}),
});

export const {
	useGetAllCountriesQuery,
	useUseSearchMutation,
	useUseArchiveSearchMutation,
	useAddClusteringKeywordMutation,
	useDeleteClusteringKeywordMutation,
	useGetAllRegionsQuery,
	useGetAllDepartmentsQuery,
	useGetMaintenanceModeQuery,
} = commonApi;

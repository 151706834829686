import { usePopper } from 'react-popper';
import { useEffect, useMemo, useRef, useState } from 'react';

const PopOver = (props: any) => {
	const wrapperRef = useRef<HTMLDivElement>(null);
	const [referenceElement, setReferenceElement] =
		useState<HTMLDivElement | null>(null);
	const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
		null
	);
	const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
	const { styles, attributes } = usePopper(referenceElement, popperElement, {
		modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
	});
	useEffect(() => {
		function handleClickOutside(event: any) {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				props.callback();
			}
		}
		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [wrapperRef]);
	return (
		<div ref={wrapperRef} className="z-1">
			<div ref={setReferenceElement}>{props.icon}</div>

			<div
				ref={setPopperElement}
				style={styles.popper}
				{...attributes.popper}
				className="service-info p-0"
			>
				{props.children()}
				<div ref={setArrowElement} style={styles.arrow} />
			</div>
		</div>
	);
};

export default PopOver;

import { useEffect, useMemo, useState } from 'react';
import CostCenterCard from '../../components/cost-center-card';
import ExtraActions from '../../components/extra-actions';
import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { Column } from 'primereact/column';

import ReactTooltip from 'react-tooltip';
import '../../views/actuals/styles.scss';
import { useGetAllMappingQuery } from '../../store/settingApi';
import AddMappingModal from './add-mapping-modal';
import { useDispatch, useSelector } from 'react-redux';
import {
	getIsAdmin,
	getIsCostController,
	getIsServiceOwner,
	getSearchTerm,
	setSettingsModal,
	settingsModalState,
} from '../../store/commonSlice';
import DisplayIcon from '../../components/icon';
import EditMappingModal from './edit-mapping-modal';

import useDownloadFile from '../../hooks/useDownloadFile';
import EditClusteringKeywords from './clustering-keywords';
import { Button } from 'primereact/button';

export interface IMappingData {
	id: number;
	said: string;
	serviceId: string;
	serviceName: string;
	aditionalInfo: string;
	costCenter_FK: number;
	targetCostCenter: string;
	targetResponsible: string;
	serviceYear: number;
	serviceMonth: number;
	amount: number;
	updatedBy: {
		email: string;
		firstName: string;
		id: number;
		identifer: number;
		lastName: string;
		isApproval: boolean;
	};
	updatedOn: string;
	keyWord: string;
	comments: string;
	matching: string;
	icaVersionId_FK: number;
	ccValid_To: string;
	sICAExport: boolean;
	ccValid_From: string;
	isICAUpdate: boolean;
	isClarified: boolean;
	isCcUpdate: boolean;
	isHistory: boolean;
	isILVComment: boolean;
	ilvCmtBy: number;
	ilvCmtOn: string;
}

const MappingPage: React.FC = function () {
	const {
		data = [],
		isLoading,
		isFetching,
		isSuccess,
		isError,
		error,
	} = useGetAllMappingQuery();
	const dispatch = useDispatch();
	const [selectedRow, setSelectedRow] = useState();
	const openModal = useSelector(settingsModalState);
	const searchTerm = useSelector(getSearchTerm);
	const [searchKeyword, setSearchKeyword] = useState('');
	useEffect(() => {
		const apiCallTimeoutId = setTimeout(() => {
			if (searchTerm !== '') {
				setSearchKeyword(searchTerm);
			} else {
				setSearchKeyword('');
			}
		}, 1000);
		return () => clearTimeout(apiCallTimeoutId);
	}, [searchTerm]);
	useEffect(() => {
		document.title = 'Mapping | XCharge';
	}, []);

	const computedData = useMemo(() => {
		let result = data;

		result =
			searchKeyword !== '' && data
				? data.filter((items: any) => {
						console.log(Object.values(items));
						return (
							Object.values(items).findIndex((chunk: any) =>
								chunk
									.toString()
									.toLowerCase()
									.includes(searchKeyword.toLowerCase())
							) !== -1
						);
				  })
				: data;

		return result;
	}, [data, searchKeyword]);
	function onRowclick(data: any) {
		console.log(data);
	}
	const isAdmin = useSelector(getIsAdmin);
	const isServiceOwner = useSelector(getIsServiceOwner);

	const displayStatus = (rowData: any) => {
		return rowData.matching === 'Perfect' && rowData.isClarified === false ? (
			<Button
				className="p-button-rounded p-button-text p-button-icon-only"
				aria-label="Cancel"
				tooltipOptions={{ position: 'left' }}
				tooltip="Already Mapped"
			>
				<DisplayIcon icon="tablerCheck" className="button-icon" />
			</Button>
		) : // <DisplayIcon
		// 	icon="tablerCheck"
		// 	className="table-action"
		// 	data-tip="Already Mapped"
		// 	data-for="mapping"
		// />
		rowData.matching === 'Perfect' && rowData.isClarified === true ? (
			<DisplayIcon icon="tablerWarning" />
		) : null;
	};

	const actions = (rowData: any) => {
		return (
			<div className="table-menu">
				<Button
					className="p-button-rounded p-button-text p-button-icon-only"
					tooltipOptions={{ position: 'left' }}
					tooltip={`${
						rowData.comments.length === 0 ? 'No Comments' : rowData.comments
					}`}
				>
					<DisplayIcon
						icon="tablerMessage"
						className={`button-icon ${
							rowData.comments.length !== 0 ? 'table-action__highlight' : ''
						}`}
					/>{' '}
				</Button>
				{/* <DisplayIcon
					icon="tablerMessage"
					className={`table-action ${
						rowData.comments.length !== 0 ? 'table-action__highlight' : ''
					}`}
					data-tip={`${
						rowData.comments.length === 0 ? 'No Comments' : rowData.comments
					}`}
					data-for="mapping"
				/> */}

				<Button
					className="p-button-rounded p-button-text p-button-icon-only"
					tooltip="Edit Mapping"
					tooltipOptions={{ position: 'left' }}
					onClick={() => {
						setSelectedRow(rowData);
						dispatch(setSettingsModal({ editMapping: true }));
					}}
				>
					<DisplayIcon icon="tablerPencil" className="button-icon" />
				</Button>

				{/* <DisplayIcon
					icon="tablerPencil"
					className="table-action"
					data-tip="Edit Mapping"
					data-for="mapping"
					onClick={() => {
						setSelectedRow(rowData);
						dispatch(setSettingsModal({ editMapping: true }));
					}}
				/> */}
			</div>
		);
	};
	const [first, setFirst] = useState(0);
	
	const isCostController = useSelector(getIsCostController);

	return (
		<div className={`grid`}>
			<div className="cards">
				<DataTable
					tableClassName='settings-mapping'
					value={computedData}
					onRowClick={(event) => onRowclick(event)}
					header={MappingHeader}
					paginator
					rows={25}
					first={first}
					onPage={(e) => {
						setFirst(e.first);
					}}
					loading={isFetching}
				>
					<Column
						field="said"
						header="SAID"
						alignHeader="left"
						sortable
					></Column>
					<Column
						field="serviceId"
						header="ID"
						alignHeader="left"
						sortable
					></Column>

					<Column
						field="serviceName"
						header="Service Name"
						alignHeader="left"
						sortable
					></Column>
					<Column
						field="targetCostCenter"
						header="Cost Center"
						sortable
						alignHeader="left"
					></Column>
					<Column
						field="targetResponsible"
						header="Responsible"
						alignHeader="left"
						sortable
					></Column>
					<Column
						field="aditionalInfo"
						header="Additional Information"
						alignHeader="left"
						sortable
					></Column>
					<Column
						header="Status"
						alignHeader="center"
						body={displayStatus}
					></Column>
					{isAdmin || isCostController || isServiceOwner && <Column body={actions} align="center"></Column>}
				</DataTable>
			</div>
			{selectedRow && openModal.editMapping && (
				<EditMappingModal mappingData={selectedRow} />
			)}
		</div>
	);
};

const MappingHeader = ({ title }: { title: string }) => {
	const isAdmin = useSelector(getIsAdmin);
	const dispatch = useDispatch();
	const { downloadFile } = useDownloadFile();
	return (
		<>
			<div className="flex flex-column justify-content-between align-items-center">
				<div className="flex justify-content-between w-full align-items-center">
					<div className="flex align-items-baseline w-full">
						<h5>{title ? title : 'Mapping'}</h5>
					</div>
					<div className="actions flex align-items-center justify-content-between mr-4"></div>

					<div className="flex align-items-center justify-content-end">
						<div className="flex flex-columns">
							<div className="flex">
								<div className="actions flex ml-4">
									{isAdmin && (
										<Button
											icon="pi pi-filter"
											className="p-button-rounded p-button-text p-button-plain p-button-icon-only"
											tooltip="Add New Mapping"
											onClick={() => {
												dispatch(setSettingsModal({ addMapping: true }));
											}}
										>
											<DisplayIcon icon="add" className="action-icon" />
										</Button>
									)}

									<Button
										icon="pi pi-filter"
										className="p-button-rounded p-button-text p-button-plain p-button-icon-only"
										tooltip="Open Mapping Difference Window"
										onClick={() => {
											console.log('PROCESS :: ', process.env);
											window.open(
												`${
													process.env.REACT_APP_ENV === 'staging'
														? '/web/XCharge'
														: '#'
												}/settings/mapping/mappingDifference`,
												'_blank'
											);
										}}
									>
										<DisplayIcon icon="tablerInfo" className="action-icon" />
									</Button>
									{isAdmin && (
										<Button
											icon="pi pi-filter"
											className="p-button-rounded p-button-text p-button-plain p-button-icon-only"
											tooltip="Add/Edit Clustering Keywords"
											tooltipOptions={{ position: 'top' }}
											onClick={() =>
												dispatch(setSettingsModal({ clusteringKeywords: true }))
											}
										>
											<DisplayIcon
												icon="tablerAdjustment"
												className="action-icon"
											/>
										</Button>
									)}
									<Button
										className="p-button-rounded p-button-text p-button-icon-only"
										tooltip="Export Mapping Report as Excel Sheet"
										tooltipOptions={{ position: 'left' }}
										onClick={() => {
											downloadFile(
												'serviceDetails/exportmappedservice',
												'Mapping-Report_' + new Date().toISOString() + '.xlsx'
											);
										}}
									>
										<DisplayIcon icon="excel" className="excel-icon__small" />
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<AddMappingModal />
			<EditClusteringKeywords />
		</>
	);
};

export default MappingPage;
export { MappingHeader };

import { useCallback } from 'react';

const useFileCreator = () => {
	const createFile = useCallback(async (fileName: string, data?: any) => {
		const fileData = new Blob([data], {
			type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		});
		let link: any = document.createElement('a');
		link.href = window.URL.createObjectURL(fileData);
		//const appendVersion = versionId != undefined ? `_${versionId}` : '';
		link.download = fileName;
		link.click();
	}, []);

	return { createFile };
};

export default useFileCreator;

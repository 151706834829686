import { Dialog } from 'primereact/dialog';
import {
    getServiceOptions,
    setServiceOptions,
} from '../../store/commonSlice';
import { Chart } from 'primereact/chart';

import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';


const BaseFeeDialog = () => {

    const dispatch = useDispatch();
    const showModal = useSelector(getServiceOptions).showBaseFeeDialog;

    const [pieChartOptions] = useState({
        // maintainAspectRatio: false,
        // aspectRatio: 1,
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: 'Overview: Base Fee Costs',
                font: {
                    size: 20
                }
            },
            legend: {
                position: 'bottom',
                labels: {
                    color: '#495057'
                }
            }
        }
    });

    const [pieChartData] = useState({
        labels: [
            'Licences', 
            'ITT-BA', 
            'SharePoint', 
            'BeMA', 
            'Network', 
            // 'Printer', 
            'RWP', 
            'Infosys', 
            'HR Services'
        ],
        datasets: [
            {
                data: [
                    3.87, 
                    68.84, 
                    11.60, 
                    23.78, 
                    48.99, 
                    // 1.15, 
                    14.23, 
                    20.30, 
                    8.58
                ],
                backgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56",
                    "#66BB6A",
                    "#26C6DA",
                    // "#8c3c8c",
                    "#F97B22",
                    "#17113f",
                    "#848486",
                ],
                hoverBackgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56",
                    "#66BB6A",
                    "#26C6DA",
                    // "#8c3c8c",
                    "#F97B22",
                    "#17113f",
                    "#848486",
                ]
            }]
    });

    const baseFeeData = [
        { category: 'Licences', total: '3,87 €' },
        { category: 'ITT-BA', total: '68,84 €' },
        { category: 'SharePoint', total: '11,60 €' },
        { category: 'BeMA', total: '23,78 €' },
        { category: 'Network', total: '48,99 €' },
        // { category: 'Printer', total: '1,15 €' },
        { category: 'RWP', total: '14,23 €' },
        { category: 'Infosys', total: '20,30 €' },
        { category: 'HR Services', total: '8,58 €' },
    ]

    return (
        <Dialog
            header="Base Fee Costs"
            visible={showModal}
            style={{ width: '60vw' }}
            onHide={() => dispatch(setServiceOptions({ showBaseFeeDialog: false }))}
        // footer={renderFooter2}
        >
            <div className='col-12'>
                <div className="my-3 col-6" style={{float: 'left'}}>
                    <DataTable
                        value={baseFeeData}
                        responsiveLayout="scroll"
                        rowHover
                        onClick={(e) => e.stopPropagation()}
                    >
                        <Column field="category" header="Category"></Column>
                        <Column field="total" header="Total"></Column>
                    </DataTable>
                </div>

                <div className="col-6" style={{float: 'right'}}>
                    <div className='m-4'>
                        <Chart id="baseFeeChartId" type="pie" data={pieChartData} options={pieChartOptions} />
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default BaseFeeDialog;
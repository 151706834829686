import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { AutoComplete } from 'primereact/autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { commonState, getMailers, setMailers } from '../../store/commonSlice';
import { useGetAllUsersQuery } from '../../store/UserApi';
import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InputField from '../input-field/input-field';
import Mailer from './mailer';
import {
	useMailICAReportMutation,
	useMailPicasICAReportMutation,
	useMailNewPicasICAReportMutation,
	useMailResponsibleMutation,
} from '../../store/mailApi';
import { IMailer } from '../../types/common.interface';
import { toast } from 'react-toastify';

const MailICAReport = ({
	versionId,
	target,
}: {
	versionId: number;
	target: 'ICA' | 'PICAS' | 'NEWPICAS' | '';
}) => {
	const dispatch = useDispatch();
	const mailers = useSelector(getMailers);
	const [mailerData, setMailerData] = useState<IMailer>();
	const { data, isLoading, isSuccess, isError, error } = useGetAllUsersQuery();
	const [formData, setFormData] = useState<any>({});
	const [mailICAReport, { isLoading: isMailingICA }] =
		useMailICAReportMutation();
	const [mailPicasICAReport, { isLoading: isMailingPicas }] =
		useMailPicasICAReportMutation();
	const [mailNewPicasICAReport, { isLoading: isMailingNewPicas }] =
		useMailNewPicasICAReportMutation();

	const footer = () => (
		<div>
			<Button
				label="Cancel"
				icon="pi pi-times"
				onClick={() => dispatch(setMailers({ icaReport: false }))}
			/>
			<Button
				label="Send"
				icon="pi pi-check"
				loading={isMailingPicas || isMailingICA || isMailingNewPicas}
				disabled={
					mailerData?.emailIds.length === 0 ||
					mailerData?.subject.length === 0 ||
					mailerData?.message.length === 0
				}
				onClick={async () => {
					if (mailerData) {
						console.log('TARGET :: ', target);
						if (target === 'ICA') {
							console.log('HERE');
							await mailICAReport({ ...mailerData, versionId: versionId })
								.unwrap()
								.then((payoad: any) => toast.success('Mail Sent Successfully'))
								.catch((e: any) => {
									toast.error('Error Sending Mail');
								});
						} else if (target === 'PICAS') {
							console.log('NOT HERE');
							await mailPicasICAReport({ ...mailerData, versionId: versionId })
								.unwrap()
								.then((payoad: any) => toast.success('Mail Sent Successfully'))
								.catch((e: any) => {
									toast.error('Error Sending Mail');
								});
						} else if (target === 'NEWPICAS') {
							console.log('NOT HERE');
							await mailNewPicasICAReport({ ...mailerData, versionId: versionId })
								.unwrap()
								.then((payoad: any) => toast.success('Mail Sent Successfully'))
								.catch((e: any) => {
									toast.error('Error Sending Mail');
								});
						}
					}
					dispatch(setMailers({ icaReport: false }));
				}}
			/>
		</div>
	);
	const mailerCallback = (data: any) => {
		setMailerData(data);
	};

	return (
		<Dialog
			header="Email Cost Allocation"
			visible={mailers.icaReport}
			style={{ width: '75vw' }}
			footer={footer}
			onHide={() => dispatch(setMailers({ icaReport: false }))}
		>
			<Mailer callback={mailerCallback} />
		</Dialog>
	);
};

export default MailICAReport;

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	useLocation,
	useParams,
	useNavigate,
	useResolvedPath,
} from 'react-router-dom';
import { useGetCurrentUserQuery } from '../../store/UserApi';
import { useGetMaintenanceModeQuery } from '../../store/commonApi';
import { getIsAdmin, setSearchTerm } from '../../store/commonSlice';
import PAGES from '../../utils/pages.enum';

const PathDebugger = () => {
	const { data: currentUser, isLoading: isLoadingUser } =
		useGetCurrentUserQuery(0);

	const _location = useLocation();
	const state: any = _location.state;
	const history = useNavigate();
	const param = useParams();
	const dispatch = useDispatch();
	const isAdmin = useSelector(getIsAdmin);

	const { data: maintenanceMode } = useGetMaintenanceModeQuery();
	const navigate = useNavigate();
	useEffect(() => {

		if (
			maintenanceMode === 'true' &&
			(currentUser?.role.isAdmin === true ||
				currentUser?.role.isSuperAdmin === true)
		) {
		} else if (
			maintenanceMode === 'true' &&
			currentUser?.role.isUserRole &&
			!_location.pathname.includes('maintenance')
		) {
			// eslint-disable-next-line no-restricted-globals
			navigate('maintenance');
		} else if (
			_location.pathname.includes('maintenance') &&
			maintenanceMode === 'false'
		) {
			navigate('actuals');
		}
	});

	useEffect(() => {
		if (state && (('prevPath' in state) as any)) {
			if (
				!state.prevPath.includes('/actuals') ||
				(state.prevPath.includes('/actuals') &&
					!_location.pathname.includes('actuals'))
			) {
				dispatch(setSearchTerm(''));
			}
		}
	}, [_location]);

	return (
		<>
			{process.env.REACT_APP_ENV !== 'development' ? null : (
				<h4>{JSON.stringify(_location) + JSON.stringify(history)}</h4>
			)}
		</>
	);
};

export default PathDebugger;

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';

import CustomAutoComplete from '../../components/autocomplete/autocomplete';
import { useCallback, useEffect, useState } from 'react';
import {
	getEditUserData,
	setCommonModal,
	setCostCenterFilter,
	setSettingsModal,
	settingsModalState,
} from '../../store/commonSlice';
import { useGetAllCostCentersQuery } from '../../store/apiSlice';
import { Skeleton } from 'primereact/skeleton';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { classNames } from 'primereact/utils';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import InputField from '../../components/input-field/input-field';
import DisplayIcon from '../../components/icon';
import { Dropdown } from 'primereact/dropdown';
import {
	useGetAllRolesQuery,
	useUpdateUsersMutation,
} from '../../store/UserApi';
import { formatDateDDMMYYYYhhmm } from '../../utils/utility-function';
import CustomToast from '../../components/toaster/toaster';
import { toast } from 'react-toastify';
import useGetOU from '../../hooks/useGetOU';

const userSchema = Yup.object().shape({
	lastName: Yup.string().required('Required !'),
	firstName: Yup.string().required('Required !'),
	email: Yup.string().email().required('Required !'),
	plant: Yup.string().optional(),
	ntid: Yup.string().optional(),
	department: Yup.string().optional(),
	departmentDescription: Yup.string().optional(),
	country: Yup.string().optional(),
});

const EditUserDialog = ({ selectedRow }: { selectedRow: any }) => {

	const OU = useGetOU();
	const [
		updateUser,
		{
			isLoading: isUpdating,
			isSuccess: isUpdateSuccess,
			isError: isUpdateFailed,
			error: updateError,
		},
	] = useUpdateUsersMutation();
	const editModal = useSelector(settingsModalState);
	const dispatch = useDispatch();
	const [userData, setUserData] = useState(selectedRow);
	const [costCenters, setCostCenters] = useState<any>(
		selectedRow.costCenters ? [...selectedRow.costCenters] : []
	);

	const [formData, setFormData] = useState<any>({});
	const {
		data: allList = [],
		isLoading: isListLoading,
		isSuccess: isListSuccess,
		isError: isListError,
		error: listError,
	} = useGetAllCostCentersQuery({OU});

	const { data: allRoles = [] } = useGetAllRolesQuery();
	const itemTemplate = (item: any) => {
		return (
			<div className="country-item">
				<div>
					{item.name} | {item.target_costcenter}
				</div>
			</div>
		);
	};

	const filterCostCenter = (x: any, rowData: any) => {
		if (x !== undefined) {
			let _costCenters;

			_costCenters = [
				...costCenters.filter((item: any) => item.id !== rowData.id),
				...allList.filter((item: any) => item.id === x.id),
			];

			setCostCenters([..._costCenters]);
		}
	};

	const dropDown = (rowData: any) => {
		console.log('ROW DATA :: ', rowData, rowData.id);

		const iList = [
			...allList
				.filter((item) => {
					return costCenters.findIndex((ud: any) => ud.id === item.id) === -1;
				})
				.filter((item) => item.isfunctional && item.isvisible)
				.map((item) => ({
					name: item.name,
					target_costcenter: item.target_costcenter,
					id: item.id,
				})),
		];

		return isListLoading ? (
			<Skeleton height="2rem" className="mb-2"></Skeleton>
		) : (
			<CustomAutoComplete
				customId={`dt-${rowData.id}`}
				placeholder="Cost Center"
				itemTemplate={itemTemplate}
				inputList={iList}
				field="name"
				target="name"
				callback={(e) => filterCostCenter(e, rowData)}
				defaultValue={
					rowData.id === 'temp-row'
						? undefined
						: {
								name: rowData.name,
								target_costcenter: allList.find(
									(item) => item.id === rowData.id
								)?.target_costcenter,
								id: rowData.id,
						  }
				}
			/>
		);
	};

	const active = (rowData: any) => {
		return (
			<InputSwitch
				id={`active-${rowData.id}`}
				checked={rowData.isReadOnly}
				onChange={(e) => {
					setCostCenters([
						...costCenters.map((item: any) => {
							if (item.id === rowData.id) {
								return { ...item, isReadOnly: e.target.value };
							}
							return item;
						}),
					]);
				}}
			/>
		);
	};

	const trash = (rowData: any) => {
		return (
			<DisplayIcon
				icon="tablerTrash"
				className="action-icon"
				onClick={() => {
					console.log('removing id ::', rowData, rowData.id);
					setCostCenters([
						...costCenters.filter((_item: any) => _item.id !== rowData.id),
					]);
				}}
			/>
		);
	};

	const formik = useFormik({
		initialValues: {
			firstName: selectedRow.firstName,
			lastName: selectedRow.lastName,
			email: selectedRow.email,
			plant: selectedRow.plant,
			ntid: selectedRow.ntid,
			department: selectedRow.departmentNew,
			departmentDescription: selectedRow.departmentDescription,
			country: selectedRow.countryNew,
			userRole: selectedRow.role,
		},

		onSubmit: (data) => {
			setFormData(data);
			formik.resetForm();
		},
	});

	const renderFooter = (name: any) => {
		return (
			<div>
				<Button
					label="Cancel"
					icon="pi pi-times"
					onClick={() => dispatch(setSettingsModal({ editUser: false }))}
					className="p-button-text"
				/>
				<Button
					label="Save"
					icon="pi pi-check"
					loading={isUpdating}
					onClick={async () => {
						const payload = {
							...formik.values,
							id: selectedRow.id,
							roleId: formik.values.userRole.id,
							costCenters: costCenters
								.filter((_e: any) => _e.id !== 'temp-row')
								.map((_e: any) => ({
									id: _e.id,
									isReadOnly: _e.isReadOnly ? _e.isReadOnly : false,
								})),
							active: selectedRow.active,
							ntId: formik.values.ntid,
						};
						console.log('formik ::', {
							...formik.values,
							id: selectedRow.id,
							roleId: formik.values.userRole.id,
							costCenters: costCenters
								.filter((_e: any) => _e.id !== 'temp-row')
								.map((_e: any) => ({
									id: _e.id,
									isReadOnly: _e.isReadOnly ? _e.isReadOnly : false,
								})),
							active: selectedRow.active,
							ntId: formik.values.ntid,
						});
						await updateUser(payload)
							.unwrap()
							.then((payload: any) =>
								toast.success('User Updated Successfully')
							)
							.catch((error: any) => toast.error(error.data.message));

						dispatch(setSettingsModal({ editUser: false }));
					}}
				/>
			</div>
		);
	};

	type f = keyof typeof formik.values;
	const isFormFieldValid = (name: f) =>
		!!(formik.touched[name] && formik.errors[name]);
	const getFormErrorMessage = (name: f) => {
		return (
			isFormFieldValid(name) && (
				<small className="p-error">{formik.errors[name]}</small>
			)
		);
	};
	return (
		<>
			<Dialog
				header={selectedRow?.lastName + ', ' + selectedRow?.firstName}
				visible={editModal.editUser}
				style={{ width: 'auto' }}
				footer={renderFooter('displayBasic2')}
				onHide={() => {
					formik.resetForm();
					setCostCenters([]);
					dispatch(setSettingsModal({ editUser: false }));
				}}
			>
				<div className="p-fluid grid mt-8">
					<div className="grid align-items-center justify-content-between col-4">
						<div className="w-full flex align-items-center justify-content-center">
							<div
								className="image-container"
								onClick={() => dispatch(setCommonModal({ imageUpdater: true }))}
							>
								<img className="image-circle" src={selectedRow.photo} alt="" />
								<div className="image-hover-option">
									{' '}
									<DisplayIcon icon="tablerEdit" className="reposition-icon" />
								</div>
							</div>
						</div>
					</div>
					<div className="grid col-8">
						<InputField
							field="email"
							formik={formik}
							label="Email"
							colSpan={12}
							disabled={isUpdating}
						/>
						<InputField
							field="firstName"
							formik={formik}
							label="First Name"
							colSpan={6}
							disabled={isUpdating}
						/>
						<InputField
							field="lastName"
							formik={formik}
							label="Last Name"
							colSpan={6}
							disabled={isUpdating}
						/>
						{/* <div className="field col-6 mt-3">
							<span className="p-float-label">
								<Dropdown
									value={formik.values.userRole}
									options={allRoles}
									onChange={formik.handleChange}
									optionLabel="name"
									id="userRole"
									name="userRole"
									disabled={isUpdating}
								/>
								<label htmlFor="useRole">User Role</label>
							</span>
						</div>
						<InputField
							field="requestedBy"
							formik={formik}
							label="Requested By"
							colSpan={6}
							disabled={isUpdating}
						/> */}
					</div>
				</div>
				<div className="p-fluid grid mt-8">
					<InputField
						field="plant"
						formik={formik}
						label="Plant"
						colSpan={4}
						disabled={isUpdating}
					/>
					<InputField
						field="ntid"
						formik={formik}
						label="Short ID"
						colSpan={4}
						disabled={isUpdating}
					/>
					<InputField
						field="country"
						formik={formik}
						label="Country"
						colSpan={4}
						disabled={isUpdating}
					/>
					<InputField
						field="department"
						formik={formik}
						label="Department"
						colSpan={4}
						disabled={isUpdating}
					/>
					<InputField
						field="departmentDescription"
						formik={formik}
						label="Department Description"
						colSpan={8}
						disabled={isUpdating}
					/>
				</div>
				<Divider />
				{costCenters && (
					<div className="w-full flex justify-content-center">
						<div className="flex align-items-end flex-column w-8">
							<DataTable value={costCenters} dataKey="id">
								<Column header="Cost Center" body={dropDown}></Column>
								<Column header="Read Only" body={active}></Column>
								<Column header="Delete" body={trash}></Column>
							</DataTable>
							<Button
								label="Add Cost Center"
								icon="pi pi-plus"
								className="p-button-sm mt-5"
								onClick={() => {
									if (
										costCenters.findIndex(
											(_item: any) => _item.id === 'temp-row'
										) === -1
									) {
										setCostCenters([...costCenters, { id: 'temp-row' }]);
									}
								}}
							/>
						</div>
					</div>
				)}

				<Divider />
				<h5>Updated</h5>
				<div className="p-fluid grid formgrid mt-5">
					<div className="grid col-12">
						<div className="field col-6 mt-3">
							<span className="p-float-label">
								<InputText
									value={
										!selectedRow?.updatedBy
											? '-'
											: selectedRow?.updatedBy.firstName +
											  ' ' +
											  selectedRow?.updatedBy.lastName +
											  ' (' +
											  selectedRow?.updatedBy.email +
											  ')'
									}
									name="updatedBy"
									disabled
								/>
								<label htmlFor="department">Updated By</label>
							</span>
						</div>

						<div className="field col-6 mt-3">
							<span className="p-float-label">
								<InputText
									value={
										selectedRow?.updatedOn
											? formatDateDDMMYYYYhhmm(selectedRow.updatedOn)
											: formatDateDDMMYYYYhhmm(new Date().toISOString())
									}
									name="updatedBy"
									disabled
								/>
								<label htmlFor="department">Updated By</label>
							</span>
						</div>
					</div>
				</div>
			</Dialog>
		</>
	);
};

export default EditUserDialog;

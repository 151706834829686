const checkAzureService = function (service: any) {
	let azureService = undefined;
	// console.log("Checking for service :: ", service)
	if (
		service.serviceName.toLocaleLowerCase().includes('gb4') &&
		service.serviceId === '20023510' &&
		Object.keys(service).indexOf('additionalInfo') === -1
	) {
		return 'null';
	}
	return service.additionalInfo;
};
const formatDateDDMMYYYYhhmm = function (date: string) {
	const convertedDate = new Date(date);
	return `${
		convertedDate.getDate() < 10 ? '0' : ''
	}${convertedDate.getDate()}.${
		convertedDate.getMonth() < 9 ? '0' : ''
	}${convertedDate.getMonth()}.${convertedDate.getFullYear()} - ${convertedDate.getHours()} : ${
		convertedDate.getMinutes() < 10 ? '0' : ''
	}${convertedDate.getMinutes()}`;
};
const formatDateDDMMYYYY = function (date?: string) {
	if (date) {
		const convertedDate = new Date(date);
		return `${
			convertedDate.getDate() < 10 ? '0' : ''
		}${convertedDate.getDate()}.${convertedDate.getMonth() < 9 ? '0' : ''}${
			convertedDate.getMonth() + 1
		}.${convertedDate.getFullYear()}`;
	} else {
		return '';
	}
};
const sortingFunction = function (array: any, field: string, order: string) {
	function sorter(a: any, b: any) {
		if (typeof a[field] === 'number') {
			// let x = typeof a[field] === 'number' ? a[field] : a[field].toLowerCase();
			// let y = typeof b[field] === 'number' ? b[field] : b[field].toLowerCase();

			if (order.toLowerCase() === 'dsc') {
				return a[field] - b[field];
			}

			if (order.toLowerCase() === 'asc') {
				return b[field] - a[field];
			}
		} else {
			let x = typeof a[field] === 'number' ? a[field] : a[field]?.toLowerCase();
			let y = typeof b[field] === 'number' ? b[field] : b[field]?.toLowerCase();

			if (order.toLowerCase() === 'dsc') {
				if (x < y) {
					return -1;
				}
				if (x > y) {
					return 1;
				}
				return 0;
			}

			if (order.toLowerCase() === 'asc') {
				if (x > y) {
					return -1;
				}
				if (x < y) {
					return 1;
				}
				return 0;
			}
		}
	}
	return array.slice().sort(sorter);
};

const formatCurrency = (value: string | number) =>
	`${Number(value).toLocaleString('es-ES', { minimumFractionDigits: 2 })} €`;
const searchHighlighter = (text?: string, searchTerm?: string) => {
	if (text) {
		if (!searchTerm) return { __html: text };
		searchTerm = searchTerm.replace(
			/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g,
			'\\$&'
		);
		//const regex = new RegExp(searchTerm, 'ig');
		const regex = new RegExp(
			'(' + searchTerm.split('\\+').join('|') + ')',
			'gi'
		);

		return {
			__html: text.replace(regex, function (str) {
				return "<span class='highlighter'>" + str + '</span>';
			}) as string,
		};
	}
	return { __html: '' };
};

const dualSearch = (data: string, searchKey: any) => {
	for (let key of searchKey) {
		if (data.includes(key)) {
			return true;
		}
	}
	return false;
};

const uniqueList = (array: any, field: any) => {
	return Array.from(new Set([...array.map((_item: any) => _item[field])])).map(
		(_key: any) => {
			return array.find((_src: any) => _src[field] === _key);
		}
	);
};

var months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

const sortByMonth = (array: any[]) =>
	array.slice().sort(function (a, b) {
		return months.indexOf(a.month) - months.indexOf(b.month);
	});

const downloadResponseAsFile = async (data: any, fileName: string) => {
	console.log('generating file to download');
	let link: any = document.createElement('a');
	link.href = window.URL.createObjectURL(data);
	//const appendVersion = versionId != undefined ? `_${versionId}` : '';
	link.download = fileName;
	link.click();
};

const getFullMonth = (date: any) => {
	return new Intl.DateTimeFormat('en-US', {month: 'long'}).format(date)
}

const getShortMonth = (date: any) => {
	return new Intl.DateTimeFormat('en-US', {month: 'short'}).format(date)
}

const formatDateDDMMMMYYYY = function (date?: string) {
	if (date) {
		const convertedDate = new Date(date);
		return `${
			convertedDate.getDate() < 10 ? '0' : ''
		}${convertedDate.getDate()}-${getShortMonth(convertedDate)
		}-${convertedDate.getFullYear()}`;
	} else {
		return '';
	}
};

export {
	sortingFunction,
	formatDateDDMMYYYYhhmm,
	formatDateDDMMYYYY,
	formatDateDDMMMMYYYY,
	checkAzureService,
	dualSearch,
	formatCurrency,
	searchHighlighter,
	uniqueList,
	sortByMonth,
	downloadResponseAsFile,
	getFullMonth
};

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setSettingsModal, settingsModalState } from "../../store/commonSlice";
import { useAddNewsAlertMutation, useEditNewsAlertMutation } from "../../store/settingApi";
import { formatDateDDMMYYYY } from "../../utils/utility-function";


const AddOrEditNewsAlerts = ({ editData }: { editData: any }) => {

    const dispatch = useDispatch();
    const serviceModal = useSelector(settingsModalState);

    const [addNewsAlertCall, { isLoading: isAddingNews }] = useAddNewsAlertMutation();
    const [editNewsAlertCall, { isLoading: isEditingNews }] = useEditNewsAlertMutation();

    const [id, setId] = useState<any>(undefined);
    const [title, setTitle] = useState<any>(undefined);
    const [description, setDescription] = useState<any>(undefined);
    const [startDate, setStartDate] = useState<any>(undefined);
    const [expiryDate, setExpiryDate] = useState<any>(undefined);

    let today = new Date();
    let month = today.getMonth();
    let year = today.getFullYear();
    let prevMonth = month === 0 ? 11 : month - 1;
    let prevYear = prevMonth === 11 ? year - 1 : year;

    let minDate = new Date();
    minDate.setMonth(month);
    minDate.setFullYear(prevYear);

    const resetFormData = () => {
        setId(undefined);
        setTitle(undefined);
        setDescription(undefined);
        setStartDate(undefined);
        setExpiryDate(undefined);
    }

    useEffect(() => {
        if (editData) {
            setId(editData.id_pk);
            setTitle(editData.title);
            setDescription(editData.description);
            setStartDate(new Date(editData.start_date));
            setExpiryDate(new Date(editData.expiry_date));
        } else {
            resetFormData();
        }
    }, [serviceModal.addOrEditNewsAlerts])

    const addNewsAlert = async () => {
        await addNewsAlertCall({
            id_pk: 0,
            title,
            description,
            start_date: startDate,
            expiry_date: expiryDate
        }).unwrap()
            .then((payload: any) => {
                toast.success('News Alert added successfully');
                resetFormData();
                dispatch(setSettingsModal({ addOrEditNewsAlerts: false }))
            })
            .catch((e: any) => toast.error('Failed adding News Alert'))
    }

    const updateNewsAlert = async () => {
        await editNewsAlertCall({
            id_pk: editData.id_pk,
            title,
            description,
            start_date: startDate,
            expiry_date: expiryDate
        }).unwrap()
            .then((payload: any) => {
                toast.success('News Alert updated successfully');
                resetFormData();
                dispatch(setSettingsModal({ addOrEditNewsAlerts: false }))
            })
            .catch((e: any) => toast.error('Failed updating News Alert'))
    }

    const disableUpdate = () => {
        if (
            title === editData.title &&
            description === editData.description &&
            formatDateDDMMYYYY(startDate) === formatDateDDMMYYYY(editData.start_date) &&
            formatDateDDMMYYYY(expiryDate) === formatDateDDMMYYYY(editData.expiry_date) ||
            !title || !description || !startDate || !expiryDate || formatDateDDMMYYYY(startDate) >= formatDateDDMMYYYY(expiryDate)
        ) {
            return true
        } else {
            return false
        }
    }

    return (
        <>
            <Dialog
                header={editData ? "Update News Alert" : "Add News Alert"}
                visible={serviceModal.addOrEditNewsAlerts}
                className="flex justify-content-center"
                style={{ width: '60vw' }}
                onHide={() => {
                    resetFormData();
                    dispatch(setSettingsModal({ addOrEditNewsAlerts: false }))
                }}
            >

                <div className="p-fluid grid formgrid mt-2">
                    <div className="grid col-12 mt-4">

                        <div className={`field col-4`}>
                            <span className="p-float-label">
                                <InputText
                                    id="Title"
                                    value={title}
                                    onChange={(e) => {
                                        console.log(e);
                                        e.target.value ? setTitle(e.target.value) : setTitle(undefined);
                                    }}
                                />
                                <label htmlFor="Title">Title<span style={{ color: "red" }}>*</span></label>
                            </span>
                        </div>

                        <div className={`field col-4`}>
                            <span className="p-float-label">
                                <Calendar id="startDate" value={startDate} onChange={(e: any) => setStartDate(e.value)}
                                    view="date" dateFormat="dd-MM-yy" minDate={minDate}
                                />
                                <label htmlFor="startDate">Start Date<span style={{ color: "red" }}>*</span></label>
                            </span>
                        </div>

                        <div className={`field col-4`}>
                            <span className="p-float-label">
                                <Calendar id="expiryDate" value={expiryDate} onChange={(e: any) => setExpiryDate(e.value)}
                                    view="date" dateFormat="dd-MM-yy" minDate={minDate}
                                />
                                <label htmlFor="expiryDate">Expiry Date<span style={{ color: "red" }}>*</span></label>
                            </span>
                        </div>

                        <div className={`field col-12`}>
                            <span className="p-float-label">
                                <InputTextarea
                                    id="description"
                                    value={description}
                                    rows={5}
                                    onChange={(e) => {
                                        console.log(e);
                                        setDescription(e.target.value);
                                    }}
                                />
                                <label htmlFor="description">Description<span style={{ color: "red" }}>*</span></label>
                            </span>
                        </div>

                        {startDate && expiryDate && formatDateDDMMYYYY(startDate) >= formatDateDDMMYYYY(expiryDate) &&
                            <span style={{ color: "red" }}>Start Date should be earlier than Expiry Date</span>}

                        <div className="flex col-12 justify-content-end">
                            {editData ?

                                <div className={`field col-4 mt-3`}>
                                    <Button
                                        label="Update News Alert"
                                        onClick={updateNewsAlert}
                                        icon="pi pi-save"
                                        className="p-button-sm"
                                        loading={isEditingNews}
                                        disabled={disableUpdate()}
                                    />
                                </div>

                                :

                                <div className={`field col-4`}>
                                    <Button
                                        label="Create News Alert"
                                        onClick={addNewsAlert}
                                        icon="pi pi-save"
                                        className="p-button-sm"
                                        loading={isAddingNews}
                                        disabled={!title || !description || !startDate || !expiryDate || formatDateDDMMYYYY(startDate) >= formatDateDDMMYYYY(expiryDate)}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>

            </Dialog>
        </>
    )

}

export default AddOrEditNewsAlerts;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllCostCenters } from '../services';
import { RootState } from '.';

export interface ActualProps {
	amount: number | string;
	companyName: string;
	createdAt: string;
	endDate: string | null;
	expectLeaveDate: string | null;
	id: string;
	remark: string;
	startDate: string;
	uid: number;
	updatedAt: string;
	[key: string]: any;
}

export interface SystemState {
	loading: boolean;
	actualAll: IProp[];
}

const initialState: SystemState = {
	loading: false,
	actualAll: [],
};

interface IService {
	id_pk: number;
	serviceId: string;
	serviceName: string;
	said: string;
	additionalInfo: string;
	plannedAmount: number;
	bookedAmount: number;
	isComment: boolean;
	ioNumber: string;
	isHistory: boolean;
	isILVComment: boolean;
	month: number;
}

interface IProp {
	costCenterId: string;
	name: string;
	costCenter: string;
	targetResponsible: string;
	userResponsible: string;
	deputy: string;
	amount: number;
	plannedAmount: number;
	bookedAmount: number;
	picasAmount: number;
	services: IService[];
}

export const getAllCostCenter = createAsyncThunk(
	'actual/getAllCostCenter',
	async () => {
		const response = await getAllCostCenters(true);
		return response.data;
	}
);

export const actualSlice = createSlice({
	name: 'actual',
	initialState,
	reducers: {},

	extraReducers(builder) {
		builder
			.addCase(getAllCostCenter.pending, (state) => {
				state.loading = true;
			})
			.addCase(getAllCostCenter.fulfilled, (state, action) => {
				state.actualAll = action.payload;
			})
			.addCase(getAllCostCenter.rejected, (state) => {
				state.loading = false;
			});
	},
});

export const allCostCenters = (state: RootState) => state.actual.actualAll;

export default actualSlice.reducer;

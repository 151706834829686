import { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import {
	dataProcessingModalState,
	setDataProcessingModal,
} from '../../store/commonSlice';
import FileUploader from '../../components/file-uploader';
import { Dropdown } from 'primereact/dropdown';
import CustomAutoComplete from '../../components/autocomplete/autocomplete';
import { Months } from '../../utils/json/months';
import { useUploadGemsUserListMutation } from '../../store/chargingReportApi';
import { Form } from 'formik';
import CustomToast from '../../components/toaster/toaster';
import { toast } from 'react-toastify';
import { Skeleton } from 'primereact/skeleton';

const UploadUserList = () => {
	const dispatch = useDispatch();
	const openModal = useSelector(dataProcessingModalState);
	const [selectedFile, setSelectedFile] = useState<File | undefined>();
	const [selectedMonth, setSelectedMonth] = useState('');

	const [
		uploadGemsReport,
		{
			isLoading: isUploading,
			isSuccess: isUploadSuccess,
			isError: isUploadError,
			error: uploadError,
		},
	] = useUploadGemsUserListMutation();
	const footer = () => (
		<div>
			<Button
				label="Cancel"
				icon="pi pi-times"
				onClick={() =>
					dispatch(setDataProcessingModal({ uploadGemsList: false }))
				}
			/>
			<Button
				label="Upload"
				icon="pi pi-check"
				disabled={selectedFile === undefined}
				loading={isUploading}
				onClick={async () => {
					console.log(selectedFile);
					//dispatch(setDataProcessingModal({ uploadChargingReport: false }))
					if (selectedFile) {
						const payload = new FormData();
						payload.append('importdata', selectedFile);
						await uploadGemsReport({
							file: payload,
						})
							.unwrap()
							.then((payload) => {
								console.log(payload);
								toast.success(JSON.parse(payload).message);
							})
							.catch((e: any) => {
								console.log(e);
								toast.error(e);
							});
						dispatch(setDataProcessingModal({ uploadGemsList: false }));
					}
				}}
			/>
		</div>
	);

	const fileCallback = (file: File | undefined) => {
		setSelectedFile(file);
	};

	return (
		<Dialog
			header="Upload GEMS User List"
			visible={openModal.uploadGemsList}
			style={{ width: '50vw' }}
			footer={footer}
			onHide={() => dispatch(setDataProcessingModal({ uploadGemsList: false }))}
		>
			<div className="grid p-fluid">
				<div className="grid col-12">
					<Message
						severity="info"
						text="Please upload your file in the .xlsx format"
						className="mt-4"
					/>

					<FileUploader callback={fileCallback} disabled={isUploading} />
				</div>
				{/* <Message
					severity="warn"
					text="Already existing Actuals will be overwritten"
					className="mt-4"
				/> */}
			</div>
		</Dialog>
	);
};

export default UploadUserList;
